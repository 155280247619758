import React from 'react';
import { IoLogoReact } from 'react-icons/io5';

const LoadingFile = () => {
  return (
            <div className="loading">
            <IoLogoReact className="loading-icon" /> <br /><br /><br />
            <span style={{fontSize: '16px', color: '#f14668'}}> &nbsp; Uploading...</span>
            </div>
  );
};

export default LoadingFile;

// CSS
const styles = `
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  color:#f14668;
  font-size:20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
