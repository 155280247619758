import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoSearch, IoDocumentAttach, IoCheckmark, IoReceipt, IoHourglassOutline, IoLockOpen, IoLockClosed} from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import './FileUpload.css';
import { getLocalIP, formatTanggal} from '../app/GlobalVar';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Iframe from 'react-iframe';
import Multiselect from 'multiselect-react-dropdown';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Loading from './Loading';



const TransDesaList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()
  const [search, setSearch] = useState('')
  const [msg, setMsg] = useState("");
  const [tglmulai, setTglMulai] = useState('');
  const [tglselesai, setTglSelesai] = useState('');
  const [tglsekarang, setTglSekarang] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [createdat, setCreatedAt] = useState('');
  const [selectedFilesdb, setSelectedFilesDb] = useState([]);
  const [jam, setJam] = useState('');
  const [lokalip, setLokalIP] = useState('');
  const [tanggalformatindo, setTanggalFormatIndo] = useState('');
  const sliderRef = useRef(null);
  const [ismodalopendesa, setIsModalOpenDesa] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);
  const [isModalOpenSendAdminTnt, setIsModalOpenSendAdminTnt] = useState(false);
  const [isModalOpenPrintLaporan, setIsModalOpenPrintLaporan] = useState(false);
  const [isModalOpenPrintRincianTrans, setIsModalOpenPrintRincianTrans] = useState(false);
  const [isModalOpenCetakLaporan, setisModalOpenCetakLaporan] = useState(false);
  const [isModalOpenCetakRincianTrans, setisModalOpenCetakRincianTrans] = useState(false);
  const [jenistransaksi, setJenisTransaksi] = useState('');
  const [isModalOpenFileRespon, setIsModalOpenFileRespon] = useState(false);
  const [transdesa, setTransDesa] = useState([]);
  const [filteredtransdesa, setFilteredTransDesa] = useState([])
  const [idpencairan, setIdPencairan] = useState("");
  const [tglentry, setTglEntry] = useState("");
  const [kodedesa, setKodeDesa] = useState("");
  const [jenistransselect, setJenisTransSelect] = useState("");
  const [norekdesa, setNorekDesa] = useState("");
  const [namadesa, setNamaDesa] = useState("");
  const [kodekantor, setKodeKantor] = useState("");
  const [kodekantorkas, setKodeKantorKas] = useState("");
  const [kodekakas, setKodeKaKas] = useState("");
  const [kodekakasall, setKodeKaKasAll] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [datarekeninginternal, setDataRekeningInternal] = useState([]);
  const [datarekeningtransfer, setDataRekeningTransfer] = useState([]);
  const [datapajak, setDataPajak] = useState([]);
  const [databpjs, setDataBpjs] = useState([]);
  const [statustransinternal, setStatusTransInternal] = useState("");
  const [statustransexternal, setStatusTransExternal] = useState("");
  const [expand, setExpand] = useState("");
  const [jmlnominalint, setJmlNominalInt] = useState("");
  const [jmldataint, setJmlDataInt] = useState("");
  const [disabledbtnkirimtnttransfer, setDisabledbtnKirimTntTransfer] = useState(false);
  const [disabledbtnkirimtntpajak, setDisabledbtnKirimTntPajak] = useState(false);
  const [disabledbtnkirimtntbpjs, setDisabledbtnKirimTntBpjs] = useState(false);
  const [kodekecamatanselect, setKodeKecamatanSelect] = useState("");
  const [kodekecamatanall, setKodeKecamatanAll] = useState([]);
  const [kodedesaselect, setKodeDesaSelect] = useState([]);
  const [kodedesa2, setKodeDesa2] = useState([]);
  const [kodedesaall, setKodeDesaAll] = useState([]);
  const [tglmulailap, setTglMulaiLap] = useState('');
  const [tglselesailap, setTglSelesaiLap] = useState('');
  const [urlbyid, setUrlById] = useState('');
  const [urlbyidrinciantrans, setUrlByIdRincianTrans] = useState('');
  const [nospp, setNoSpp] = useState("");
  const [role, setRole] = useState('');
  const [userexecutor, setUserExecutor] = useState("");
  const [executor, setExecutor] = useState([]);
  const [urlFile, setUrlFile] = useState("");
  const [nameFile, setNameFile] = useState("");
  const [loading, setLoading] = useState('');
  const dateInputRefRekapLapMulai = useRef(null);
  const dateInputRefRekapLapSelesai = useRef(null);
  const dateInputRefTransLapMulai = useRef(null);
  const dateInputRefTransLapSelesai = useRef(null);

  const datePeriodeMulai = useRef(null);
  const datePeriodeSelesai = useRef(null);
  const dateFormat = 'd/m/Y';

  useEffect(() => {
  
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(selectedImageIndex);
    }

  }, [selectedImageIndex]);

  useEffect(() => {
    fetchDataTgl(); 
    setKodeKantor(user && user.kode_kantor);
    setKodeKantorKas(user && user.kode_kantor_kas);
    setRole(user && user.role);


  }, [user && user.kode_user_cbs, tglsekarang, user && user.kode_kantor, user && user.kode_kantor_kas, user && user.is_tnt, user && user.role]);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: selectedImageIndex,
    afterChange: (currentSlide) => {
      setSelectedImageIndex(currentSlide);
    },
    };


  const handleExpand = () => {
    setExpand('modal-full-screen');
  };

  const handleReduce = () => {
    setExpand('');
  };
  


  const handleOpenModalDesa = (islock) => {
    if(islock == 'unlock'){
      Swal.fire(  
        'Gagal!',
        'Data belum di lock!',
        'error'
    )
      setIsModalOpenDesa(false)
    }else{
      setIsModalOpenDesa(true)
    }

  };

  const handleCloseModalDesa = () => {
    setIsModalOpenDesa(false);
    resetForm();
  };

  
  const handleOpenModalImage = (pencairanid, index) => {
    getTransDesaById(pencairanid);
    setIsModalImageOpen(true);
    setSelectedImageIndex(index);
  };

  const handleCloseModalImage = () => {
    setIsModalImageOpen(false);
    setSelectedImageIndex(null);
  };

  const handleOpenModalSendAdminTnt = (key) => {
    setIsModalOpenSendAdminTnt(true);
    getUserExecutor('executor');
    setJenisTransaksi(key);
    // console.log(key);
  };

  const handleCloseModalSendAdminTnt = () => {
    setIsModalOpenSendAdminTnt(false);
    setUserExecutor("");
    setJenisTransaksi("");
  };


  const handleOpenModalFileRespon = (url, name) => {
    setIsModalOpenFileRespon(true);
    setUrlFile(url);
    setNameFile(name);
  };

const handleCloseModalFileRespon = () => {
    setIsModalOpenFileRespon(false);
    setUrlFile("");
    setNameFile("");
  };
 

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleOpenModalPrintLaporan = () => {
    setIsModalOpenPrintLaporan(true);
    
    getKodeKecamatan();
    if(role == 'operator'){
    getKodeDesa();
    }
    
    flatpickr(dateInputRefRekapLapMulai.current, {
      altInput: true,
      altFormat: dateFormat,
      dateFormat: 'Y-m-d', 
      disableMobile: "true",
      onChange: function(selectedDates, dateStr) {
        setTglMulaiLap(dateStr)
      },
    });

    flatpickr(dateInputRefRekapLapSelesai.current, {
      altInput: true,
      altFormat: dateFormat,
      dateFormat: 'Y-m-d', 
      disableMobile: "true",
      onChange: function(selectedDates, dateStr) {
        setTglSelesaiLap(dateStr)
      },
    });

  };


  const handleCloseModalPrintLaporan = () => {
    setIsModalOpenPrintLaporan(false);
    setTglMulaiLap("")
    setTglSelesaiLap("")
    setKodeKaKas("")
    dateInputRefRekapLapMulai.current._flatpickr.clear();
    dateInputRefRekapLapSelesai.current._flatpickr.clear();
  };


  const handleOpenModalPrintRincianTrans = () => {
    setIsModalOpenPrintRincianTrans(true);


    flatpickr(dateInputRefTransLapMulai.current, {
      altInput: true,
      altFormat: dateFormat,
      dateFormat: 'Y-m-d', 
      disableMobile: "true",
      onChange: function(selectedDates, dateStr) {
        setTglMulaiLap(dateStr)     
      },
    });

    flatpickr(dateInputRefTransLapSelesai.current, {
      altInput: true,
      altFormat: dateFormat,
      dateFormat: 'Y-m-d', 
      disableMobile: "true",
      onChange: function(selectedDates, dateStr) {
        setTglSelesaiLap(dateStr)
      },
    });

  };


const handleCloseModalPrintRincianTrans = () => {
    setIsModalOpenPrintRincianTrans(false);
    setTglMulaiLap("")
    setTglSelesaiLap("")
    setKodeKaKas("")
    setJenisTransSelect("")
    dateInputRefTransLapMulai.current._flatpickr.clear();
    dateInputRefTransLapSelesai.current._flatpickr.clear();
  };


  const options = kodedesaall.map(desa => ({
    label: `${desa.KODE_DESA} - ${desa.DESA}`,
    value: desa.KODE_DESA,
  }));

  const handleDesaSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setKodeDesaSelect(selectedOptions);
    setKodeDesa2(selectedValues);
  }


  const handleOpenModalCetakLaporan = () => {
       
    if(role == ''){
      Swal.fire(  
        'Gagal!',
        'Role user masih kosong!',
        'error'
      )
    }else if(tglmulailap == ''){
      Swal.fire(  
        'Gagal!',
        'Periode tanggal mulai belum di isi!',
        'error'
      )
    }else if(tglselesailap == ''){
      Swal.fire(  
        'Gagal!',
        'Periode tanggal selesai belum di isi!',
        'error'
      )
    }else if(kodekakas == ''){
      Swal.fire(  
        'Gagal!',
        'Kode desa masih kosong!',
        'error'
      )
    }else{
      setisModalOpenCetakLaporan(true);
      setUrlById('/printlaporankantor/'+kodekakas+'/'+tglmulailap+'/'+tglselesailap);

    }

    };
  
const handleCloseModalCetakLaporan = () => {
      setisModalOpenCetakLaporan(false);
    };


const handleOpenModalCetakRincianTrans = () => {
      if(role == ''){
        Swal.fire(  
          'Gagal!',
          'Role user masih kosong!',
          'error'
        )
      }else if(tglmulailap == ''){
        Swal.fire(  
          'Gagal!',
          'Periode tanggal mulai belum di isi!',
          'error'
        )
      }else if(tglselesailap == ''){
        Swal.fire(  
          'Gagal!',
          'Periode tanggal selesai belum di isi!',
          'error'
        )
      }else if(tglmulailap > tglselesailap){
        Swal.fire(  
          'Gagal!',
          'Periode tanggal mulai tidak boleh lebih besar dari tanggal selesai!',
          'error'
        )
      }else if(kodekakas == ''){
        Swal.fire(  
          'Gagal!',
          'Kode desa masih kosong!',
          'error'
        )
      }else if(jenistransselect == ''){
        Swal.fire(  
          'Gagal!',
          'Jenis Transaksi belum di pilih!',
          'error'
        )
      }else{

        setisModalOpenCetakRincianTrans(true);
        setUrlByIdRincianTrans('/printrinciantranskantor/'+kodekakas+'/'+tglmulailap+'/'+tglselesailap+'/'+jenistransselect);
      }

      };

  const handleCloseModalCetakRincianTrans = () => {
        setisModalOpenCetakRincianTrans(false);    
      };



const getKantorKas = async () => {
        try {
          const response = await axiosJWT.get(`${env.API_URL}/kantorkas`,{
            headers: {
              Authorization: `Bearer ${token}`
          }
          });
          setKodeKaKasAll(response.data);
          // console.log(response.data)
        } catch (error) {
          if (error.response) {
            setMsg(error.response.data.msg);
          }
        }
      };


  const getKodeKecamatan = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/reffkecamatan`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKodeKecamatanAll(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  
  
  const handleBlurKodeKecamatan = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/reffdesakec/${kodekecamatanselect}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      
      setKodeDesaAll(response.data);
      
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
        setKodeDesaAll([]);
      }
    }
  };


  const getKodeDesa = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/reffdesakas/${kodekantorkas}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKodeDesaAll(response.data);
      
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  

  const resetForm = () => {
    setIdPencairan('');
    setExpand('');
    setActiveTab(0);
  }


  const HorizontalLine = () => {
    return (
      <div
        style={{
          borderTop: '1px solid #cfcfcf',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      ></div>
    );
  };



  const settanggal = (e) => {
    var date = new Date(e); 
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)).toLocaleString('en-ID', { hour12:false } );
  }


  
  const getUserExecutor = async (id) => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/userexe/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setExecutor(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  const getTransDesaById = async (pencairanid) => {
    dispatch(getMe());
    try {
      const response = await axiosJWT.get(`${env.API_URL}/pencairan/${pencairanid}`,{
       responseType: 'json',
        headers: {
          Authorization: `Bearer ${token}`
      }
      });

      const filesFromServer = response.data.formattedFiles.map((file) => ({
        file:{name: file.name,
              type: file.type},
        ext: file.ext,
        src: env.API_URL+file.src 
      }));


      const datatransInt = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '0') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        uuid: dt.uuid,
        pencairanid: dt.pencairan_id,
        statustrans: dt.status_trans,
        norektujuan: dt.no_rekening,
        // kantortujuan: dt.kantor_tujuan,
        nama: dt.nama,
        nominal: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan,
      }
      ));

      
      const datatransIntsend = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '0' && dt.status_trans === '0') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        uuid: dt.uuid,
        noreksumber: dt.norek_sumber,
        norektujuan: dt.no_rekening,
        kantoruser: dt.kantor_user,
        kantorsumber: dt.kantor_sumber,
        kantortujuan: dt.kantor_tujuan,
        nama: dt.nama,
        nominal: dt.nominal,
        keterangan: dt.keterangan,
        jenisrekening: dt.jenisrekening,
        namabank: dt.namabank,
        validate: dt.validate,
        typejurnal: dt.type_jurnal,
        perktabsumber: dt.perktab_sumber,
        perktabtujuan: dt.perktab_tujuan,
        jam: jam,
        tgltrans: tglsekarang,
        lokalip: lokalip,
        useribs: user && user.kode_user_cbs,
        statustrans: dt.status_trans
      }
      ));
      
      const datatransIntCount = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '0' && dt.status_trans === '1') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        uuid: dt.uuid,
      }
      ));


      const datatransTransfer = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '1' ) // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        idpencairan: response.data.response.pencairan_id,
        uuid: dt.uuid,
        kodekantorkas: user && user.kode_kantor_kas,
        kodekantor: user && user.kode_kantor,
        jenistnt : dt.jenistransaksi,
        namanasabah : response.data.response.nama_desa,
        norek : response.data.response.norek_desa,
        kodekantortab : response.data.response.kode_kantor,
        perktab : response.data.pencairan_trans[0].perktab_sumber,
        alamatnasabah : response.data.response.alamat_desa,
        useribs: user && user.kode_user_cbs,
        lock: 'unlock',
        createdat:createdat,
        tglsekarang: tglsekarang,

        statustrans: dt.status_trans,
        kodebank: dt.kode_bank_umum,
        no_rekening: dt.no_rekening,
        nama: dt.nama, 
        jumlahsetoran: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan, 
        namabank: dt.namabank,
        filerespontnt: dt.filerespontnt
      }
      ));
  
      const datatransTransferCount = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '1' && dt.status_trans === '0') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        uuid: dt.uuid,
      }
      ));

      const datatransPajak = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '2') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        idpencairan: response.data.response.pencairan_id,
        uuid: dt.uuid,
        kodekantorkas: user && user.kode_kantor_kas,
        kodekantor: user && user.kode_kantor,
        jenistnt : dt.jenistransaksi,
        namanasabah : response.data.response.nama_desa,
        norek : response.data.response.norek_desa,
        kodekantortab : response.data.response.kode_kantor,
        perktab : response.data.pencairan_trans[0].perktab_sumber,
        alamatnasabah : response.data.response.alamat_desa,
        useribs: user && user.kode_user_cbs,
        lock: 'unlock',
        createdat:createdat,
        tglsekarang: tglsekarang,

        statustrans: dt.status_trans,
        idbilling: dt.id_biling, 
        jenispajak: dt.jenis_pajak, 
        masapajak: dt.masa_pajak, 
        jumlahsetoran: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan,    
        filerespontnt: dt.filerespontnt
      }
      ));


      const datatransPajakCount = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '2' && dt.status_trans === '0') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        uuid: dt.uuid,
      }
      ));

      const datatransBpjs = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '3') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        idpencairan: response.data.response.pencairan_id,
        uuid: dt.uuid,
        kodekantorkas: user && user.kode_kantor_kas,
        kodekantor: user && user.kode_kantor,
        jenistnt : dt.jenistransaksi,
        namanasabah : response.data.response.nama_desa,
        norek : response.data.response.norek_desa,
        kodekantortab : response.data.response.kode_kantor,
        perktab : response.data.pencairan_trans[0].perktab_sumber,
        alamatnasabah : response.data.response.alamat_desa,
        useribs: user && user.kode_user_cbs,
        lock: 'unlock',
        createdat: createdat,
        tglsekarang: tglsekarang,

        statustrans: dt.status_trans,
        nova: dt.nomor_virtual_account,
        jumlahsetoran: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan, 
        filerespontnt: dt.filerespontnt
      }
      ));

      const datatransBpjsCount = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '3' && dt.status_trans === '0') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        uuid: dt.uuid,
      }
      ));


      if(datatransTransferCount.length == 0){
        setDisabledbtnKirimTntTransfer(true);
      }else{
        setDisabledbtnKirimTntTransfer(false);
      }

      if(datatransPajakCount.length == 0){
        setDisabledbtnKirimTntPajak(true);
      }else{
        setDisabledbtnKirimTntPajak(false);
      }

      if(datatransBpjsCount.length == 0){
        setDisabledbtnKirimTntBpjs(true);
      }else{
        setDisabledbtnKirimTntBpjs(false);
      }
    

      const TotalNominalInt = datatransIntsend.reduce(
        (accumulator, currentData) => accumulator + Number(currentData.nominal),
        0
      );


      setDataRekeningInternal(Array.isArray(datatransInt) ? datatransInt : []);
      setDataRekeningTransfer(Array.isArray(datatransTransfer) ? datatransTransfer : []);
      setDataPajak(Array.isArray(datatransPajak) ? datatransPajak : []);
      setDataBpjs(Array.isArray(datatransBpjs) ? datatransBpjs : []);


      setJmlNominalInt(TotalNominalInt);
      setJmlDataInt(datatransIntsend.length);

      setSelectedFilesDb(filesFromServer);
      setIdPencairan(response.data.response.pencairan_id);
      setNoSpp(response.data.response.no_spp);
      setKodeDesa(response.data.response.kode_desa);
      setNorekDesa(response.data.response.norek_desa);
      setNamaDesa(response.data.response.nama_desa);
      setKodeKantor(response.data.response.kode_kantor);
      setKodeKantorKas(response.data.response.kode_kantor_kas);
      setKeterangan(response.data.response.keterangan);
      setStatusTransInternal(response.data.response.status_trans_internal);
      setStatusTransExternal(response.data.response.status_trans_external);
    
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  const fetchData = async () => {
    const localIP = await getLocalIP();
    setLokalIP(localIP);
    };



      const fetchDataTgl = async () => {
        const tanggal = await formatTanggal();
        setTanggalFormatIndo(tanggal.tanggalformatindo);
        setTglSekarang(tanggal.tgl_trans);   
        setTglMulai(tanggal.tgl_trans); 
        setTglSelesai(tanggal.tgl_trans);    
        setCreatedAt(tanggal.tgl_trans+' '+tanggal.jam_trans);     
        setJam(tanggal.jam_trans); 
  
        flatpickr(datePeriodeMulai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d',
          defaultDate: tanggal.tgl_trans,
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglMulai(dateStr);
          },
        });
    
        flatpickr(datePeriodeSelesai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d',
          defaultDate: tanggal.tgl_trans,
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglSelesai(dateStr);
          },
        });
  
        };

axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))


const isTrans = async (uuid, confirmed, pencairanid) => {

    Swal.fire({
      title: 'Konfirmasi!',
      text: " Anda yakin akan melakukan konfirmasi transaksi?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Konfirmasi!'
  }).then(async (result) => {
    if (result.isConfirmed) {
  
      await axiosJWT.patch(`${env.API_URL}/updatetransinternal/${uuid}`,{
        statustrans: confirmed,
        tglkonfirmasi: tglsekarang,
        jam: jam
      },{
        headers: {
          Authorization: `Bearer ${token}`
      }
      })
  
      getTransDesaById(pencairanid);
  
      }
      
    })
  
  
    getTransDesaById(pencairanid);
     
    }

    const islockpencairan = async (pencairanid, lock, isverified) => {

      if((isverified == 'verified' && role != 'admin')  || role == 'admin' ){
        Swal.fire({
          title: 'Konfirmasi!',
          text: "Anda akan melakukan "+lock+" data ini?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, '+lock+'!'
      }).then(async (result) => {
        if (result.isConfirmed) {
      
          await axiosJWT.patch(`${env.API_URL}/lockpencairandesa/${pencairanid}`,{
            lock: lock
          },{
            headers: {
              Authorization: `Bearer ${token}`
          }
          })
          getTransDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
          }
          
        })
      }else{ 
          Swal.fire(
            'Error!',
            'Data belum diverifikasi!',
            'error'
          );
        }
      
        getTransDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
         
        }
      

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Tanggal',
        selector: row => row.tgl_entry,
        sortable: true,
        width: "100px"
    },
    {
        name: 'ID Data',
        selector: row => row.pencairan_id,
        sortable: true,
        width: "160px"
    },
    {
        name: 'No SPP',
        selector: row => row.no_spp,
        sortable: true,
        width: "160px"
    },
    {
        name: 'Kd Desa',
        selector: row => row.kode_desa,
        sortable: true,
        width: "100px"
    },
    {
        name: 'Nama Desa',
        selector: row => row.nama_desa,
        sortable: true,
        width: "200px",
        cell: row => (
          <div title={row.nama_desa}>
            {row.nama_desa}
          </div>
        )
    },
    {
        name: 'Kantor',
        selector: row => row.nama_kantor,
        sortable: true,
        width: "150px",
        cell: row => (
          <div title={row.nama_kantor}>
            {row.nama_kantor}
          </div>
        )
    },
    {
      name: 'Status Trans',  
      sortable: false,
      width: "100px",
      selector: row => row.status_pencairan_desa,
      cell: (row) => {
        if ((row.status_pencairan_desa === '0' || row.status_pencairan_desa === null) && row.is_verified == 'unverified') {
          return (
            <div>
              <span           
                onClick={() => {
                  Swal.fire(
                    'Warning!',
                    'Data belum di proses, karena belum di verifikasi',
                    'warning'
                  );
                }}               
              className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Belum di proses"><IoHourglassOutline size={20} /></span>
            </div>
          );
        } else if ((row.status_pencairan_desa === '0' || row.status_pencairan_desa === null) && row.is_verified == 'verified') {
          return (
            <div>
              <span           
                onClick={() => {
                  Swal.fire(
                    'Warning!',
                    'Dalam proses transaksi',
                    'warning'
                  );
                }}               
              className="is-small mr-1" style={{ color: '#ff8400', cursor: 'pointer'}} title="Dalam Proses"><IoHourglassOutline size={20} /></span>
            </div>
          );
        } else if (row.status_pencairan_desa === '1') {
          return (
            <div>
              <span           
                onClick={() => {
                  Swal.fire(
                    'Berhasil!',
                    'Data sudah ditransaksikan',
                    'success'
                  );
                }}               
              className="is-small mr-1" style={{ color: '#48c78e', cursor: 'pointer'}} title="Selesai transaksi"><IoCheckmark size={20} /></span>
            </div>
          );
        
        } else {
          return null; 
        }
      }
   },
    {
      name: 'Rincian Trans',
      selector: row => {
        if (row.status_trans) {
          const StatusTrans = row.status_trans.split('|'); 
          return (
              <ul>
                  {StatusTrans.map((statustrans, index) => (
                     <li key={index}>
                         &nbsp;{statustrans}
                 </li>
                  ))}
              </ul>
          );
        }
        return 'Tidak Ada Data Transaksi';
      },
      sortable: true,
      width: "230px"
    },  
    {

        name: 'File',
        selector: row => {
          if (row.filepencairan) {
            const fileNames = row.filepencairan.split('|'); 
            return (
                <ul>
                    {fileNames.map((fileName, index) => (
                       <li key={index}>
                        <a onClick={() => {handleOpenModalImage(row.pencairan_id, index)}}  >
                           <IoDocumentAttach /> &nbsp;{fileName}
                       </a>
                   </li>
                    ))}
                </ul>
            );
          }
          return 'Tidak Ada File';
        },
        sortable: true,
        width: "240px"

    },
    {
      name: 'Is Verified',  
      sortable: false,
      width: "90px",
      selector: row => row.is_verified,
      cell: (row) => {
        if (row.is_verified === 'unverified') {
          return (
            <div>
                <span
                onClick={() => { 
                  Swal.fire(
                  'Error!',
                  'Anda tidak berhak melakukan verifikasi data ini!',
                  'error'
                );}}                
                className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Data belum di verifikasi"><IoHourglassOutline size={20} /></span>
            </div>
          );
        } else if (row.is_verified === 'verified') {
          return (
            <div>
                <span
                onClick={() => { 
                  Swal.fire(
                  'Error!',
                  'Anda tidak berhak melakukan verifikasi data ini!',
                  'error'
                );}}                    
                className="is-small mr-1" style={{ color: '#48c78e', cursor: 'pointer'}} title="Data sudah di verifikasi"><IoCheckmark size={20} /></span>
            </div>
          );
        } else {
          return null; 
        }
      }
    },
    {
      name: 'Is Lock',  
      sortable: false,
      width: "70px",
      selector: row => row.is_lock,
      cell: (row) => {
        if (row.is_lock === 'unlock') {
          return (
            <div>
               <span
                onClick={() => {islockpencairan(row.pencairan_id, 'lock', row.is_verified)}}            
                className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Data belum di lock"><IoLockOpen size={20} /></span>
            </div>
          );
        } else if (row.is_lock === 'lock') {
          return (
            <div>
              <span           
              onClick={() => {islockpencairan(row.pencairan_id, 'unlock', row.is_verified)}}
              className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Data sudah di lock"><IoLockClosed size={20} /></span> 
            </div>
          );
        } else {
          return null; 
        }
      }
  },
    {
        name: 'Action',
        sortable: false,
        selector: row => row.pencairan_id,

        cell: (row) => {
          if(role === 'admin'){
            return (
              <div>
               <span
                onClick={() => {setIdPencairan(row.pencairan_id);getTransDesaById(row.pencairan_id);handleOpenModalDesa();resetForm();}}                    
                className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Konfirmasi transaksi"><IoReceipt size={20} /></span>
            </div>
              );
          }else{
            return (
              <div>
                <span
                onClick={() => {setIdPencairan(row.pencairan_id);getTransDesaById(row.pencairan_id);handleOpenModalDesa(row.is_lock);resetForm();}}                  
                className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Konfirmasi transaksi"><IoReceipt size={20} /></span>
            </div>
              );
          }
            
        },
        width: "150px"
    }
  ];


  
  const columnsInternal = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Status Trans',
        selector: row => row.statustrans,
        sortable: true,
        cell: row => {
        if (row.statustrans === '0') {
          return <span onClick={() => {isTrans(row.uuid, '1', row.pencairanid)}}  style={{ color: '#f03a5f', cursor: 'pointer'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
        }else if (row.statustrans === '1'){
          return <span onClick={() => {isTrans(row.uuid, '0', row.pencairanid)}}  style={{ color: '#48c78e', cursor: 'pointer'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
        }
        },
        width: "120px"
    },  
    {
        name: 'No Rekening',
        selector: row => row.norektujuan,
        sortable: true,
        cell: (row) => <div title={`Bank Tujuan ${row.namabank}.`}>{row.norektujuan}</div>,
        width: "150px"
    },
    {
        name: 'Nama',
        selector: row => row.nama,
        sortable: true,
        width: "300px",
        cell: row => (
          <div title={row.nama}>
            {row.nama}
          </div>
        )
    },
    {
        name: 'Nominal',
        selector: row => row.nominal,
        sortable: true,
        width: "150px"
   },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "200px"
    }
  ];


  const columnsTransfer = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Status Trans',
        selector: row => row.statustrans,
        sortable: true,
        cell: row => {
        if (row.statustrans === '0') {
          return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
        }else if (row.statustrans === '1'){
          return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
        }else if (row.statustrans === '2'){
          return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoHourglassOutline size={20} /></span>;
        }
        },
        width: "120px"
    },  
    {
        name: 'No Rekening',
        selector: row => row.no_rekening,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Nama Penerima',
        selector: row => row.nama,
        sortable: true,
        width: "200px"
    },
    {
        name: 'Jumlah Setoran',
        selector: row => row.jumlahsetoran,
        sortable: true,
        width: "150px"
   },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "200px"
    },
    {
      name: 'Kode Bank Tujuan',
      selector: row => row.kodebank,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Bank Tujuan',
      selector: row => row.namabank,
      sortable: true,
      width: "200px"
    },
    {
      name: 'File Respon',
      selector: row => {
        if (row.filerespontnt) {
          const fileNames = row.filerespontnt.split('|'); 
          return (
              <ul>
                  {fileNames.map((fileName, index) => ( 
                    
                     <li key={index}>
                      <a onClick={() => {handleOpenModalFileRespon(`${env.API_URL}/upload_tnt_respon/${fileName}`, `${fileName}`)}}  >
                         <IoDocumentAttach /> &nbsp;{fileName}
                     </a>
                 </li>
                  ))}
              </ul>
          );
        }
        return '-';
      },
      sortable: true,
      width: "250px"

    }
  ];


  const columnsPajak = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Status Trans',
      selector: row => row.statustrans,
      sortable: true,
      cell: row => {
      if (row.statustrans === '0') {
        return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
      }else if (row.statustrans === '1'){
        return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
      }else if (row.statustrans === '2'){
        return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoHourglassOutline size={20} /></span>;
      }
      },
      width: "120px"
    },
    {
        name: 'Id Billing',
        selector: row => row.idbilling,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jenis Pajak',
        selector: row => row.jenispajak,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Masa Pajak',
        selector: row => row.masapajak,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jumlah Setoran',
        selector: row => row.jumlahsetoran,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "150px"
    },
    {

      name: 'File Respon',
      selector: row => {
        if (row.filerespontnt) {
          const fileNames = row.filerespontnt.split('|'); 
          return (
              <ul>
                  {fileNames.map((fileName, index) => ( 
                    
                     <li key={index}>
                      <a onClick={() => {handleOpenModalFileRespon(`${env.API_URL}/upload_tnt_respon/${fileName}`, `${fileName}`)}}  >
                         <IoDocumentAttach /> &nbsp;{fileName}
                     </a>
                 </li>
                  ))}
              </ul>
          );
        }
        return '-';
      },
      sortable: true,
      width: "250px"

    }
  ];


  const columnsBpjs = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Status Trans',
      selector: row => row.statustrans,
      sortable: true,
      cell: row => {
      if (row.statustrans === '0') {
        return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
      }else if (row.statustrans === '1'){
        return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
      }else if (row.statustrans === '2'){
        return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoHourglassOutline size={20} /></span>;
      }
      },
      width: "120px"
    },
    {
        name: 'Nomor VA',
        selector: row => row.nova,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jumlah Setoran',
        selector: row => row.jumlahsetoran,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "250px"
    },
    {

      name: 'File Respon',
      selector: row => {
        if (row.filerespontnt) {
          const fileNames = row.filerespontnt.split('|'); 
          return (
              <ul>
                  {fileNames.map((fileName, index) => ( 
                    
                     <li key={index}>
                      <a onClick={() => {handleOpenModalFileRespon(`${env.API_URL}/upload_tnt_respon/${fileName}`, `${fileName}`)}}  >
                         <IoDocumentAttach /> &nbsp;{fileName}
                     </a>
                 </li>
                  ))}
              </ul>
          );
        }
        return '-';
      },
      sortable: true,
      width: "250px"

    }
  ];

  useEffect(() => { 
    setTglEntry(tanggalformatindo);
    setKodeDesa(user && user.kode_user_cbs);
    setNamaDesa(user && user.name);
    setKodeKantor(user && user.kode_kantor);
    setKodeKantorKas(user && user.kode_kantor_kas);
    getTransDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
    fetchDataTgl();
    getKantorKas();

  
  }, [user && user.kode_user_cbs, tglsekarang, user && user.kode_kantor, user && user.kode_kantor_kas, user && user.is_tnt, user && user.role, user && user.name]);

useEffect(() => {
    const result = transdesa.filter((transdesa) => {
        return transdesa.pencairan_id.toLowerCase().match(search.toLowerCase())
    })
    setFilteredTransDesa(result)
 }, [search])


  const getTransDesa = async (id_user, tgl1, tgl2) => {

    const response = await axiosJWT.get(`${env.API_URL}/pencairan/${id_user}/${tgl1}/${tgl2}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setTransDesa(response.data)
    setFilteredTransDesa(response.data)
  }

const getTransDesaFilter = async (e) => {
    e.preventDefault();
        var id_user = user && user.kode_user_cbs;
        var tglmulaif = settanggal(tglmulai);
        var tglselesaif = settanggal(tglselesai);
    const response = await axiosJWT.get(`${env.API_URL}/pencairan/${id_user}/${tglmulaif}/${tglselesaif}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setTransDesa(response.data)
    setFilteredTransDesa(response.data)
}




const SendToAdminTntTransfer = async (e) => {
  dispatch(getMe());
  e.preventDefault()

if(userexecutor == ''){
  Swal.fire(
    'Error!',
    'User Executor TNT belum dipilih!',
    'error'
  );
}else{

  const formData = new FormData();
  formData.append('userprocessed', userexecutor);
  formData.append('datarekeningtransfer', JSON.stringify(datarekeningtransfer));      
        try {
          setLoading(true);

          const response = await axiosJWT.post(`${env.API_URL}/sendadmintnttransfer`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });

          if (response.status === 200) {
            const timeout = setTimeout(() => {
              setLoading(false);
              getTransDesaById(idpencairan);
              handleCloseModalSendAdminTnt();
  
              Swal.fire(
                'Berhasil!',
                response.data.msg,
                'success'
              );  
            }, 3000);
  
            return () => clearTimeout(timeout);
           
          } else {
            Swal.fire(
              'Error!',
              'Gagal mendapatkan respons yang valid.',
              'error'
            );
          }
         
        } catch (error) {
          Swal.fire(
            'Error!',
            error.response.data.msg,
            'error'
          );
        }

      }

};



const SendToAdminTntPajak = async (e) => {
  dispatch(getMe());
  e.preventDefault()

if(userexecutor == ''){
  Swal.fire(
    'Error!',
    'User Executor TNT belum dipilih!',
    'error'
  );
}else{

  const formData = new FormData();
  formData.append('userprocessed', userexecutor);
  formData.append('datapajak', JSON.stringify(datapajak)); 
        try {
          setLoading(true);

          const response = await axiosJWT.post(`${env.API_URL}/sendadmintntpajak`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });

          if (response.status === 200) {
            const timeout = setTimeout(() => {
              setLoading(false);
              getTransDesaById(idpencairan);
              handleCloseModalSendAdminTnt();
  
              Swal.fire(
                'Berhasil!',
                response.data.msg,
                'success'
              );  
            }, 3000);
  
            return () => clearTimeout(timeout);
           
          } else {
            Swal.fire(
              'Error!',
              'Gagal mendapatkan respons yang valid.',
              'error'
            );
          }
         
        } catch (error) {
          Swal.fire(
            'Error!',
            error.response.data.msg,
            'error'
          );
        }

      }

};



const SendToAdminTntBpjs = async (e) => {
  dispatch(getMe());
  e.preventDefault()

if(userexecutor == ''){
  Swal.fire(
    'Error!',
    'User Executor TNT belum dipilih!',
    'error'
  );
}else{

  const formData = new FormData();
  formData.append('userprocessed', userexecutor);
  formData.append('databpjs', JSON.stringify(databpjs));
      
        try {
          setLoading(true);

          const response = await axiosJWT.post(`${env.API_URL}/sendadmintntbpjs`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });

     
          if (response.status === 200) {
            const timeout = setTimeout(() => {
              setLoading(false);
              getTransDesaById(idpencairan);
              handleCloseModalSendAdminTnt();
  
              Swal.fire(
                'Berhasil!',
                response.data.msg,
                'success'
              );  
            }, 3000);
  
            return () => clearTimeout(timeout);
           
          } else {
            Swal.fire(
              'Error!',
              'Gagal mendapatkan respons yang valid.',
              'error'
            );
          }

        } catch (error) {
          Swal.fire(
            'Error!',
            error.response.data.msg,
            'error'
          );
        }

      }

};



return (
    
<div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>


      <h2 className="subtitle"><b>Transaksi Desa</b></h2>

      <div className="box">
        <button  
          onClick={() => {handleOpenModalPrintLaporan();}} 
          className="button is-small is-warning " style={{marginRight:'5px'}}>
          Print Rekap Laporan
        </button>
        <button  onClick={() => {handleOpenModalPrintRincianTrans();}} className="button is-small is-warning">
          Print Rincian Transaksi
        </button>

    </div>

      <div className="box">
     

<div className="field is-horizontal mb-0">

    <label className="label is-small column is-1 pt-1">Periode</label>
    <input className="input is-small column is-2 mr-2" 
      ref={datePeriodeMulai}
      type="date" 
      value={tglmulai} 
    />
    <input className="input is-small column is-2 mr-2"  
     ref={datePeriodeSelesai}
     type="date" 
     value={tglselesai} 
    />
    <button className="button is-small is-primary" onClick={(e) => getTransDesaFilter(e)} title="Search Data"><IoSearch size="20"/></button>
   
</div>
     
            <DataTable
                        columns={columns}
                        data={Array.isArray(filteredtransdesa) ? filteredtransdesa : []}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />


<div className={`modal ${expand} modal-fx-3dRotateFromBottom ${ismodalopendesa ? 'is-active' : ''}`}>
        <div className="modal-background "></div>
        <div className="modal-content">
        <header className="modal-card-head">
              {expand === '' ? (
                <p className="modal-card-title" style={{marginLeft:'5px', fontSize: '20px',  cursor:'pointer'}} onClick={() => handleExpand()} title="Full Screen"><b>[Data Transaksi Desa]</b></p>
              ) : (
                <p className="modal-card-title" style={{marginLeft:'5px', fontSize: '20px',  cursor:'pointer'}} onClick={() => handleReduce()} title="Exit Full Screen"><b>[Data Transaksi Desa]</b></p>
              )}
              
              <button
                onClick={handleCloseModalDesa}
                className="delete"
                aria-label="close"
              />
            </header>


   
        <section className="modal-card-body" style={{padding:'5px'}} >

           <div>
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                <div className="columns">
                  <div className="column is-half">
                    {/* <label className="label is-small mb-0">Tanggal Trans</label> */}
                    <input
                      title="Tanggal Transaksi"
                      type="text"
                      className="input is-small mb-0"
                      value={tanggalformatindo}
                      placeholder="Tanggal Transaksi"
                      readOnly
                    />
                  </div>

                  <div className="column is-half">
                    {/* <label className="label is-small mb-0">Kode Kantor User</label> */}
                      <input
                        title="Kode Kantor User"
                        type="text"
                        className="input is-small mb-0"
                        value={user && user.kode_kantor}
                        placeholder="Kode Kantor User"
                        readOnly
                        />
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    {/* <label className="label is-small mb-0">Nomor Rekening Sumber</label> */}
                    <input
                      title="Nomor Rekening Sumber"
                      type="text"
                      className="input is-small mb-0"
                      value={norekdesa}
                      placeholder="Nomor Rekening"
                      readOnly
                    />
                  </div>

                  <div className="column is-half">
                    {/* <label className="label is-small mb-0">Nama Nasabah</label> */}
                      <input
                        title="Nama Nasabah"
                        type="text"
                        className="input is-small mb-0"
                        value={namadesa}
                        placeholder="Nama Nasabah"
                        readOnly
                        />
                  </div>
                </div>


          </div>

        <div className="tabs">
            <ul>
              <li
                className={activeTab === 0 ? 'is-active' : ''}
                onClick={() => handleTabClick(0)}>
                <div>Trans Internal</div>
              </li>
               <li
                className={activeTab === 1 ? 'is-active' : ''}
                onClick={() => handleTabClick(1)}>
               <div>Transfer Antar Bank</div>
              </li>

              <li
                className={activeTab === 2 ? 'is-active' : ''}
                onClick={() => handleTabClick(2)}>
               <div>Pajak</div>
              </li>

              <li
                className={activeTab === 3 ? 'is-active' : ''}
                onClick={() => handleTabClick(3)}>
               <div>BPJS</div>
              </li>
              
            </ul>
          </div>

          {activeTab === 0 && (
            <div>
              <DataTable
              columns={columnsInternal}
              data={Array.isArray(datarekeninginternal) ? datarekeninginternal : []}
              pagination
              fixedHeader
              fixedHeaderScrollHeight='600px'
              highlightOnHover
              />

            </div>
          )}
          {activeTab === 1 && (
            <div>
              <DataTable
               columns={columnsTransfer}
               data={Array.isArray(datarekeningtransfer) ? datarekeningtransfer : []}
               fixedHeader
               fixedHeaderScrollHeight='600px'
               highlightOnHover
               />
              {datarekeningtransfer != '' ? (
               <div className="mr-2"> 
                 <button className="button is-warning is-small is-pulled-right" onClick={() => {handleOpenModalSendAdminTnt('1');}} disabled={disabledbtnkirimtnttransfer}>Kirim Admin TNT</button>
              
               </div>
                 ) : null}   
            </div>
          )}
             {activeTab === 2 && (
            <div>
              <DataTable
               columns={columnsPajak}
               data={Array.isArray(datapajak) ? datapajak : []}
               fixedHeader
               fixedHeaderScrollHeight='600px'
               highlightOnHover
               />
               {datapajak != '' ? (
               <div className="mr-2"> 
                 <button className="button is-warning is-small is-pulled-right" onClick={() => {handleOpenModalSendAdminTnt('2');}} disabled={disabledbtnkirimtntpajak}>Kirim Admin TNT</button>
               </div>
               ) : null} 
            </div>
          )}
             {activeTab === 3 && (
            <div>
              <DataTable
               columns={columnsBpjs}
               data={Array.isArray(databpjs) ? databpjs : []}
               fixedHeader
               fixedHeaderScrollHeight='600px'
               highlightOnHover
               />
              {databpjs != '' ? (
               <div className="mr-2"> 
                 <button className="button is-warning is-small is-pulled-right" onClick={() => {handleOpenModalSendAdminTnt('3');}} disabled={disabledbtnkirimtntbpjs}>Kirim Admin TNT</button>
               </div>
                ) : null} 
            </div>
          )}

        </div>

        </section>
            <footer className="modal-card-foot">
              <button onClick={handleCloseModalDesa} className="button is-small is-danger">Close</button>  
            </footer>
        </div>
      </div>
      
      </div>




      <div className={`modal modal-fx-3dSlit ${isModalImageOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalImage()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Slider ref={sliderRef} {...sliderSettings} >
           
              {selectedFilesdb.map((item, index) => (
                <div key={index}                          
                className={`Image ${index === selectedImageIndex ? 'active' : ''}`}
                >

                  {item.ext === '.jpg' ? (
                    <img src={item.src} alt={`Image ${index + 1}`} width="100%" height="400px" />
                  ) : (
                    <embed src={item.src} ext="application/pdf" width="100%" height="400px" />
                  )}

                </div>                              
              ))}
            </Slider>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalImage()}></button>
        </div>
      </div>


{/* modal Kirim Admin TNT */}
<div className={`modal modal-fx-3dSlit ${isModalOpenSendAdminTnt ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalSendAdminTnt()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>   
          <p className="modal-card-title" ><b>Kirim ke User executor TNT </b></p>
          <HorizontalLine />

            <div>
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
            {loading && <Loading />}
            <div className="column is-half">
            {/* <label className="label is-small mb-0">User Pemroses</label> */}
              
                <p clclassNameass="control">
                  <div className="select is-fullwidth is-small mb-0">
                     <select
                         title="User Executor"
                          value={userexecutor}
                            onChange={(e) => setUserExecutor(e.target.value)}
                            >
                            <option value="">-- Pilih User Executor --</option>
                            {executor.map(exe => (
                            <option key={exe.kode_user_cbs} value={exe.kode_user_cbs}>
                            {exe.name}
                            </option>
                            ))}
                      </select>
                  </div>
              </p>
              </div>

            </div>

            </div>
            {jenistransaksi == '1' ? (
                <button className="button is-success is-small is-pulled-right" style={{marginLeft:'5px'}}  onClick={(e) => SendToAdminTntTransfer(e)}>Send Transfer</button>
            ): jenistransaksi == '2' ? (
              <button className="button is-success is-small is-pulled-right" style={{marginLeft:'5px'}}  onClick={(e) => SendToAdminTntPajak(e)}>Send Pajak</button>
            ): jenistransaksi == '3' ? (
              <button className="button is-success is-small is-pulled-right" style={{marginLeft:'5px'}}  onClick={(e) => SendToAdminTntBpjs(e)}>Send Bpjs</button>
            ):null }
          
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalSendAdminTnt()}></button>
        </div>
      </div>

      
      <div className={`modal modal-fx-3dSlit ${isModalOpenFileRespon ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalFileRespon()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <b>File : {nameFile}</b><br /><br />
            <Iframe src={urlFile}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalFileRespon()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenPrintLaporan ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalPrintLaporan()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Rekap Laporan</b></p>
              <button
                onClick={handleCloseModalPrintLaporan}
                className="delete"
                aria-label="close"
              />
            </header>

          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
        

                                <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Mulai</label>
                                      <input                                
                                        ref={dateInputRefRekapLapMulai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Mulai (dd/MM/yyyy)"   
                                      />

                                  </div>  
                             </div> 

                             <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Selesai</label>
                                      <input
                                        ref={dateInputRefRekapLapSelesai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Selesai (dd/MM/yyyy)"                              
                                      />

                                  </div>  
                             </div> 

                         
                             <div className="columns mb-0"> 
                             <div className="column is-full">
                              <label className="label is-small mb-0">Kantor Kas</label>
                              <p className="control">
                                <div className="select is-fullwidth is-small">                           
                                  <select
                                  value={kodekakas}
                                  onChange={(e) => setKodeKaKas(e.target.value)}
                                  >
                                  <option value="">-- Select --</option>
                                        {kodekakasall.map(ktr => (
                                          <option key={ktr.kode_kantor_kas} value={ktr.kode_kantor_kas}>
                                            {ktr.nama_kantor}
                                          </option>
                                        ))}
                                </select>
                                </div>
                                </p>
                     
                              </div>
                            </div>


                           


          </div>
         
          </section>
          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={handleOpenModalCetakLaporan}>OK</button>
        <button className="button is-danger is-small" onClick={handleCloseModalPrintLaporan}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalPrintLaporan()}></button>
        </div>
      </div>


      
      <div className={`modal modal-fx-3dSlit ${isModalOpenPrintRincianTrans ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalPrintRincianTrans()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Rincian Transaksi</b></p>
              <button
                onClick={handleCloseModalPrintRincianTrans}
                className="delete"
                aria-label="close"
              />
            </header>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
        

                                <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Mulai</label>

                                      <input                                
                                        ref={dateInputRefTransLapMulai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Mulai (dd/MM/yyyy)"   
                                      />

                                   

                                  </div>  
                             </div> 

                             <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Selesai</label>

                                      <input
                                        ref={dateInputRefTransLapSelesai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Selesai (dd/MM/yyyy)"                              
                                      />
                                   
                                        
                                  </div>  
                             </div> 

                         
                             <div className="columns mb-0"> 
                             <div className="column is-full">
                              <label className="label is-small mb-0">Kantor Kas</label>
                              <p className="control">
                                <div className="select is-fullwidth is-small">                           
                                  <select
                                  value={kodekakas}
                                  onChange={(e) => setKodeKaKas(e.target.value)}
                                  >
                                  <option value="">-- Select --</option>
                                        {kodekakasall.map(ktr => (
                                          <option key={ktr.kode_kantor_kas} value={ktr.kode_kantor_kas}>
                                            {ktr.nama_kantor}
                                          </option>
                                        ))}
                                </select>
                                </div>
                                </p>
                     
                              </div>
                            </div>


                            <div className="columns mb-0"> 
                              <div className="column is-full">
                                <label className="label is-small mb-0">Jenis Transaksi</label>
                                      <p className="control">
                                      <div className="select is-fullwidth is-small mb-0">
                                      <select
                                        title="Jenis Transaksi"
                                        value={jenistransselect}
                                        onChange={(e) => setJenisTransSelect(e.target.value)}
                                        >
                                        <option value="">-- Pilih Jenis Transaksi --</option>
                                        <option value="0">Transaksi Internal</option>
                                        <option value="1">Transaksi Antar Bank</option>
                                        <option value="2">Pembayaran Pajak</option>
                                        <option value="3">Pembayaran BPJS</option>
                                          
                                      </select>
                                      </div>
                                      </p>
                              </div>  
                              </div> 

                             

          </div>
         
          </section>
          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={handleOpenModalCetakRincianTrans}>OK</button>
        <button className="button is-danger is-small" onClick={handleCloseModalPrintRincianTrans}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalPrintRincianTrans()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenCetakLaporan ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalCetakLaporan()}></div>
        <div className="modal-content is-huge" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Iframe src={urlbyid}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalCetakLaporan()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenCetakRincianTrans ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalCetakRincianTrans()}></div>
        <div className="modal-content is-huge" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Iframe src={urlbyidrinciantrans}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalCetakRincianTrans()}></button>
        </div>
      </div>


      
      </div>
  );
};



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}


export default TransDesaList;
