import React, { useEffect, useState } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {useParams } from 'react-router-dom'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    PDFViewer } from "@react-pdf/renderer";
import angkaTerbilang from 'angka-menjadi-terbilang';


const PrintSlip = ({}) => {
    const { pencairanid } = useParams()
    const [datarekeninginternal, setDataRekeningInternal] = useState([]);
    const [datarekeningtransfer, setDataRekeningTransfer] = useState([]);
    const [datapajak, setDataPajak] = useState([]);
    const [databpjs, setDataBpjs] = useState([]);

    const [namadesa, setNamaDesa] = useState('')
    const [alamatdesa, setAlamatDesa] = useState('')
    const [norekdesa, setNorekDesa] = useState('')
    const [tglentry, setTglEntry] = useState('')
    const [namakantor, setNamaKantor] = useState('')
    const [jumlahnominal, setJumlahNominal] = useState('')


    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const axiosJWT = axios.create()

    const [logo, setLogo] = useState('')
    const [namainstansi, setNamaInstansi] = useState('')
    const [warnaslip, setWarnaSlip] = useState('')
    const [pageHeight, setPageHeight] = useState(25);

    

    let jmlnominalInt = 0;
    let jmlnominalTransfer = 0;
    let jmlnominalPajak = 0;
    let jmlnominalBpjs = 0;
    


        let url_patch = 'https://tntd.bprsbtb.co.id';
    

    useEffect(() => {
        getSlip();
        getInstansi();
    }, [])


function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    axiosJWT.interceptors.request.use(async (config) => {
      const currentDate = new Date()
      if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(`${url_patch}/token`)
          config.headers.Authorization = `Bearer ${response.data.accessToken}`
          setToken(response.data.accessToken)
          const decoded = jwt_decode(response.data.accessToken)
          setName(decoded.name)
          setExpire(decoded.exp)
      }
      return config
  }, (error) => Promise.reject(error))

  
  const getInstansi = async () => {
    const response = await axiosJWT.get(`${url_patch}/instansi`,{
      headers: {
          Authorization: `Bearer ${token}`
      }
    });
    setLogo(`${url_patch}/images/${response.data[0].instansi_logo}`);
    setNamaInstansi(response.data[0].instansi_nama);
    setWarnaSlip(response.data[0].warna_slip);
  };

const getSlip = async () => {
    const response = await axiosJWT.get(`${url_patch}/pencairan/${pencairanid}`,{
          headers: {
              Authorization: `Bearer ${token}`
          }
        });

        const datatransInternal = response.data.pencairan_trans
        .filter((dt) => dt.jenistransaksi === '0') // Filter data berdasarkan jenisrekening
        .map((dt) => ({
          no_rekening: dt.no_rekening,
          nama: dt.nama,
          nominal: dt.nominal,
          keterangan: dt.keterangan,
          jenisrekening: dt.jenisrekening,
          namabank: dt.namabank,
          validate: dt.validate,
        }
        ));

        const TotalNominalInternal = datatransInternal.reduce(
          (accumulator, currentData) => accumulator + Number(currentData.nominal),
          0
        );

  
        const datatransTransfer = response.data.pencairan_trans
        .filter((dt) => dt.jenistransaksi === '1') // Filter data berdasarkan jenisrekening
        .map((dt) => ({
          no_rekening: dt.no_rekening,
          nama: dt.nama,
          nominal: dt.nominal,
          keterangan: dt.keterangan,
          jenisrekening: dt.jenisrekening,
          namabank: dt.namabank,
          validate: dt.validate,
        }));

        
        const TotalNominalTransfer = datatransTransfer.reduce(
          (accumulator, currentData) => accumulator + Number(currentData.nominal),
          0
        );


        const datatransPajak = response.data.pencairan_trans
        .filter((dt) => dt.jenistransaksi === '2') // Filter data berdasarkan jenisrekening
        .map((dt) => ({
          jenispajak: dt.jenis_pajak,
          idbiling: dt.id_biling,
          masapajak: dt.masa_pajak,
          keterangan: dt.keterangan,
          nominal: dt.nominal,
        }));

        
        const TotalNominalPajak = datatransPajak.reduce(
          (accumulator, currentData) => accumulator + Number(currentData.nominal),
          0
        );

        const datatransBpjs = response.data.pencairan_trans
        .filter((dt) => dt.jenistransaksi === '3') // Filter data berdasarkan jenisrekening
        .map((dt) => ({
          nova: dt.nomor_virtual_account,
          keterangan: dt.keterangan,
          nominal: dt.nominal,
        }));

        
        const TotalNominalBpjs = datatransBpjs.reduce(
          (accumulator, currentData) => accumulator + Number(currentData.nominal),
          0
        );

        const TotalNominal = parseFloat(TotalNominalInternal)+parseFloat(TotalNominalTransfer)+parseFloat(TotalNominalPajak)+parseFloat(TotalNominalBpjs);

        setDataRekeningInternal(datatransInternal);
        setDataRekeningTransfer(datatransTransfer);
        setDataPajak(datatransPajak);
        setDataBpjs(datatransBpjs);

        setJumlahNominal(TotalNominal);
        setNamaDesa(response.data.response.nama_desa);
        setAlamatDesa(response.data.response.alamat_desa);
        setNorekDesa(response.data.response.norek_desa);
        setTglEntry(response.data.response.tgl_entry);
        setNamaKantor(response.data.response.nama_kantor);
       
}


const HorizontalLine = () => {
  return (
    <div
      style={{
        borderTop: '1px solid #333',
        width: '100%',
      }}
    ></div>
  );
};

const generateTableDataInternal = () => {
  let i = 1;
  let tableDataInt = []; 
  let tableInt = []; 

  tableDataInt.push(
        <View fixed style={styles.tableRow}>
        <View style={[styles.tableHeader, {width:'20px',  borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>No</Text>
        </View>
        <View style={[styles.tableHeader, {width:'120px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nama Rekening</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>No. Rekening</Text>
        </View>
        <View style={[styles.tableHeader, {width:'250px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Keterangan</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nominal</Text>
        </View>
      </View>
  );

  datarekeninginternal.forEach((item, index) => {
      const nominal = Number(item.nominal);
      if (!isNaN(nominal)) {
        jmlnominalInt += nominal;
      }

      tableDataInt.push(
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCell, { width: '20px', padding: 2, borderLeft: '1px dotted black' }]}>
            <Text>{i++}</Text>
          </View>
          <View style={[styles.tableCell, { width: '120px', padding: 2 }]}>
            <Text>{item.nama}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text>{item.no_rekening}</Text>
          </View>
          <View style={[styles.tableCell, { width: '250px', padding: 2 }]}>
            <Text>{item.keterangan}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.nominal)}</Text>
          </View>
        </View>
      );
  });


  tableDataInt.push(
    <View style={styles.tableRow}>
    <View style={[styles.tableCell, {width:'470px', borderColor: '#fff', borderLeft: '1px dotted white', backgroundColor: warnaslip, padding: 2}]}>
      <Text style={{color: 'white', textAlign: 'center'}}>Total</Text>
    </View>
    <View style={[styles.tableCell, {width:'80px', padding: 2}]}>
    <Text style={{ textAlign: "right", fontFamily: "Helvetica-Bold" }}>{new Intl.NumberFormat('id-ID').format(jmlnominalInt)}</Text>
    </View>
  </View>
);

tableInt.push(
  <View>
    <View>
        <Text>Rekening Tabungan di {namainstansi}:</Text>
    </View>
    <View style={[styles.table, { marginLeft : '8'}]}>
      {tableDataInt}
    </View>
  </View>
);

  return tableInt;
};



const generateTableDataTransfer = () => {
  let i = 1;
  let tableDataTransfer = []; 
  let tableTransfer = []; 

  tableDataTransfer.push(
        <View fixed style={styles.tableRow}>
        <View style={[styles.tableHeader, {width:'20px',  borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>No</Text>
        </View>
        <View style={[styles.tableHeader, {width:'120px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nama Rekening</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>No. Rekening</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nama Bank</Text>
        </View>
        <View style={[styles.tableHeader, {width:'170px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Keterangan</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nominal</Text>
        </View>
      </View>
  );

  datarekeningtransfer.forEach((item, index) => {
      const nominal = Number(item.nominal);
      if (!isNaN(nominal)) {
        jmlnominalTransfer += nominal;
      }

      tableDataTransfer.push(
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCell, { width: '20px', padding: 2, borderLeft: '1px dotted black' }]}>
            <Text>{i++}</Text>
          </View>
          <View style={[styles.tableCell, { width: '120px', padding: 2 }]}>
            <Text>{item.nama}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text>{item.no_rekening}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text>{item.namabank}</Text>
          </View>
          <View style={[styles.tableCell, { width: '170px', padding: 2 }]}>
            <Text>{item.keterangan}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.nominal)}</Text>
          </View>
        </View>
      );
  });


  tableDataTransfer.push(
    <View style={styles.tableRow}>
    <View style={[styles.tableCell, {width:'470px', borderColor: '#fff', borderLeft: '1px dotted white', backgroundColor: warnaslip, padding: 2}]}>
      <Text style={{color: 'white', textAlign: 'center'}}>Total</Text>
    </View>
    <View style={[styles.tableCell, {width:'80px', padding: 2}]}>
    <Text style={{ textAlign: "right", fontFamily: "Helvetica-Bold" }}>{new Intl.NumberFormat('id-ID').format(jmlnominalTransfer)}</Text>
    </View>
  </View>
);

tableTransfer.push(
<View>
<View>
    <Text>Rekening Tabungan di Bank Umum:</Text>
</View>
<View style={[styles.table, { marginLeft : '8'}]}>
  {tableDataTransfer}
</View>
</View>

);

  return tableTransfer;
};



const generateTableDataPajak = () => {
  let i = 1;
  let tableDataPajak = []; 
  let tablePajak = []; 

  tableDataPajak.push(
        <View fixed style={styles.tableRow}>
        <View style={[styles.tableHeader, {width:'20px',  borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>No</Text>
        </View>
        <View style={[styles.tableHeader, {width:'120px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Jenis Pajak</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Id Billing</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Masa Pajak</Text>
        </View>
        <View style={[styles.tableHeader, {width:'170px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Keterangan</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nominal</Text>
        </View>
      </View>
  );

  datapajak.forEach((item, index) => {
      const nominal = Number(item.nominal);
      if (!isNaN(nominal)) {
        jmlnominalPajak += nominal;
      }

      tableDataPajak.push(
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCell, { width: '20px', padding: 2, borderLeft: '1px dotted black' }]}>
            <Text>{i++}</Text>
          </View>
          <View style={[styles.tableCell, { width: '120px', padding: 2 }]}>
            <Text>{item.jenispajak}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text>{item.idbiling}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text>{item.masapajak}</Text>
          </View>
          <View style={[styles.tableCell, { width: '170px', padding: 2 }]}>
            <Text>{item.keterangan}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.nominal)}</Text>
          </View>
        </View>
      );
  });


  tableDataPajak.push(
    <View style={styles.tableRow}>
    <View style={[styles.tableCell, {width:'470px', borderColor: '#fff', borderLeft: '1px dotted white', backgroundColor: warnaslip, padding: 2}]}>
      <Text style={{color: 'white', textAlign: 'center'}}>Total</Text>
    </View>
    <View style={[styles.tableCell, {width:'80px', padding: 2}]}>
    <Text style={{ textAlign: "right", fontFamily: "Helvetica-Bold" }}>{new Intl.NumberFormat('id-ID').format(jmlnominalPajak)}</Text>
    </View>
  </View>
);

tablePajak.push(
<View>
<View>
    <Text>Data Pembayaran Pajak:</Text>
</View>
<View style={[styles.table, { marginLeft : '8'}]}>
  {tableDataPajak}
</View>
</View>

);

  return tablePajak;
};


const generateTableDataBpjs = () => {
  let i = 1;
  let tableDataBpjs = []; 
  let tableBpjs = []; 

  tableDataBpjs.push(
        <View fixed style={styles.tableRow}>
        <View style={[styles.tableHeader, {width:'20px',  borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>No</Text>
        </View>
        <View style={[styles.tableHeader, {width:'120px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nomor VA</Text>
        </View>
        <View style={[styles.tableHeader, {width:'330px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Keterangan</Text>
        </View>
        <View style={[styles.tableHeader, {width:'80px', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
          <Text style={{color: 'white'}}>Nominal</Text>
        </View>
      </View>
  );

  databpjs.forEach((item, index) => {
      const nominal = Number(item.nominal);
      if (!isNaN(nominal)) {
        jmlnominalBpjs += nominal;
      }

      tableDataBpjs.push(
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCell, { width: '20px', padding: 2, borderLeft: '1px dotted black' }]}>
            <Text>{i++}</Text>
          </View>
          <View style={[styles.tableCell, { width: '120px', padding: 2 }]}>
            <Text>{item.nova}</Text>
          </View>
          <View style={[styles.tableCell, { width: '330px', padding: 2 }]}>
            <Text>{item.keterangan}</Text>
          </View>
          <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
            <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.nominal)}</Text>
          </View>
        </View>
      );
  });


  tableDataBpjs.push(
    <View style={styles.tableRow}>
    <View style={[styles.tableCell, {width:'470px', borderColor: '#fff', borderLeft: '1px dotted white', backgroundColor: warnaslip, padding: 2}]}>
      <Text style={{color: 'white', textAlign: 'center'}}>Total</Text>
    </View>
    <View style={[styles.tableCell, {width:'80px', padding: 2}]}>
    <Text style={{ textAlign: "right", fontFamily: "Helvetica-Bold" }}>{new Intl.NumberFormat('id-ID').format(jmlnominalBpjs)}</Text>
    </View>
  </View>
);

tableBpjs.push(
<View>
<View>
    <Text>Data Pembayaran BPJS:</Text>
</View>
<View style={[styles.table, { marginLeft : '8'}]}>
  {tableDataBpjs}
</View>
</View>

);

  return tableBpjs;
};


var TampilTgl = function (yourDate) {
  var date = new Date(yourDate);
  var tahun = date.getFullYear();
  var bulan = date.getMonth();
  var tanggal = date.getDate();
  var hari = date.getDay();
  var jam = date.getHours();
  var menit = date.getMinutes();
  var detik = date.getSeconds();
  switch(hari) {
   case 0: hari = "Minggu"; break;
   case 1: hari = "Senin"; break;
   case 2: hari = "Selasa"; break;
   case 3: hari = "Rabu"; break;
   case 4: hari = "Kamis"; break;
   case 5: hari = "Jum'at"; break;
   case 6: hari = "Sabtu"; break;
  }
  switch(bulan) {
   case 0: bulan = "Januari"; break;
   case 1: bulan = "Februari"; break;
   case 2: bulan = "Maret"; break;
   case 3: bulan = "April"; break;
   case 4: bulan = "Mei"; break;
   case 5: bulan = "Juni"; break;
   case 6: bulan = "Juli"; break;
   case 7: bulan = "Agustus"; break;
   case 8: bulan = "September"; break;
   case 9: bulan = "Oktober"; break;
   case 10: bulan = "November"; break;
   case 11: bulan = "Desember"; break;
  }
  
  var TampilTgl = tanggal + " " + bulan + " " + tahun;
  
  
  return TampilTgl;
  }



// const settanggal = (e) => {
//   var date = new Date(e); 
//   return (("0" + date.getDate()).slice(-2)  + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()).toLocaleString('en-ID', { hour12:false } );
  
// }


    return (

      <PDFViewer style={styles.viewer}>
      <Document title='Slip'>
       <Page size='A4' style={styles.page}>
       <View style={[styles.table]}>

          <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1}]}>
            <View style={[styles.Row, {backgroundColor: warnaslip, width: '440px', height: '30px', paddingTop: '8px', paddingLeft: '20px',}]}>
              <Text style={{fontSize: '12px', color: 'white'}}>Formulir Pemindahbukuan / Non Cash Transaction</Text>
            </View>
            <View style={[styles.Row, {paddingLeft : '5px', paddingTop : '5px'}]}><Image src={logo} style={styles.image} /></View>
          </View>

          <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1,  height: '50px',}]}>

              <View style={[styles.Row, {borderRight: 1, borderColor: warnaslip, borderStyle: 'solid' , width: '60%',  height: '50px',}]}>
                <Text style={{fontSize: '9px', marginLeft: '2px'}}>Validasi :</Text>
              </View>

              <View style={[styles.Row, {width: '40%',  height: '50px',}]}>
           
                    <View style={[styles.table, {border: 0}]}>
                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'60px', paddingLeft: '3', paddingBottom: '3', border: 0}]}>
                              <Text>Tanggal</Text>
                            </View>
                            <View style={[styles.tableCell, {width:'200px', border: 0}]}>
                              <Text>:  {TampilTgl(tglentry)}</Text>
                            </View>
                          </View>
                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'60px', paddingLeft: '3', border: 0}]}>
                              <Text>Kantor</Text>
                            </View>
                            <View style={[styles.tableCell, {width:'200px', border: 0}]}>
                              <Text>:  {namakantor}</Text>
                            </View>
                          </View>
                    </View>


              </View>
            </View>
          <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1,  height: '120px',}]}>
       
                    <View style={[styles.table, {border: 0, marginLeft: '20px'}]}>
                    
                          <View fixed style={[styles.tableRow, {border: 0, marginTop: '8px'}]}>
                            <View style={[styles.tableCell, {border: 0, paddingBottom: '3', flex: 2}]}>
                              <Text>Yang bertanda tangan dibawah ini, kami selaku penanggung jawab Rekening Kas Desa :</Text>
                            </View>
                          </View>

                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'120px', paddingBottom: '3', border: 0}]}>
                              <Text>Pemerintah Desa</Text>
                            </View>
                            <View style={[styles.tableCell, { paddingBottom: '3', border: 0}]}>
                              <Text>: {namadesa}</Text>
                            </View>
                          </View>

                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'120px', paddingBottom: '3', border: 0}]}>
                              <Text>Alamat</Text>
                            </View>
                            <View style={[styles.tableCell, { paddingBottom: '3', border: 0}]}>
                              <Text>: {alamatdesa}</Text>
                            </View>
                          </View>


                          
                          <View fixed style={[styles.tableRow, {border: 0, marginTop: '10px'}]}>
                            <View style={[styles.tableCell, {border: 0, paddingBottom: '3', flex: 2}]}>
                              <Text>Bersama ini kami mohon kepada teller/kasir {namainstansi} untuk dipindahbukukan dana dari rekening kas desa kami :</Text>
                            </View>
                          </View>

                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'120px', paddingBottom: '3', border: 0}]}>
                              <Text>Nomor Rekening Kas Desa</Text>
                            </View>
                            <View style={[styles.tableCell, { paddingBottom: '3', border: 0}]}>
                              <Text>: {norekdesa}</Text>
                            </View>
                          </View>

                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'120px', paddingBottom: '3', border: 0}]}>
                              <Text>Atas Nama</Text>
                            </View>
                            <View style={[styles.tableCell, { paddingBottom: '3', border: 0}]}>
                              <Text>: {namadesa}</Text>
                            </View>
                          </View>

                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'120px', paddingBottom: '3', border: 0}]}>
                              <Text>Jumlah (Total)</Text>
                            </View>
                            <View style={[styles.tableCell, { paddingBottom: '3', border: 0}]}>
                              <Text>: Rp. {new Intl.NumberFormat('id-ID').format(jumlahnominal)}  </Text>
                            </View>
                          </View>

                          <View fixed style={[styles.tableRow, {border: 0}]}>
                            <View style={[styles.tableCell, {width:'120px', paddingBottom: '3', border: 0}]}>
                              <Text>Terbilang</Text>
                            </View>
                            <View style={[styles.tableCell, { paddingBottom: '3', border: 0}]}>
                              <Text>: {angkaTerbilang(jumlahnominal).split(' ').map(capitalizeFirstLetter).join(' ')} Rupiah</Text>
                            </View>
                          </View>


                    </View>
          </View> 

            <View style={[styles.Row, {backgroundColor: warnaslip, borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3'}]}>
              <Text style={{ color: 'white'}}>Ke dalam rekening-rekening sebagai berikut :</Text>
          </View>


                  {datarekeninginternal != '' ? (
                        <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                          {generateTableDataInternal()}
                        </View>
                    ):null}
                    {datarekeningtransfer != '' ? (
                        <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                            {generateTableDataTransfer()}
                        </View>
                    ):null}
                    {datapajak != '' ? (
                        <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                            {generateTableDataPajak()}
                        </View>
                    ):null}
                    {databpjs != '' ? (
                        <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                            {generateTableDataBpjs()}
                        </View>
                    ):null}
        
                 
                        <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                          <View style={[styles.table]}>
                            <View  style={[styles.tableRow, {marginLeft : '8'}]}>
                              <View style={[styles.tableCell, {width:'470px', borderColor: '#fff', backgroundColor: warnaslip, borderLeft: '1px dotted white', borderTop: '1px dotted white', padding: 2}]}>
                                <Text style={{color: 'white'}}>Grand Total</Text>
                              </View>
                              <View style={[styles.tableCell, {width:'80px', borderTop: '1px dotted black', padding: 2}]}>
                                <Text style={{ textAlign: "right", fontFamily: "Helvetica-Bold"}}>{new Intl.NumberFormat('id-ID').format(parseFloat(jmlnominalInt) + parseFloat(jmlnominalTransfer) + parseFloat(jmlnominalPajak) + parseFloat(jmlnominalBpjs))}</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                  
                        <View style={[styles.Row, {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1, paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                          <Text>Apabila dikemudian hari terjadi kesalahan penyampaian data tersebut, maka kami akan bertanggung jawab atas kesalahan penyampaian data tersebut</Text>
                        </View>
                      

                        <View style={[styles.Row,  {borderColor: warnaslip, borderStyle: 'solid', borderWidth: 1}]}>  
                       
                            <View style={[styles.table]}>
                              <View style={[styles.tableRow]}>
                                <View style={[styles.tableHeader, {width:'50%',  borderColor: '#fff', backgroundColor: warnaslip, padding: 2, flex: 2}]}>
                                  <Text style={{color: 'white'}}>Tanda Tangan Nasabah</Text>
                                </View>
                                <View style={[styles.tableHeader, {width:'25%', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
                                  <Text style={{color: 'white'}}>Cap/Stempel Desa</Text>
                                </View>
                                <View style={[styles.tableHeader, {width:'25%', borderColor: '#fff', backgroundColor: warnaslip, padding: 2}]}>
                                  <Text style={{color: 'white'}}>Tanda Tangan Kasir</Text>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={[styles.tableCell, { width: '25%', height: '80px', padding: 2 }]}>
                                  <Text style={{textAlign: 'center', marginBottom: '60px'}}>Kepala Desa</Text>
                                  <HorizontalLine />
                                  <Text style={{fontSize: '7px', textAlign: 'center'}}>Tanda tangan, nama terang</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '25%', height: '80px', padding: 2 }]}>
                                  <Text style={{textAlign: 'center', marginBottom: '60px'}}>Bendahara Desa</Text>
                                  <HorizontalLine />
                                  <Text style={{fontSize: '7px', textAlign: 'center'}}>Tanda tangan, nama terang</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '25%', height: '80px', padding: 2 }]}>
                                  <Text></Text>
                                </View>
                                <View style={[styles.tableCell, { width: '25%', height: '80px', padding: 2 }]}>
                                  <Text style={{textAlign: 'center', marginBottom: '67px'}}></Text>
                                  <HorizontalLine />
                                  <Text style={{fontSize: '7px', textAlign: 'center'}}>Tanda tangan, nama terang</Text>
                                </View>
                              </View>
                            </View>
                          
                        </View>
                  
                
                        <View style={[styles.Row, {paddingLeft: '3', paddingTop: '5px', paddingBottom: '5px'}]}>
                            <View style={[styles.table]}>

                                <View style={styles.tableRow}>
                                  <View style={[styles.tableCell, { border: 0, width: '10px'}]}>
                                    <Text>1.</Text>
                                  </View>
                                  <View style={[styles.tableCell, { border: 0}]}>
                                    <Text>Slip dianggap sah oleh Bank apabila dibubuhi stempel dan tanda tangan kasir.</Text>
                                  </View>
                                </View>
                                
                                <View style={styles.tableRow}>
                                  <View style={[styles.tableCell, { border: 0, width: '10px'}]}>
                                    <Text>2.</Text>
                                  </View>
                                  <View style={[styles.tableCell, { border: 0}]}>
                                    <Text>Setoran akan dibukukan secara efektif setelah dana diterima dan divalidasi oleh Bank.</Text>
                                  </View>
                                </View>
                                
                                <View style={styles.tableRow}>
                                  <View style={[styles.tableCell, { border: 0, width: '10px' }]}>
                                    <Text>3.</Text>
                                  </View>
                                  <View style={[styles.tableCell, { border: 0 }]}>
                                    <Text>Sesuai dengan ketentuan tentang Pedoman Anti Pencucian Uang dan Pencegahan Pendanaan Terorisme.</Text>
                                  </View>
                                </View>

                            </View>
                        </View>



        </View>

      </Page>
    </Document>
      </PDFViewer>
    );
  }


  // const PageBreak = () => (
  //   <View style={styles.page}>
  //     <Text>Page Break</Text>
  //   </View>
  // );
  

// Stylesheet untuk PDF
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 8,
    padding: 10,
    paddingBottom: 50, 
    flexDirection: "row",
  },
  pageBreak: {
    pageBreakBefore: 'always',
  },
  section: {
      // paddingLeft: 40,
      // paddingTop: 20,
      // flexGrow: 1,
      margin: 40,
      padding: 10,
      flexGrow: 1,

  },
  viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
  },
  Row: {
    flexDirection: "row",
  },
  image: {
    width: 120,
    height: 20,
  },
  h3: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center" ,
  },

  table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0,  overflow: 'wrap'  },
  tableRow: { flexDirection: 'row' },
  tableHeader: { backgroundColor: '#f0f0f0', width: '100%', borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', borderTop: '1px solid black',},
  tableCell: { borderBottom: '1px dotted black', borderRight: '1px dotted black' },

  textContainer:{
    left: '10px',
  }
});



  export default PrintSlip;

  