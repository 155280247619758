import React, { useEffect, useState } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {useParams } from 'react-router-dom'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    PDFViewer } from "@react-pdf/renderer";


const PrintLaporanKantor = ({}) => {
    const { kodekakas,  tglmulailap, tglselesailap} = useParams()

    const [datalaporan, setDataLaporan] = useState([]);

    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const axiosJWT = axios.create()

    const [namainstansi, setNamaInstansi] = useState('')
    const [alamatinstansi, setAlamatInstansi] = useState('')
    const [telpinstansi, setTelpInstansi] = useState('')
    const [logo, setLogo] = useState('')
    const [namadesa, setNamaDesa] = useState('')
    const [namakecamatan, setNamaKecamatan] = useState('')
    const [nospp, setNoSpp] = useState('')

    let grandtotal = 0;

    let url_patch = 'https://tntd.bprsbtb.co.id';
    

    useEffect(() => {
        getLaporan();
        getInstansi();
    }, [])


function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    axiosJWT.interceptors.request.use(async (config) => {
      const currentDate = new Date()
      if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(`${url_patch}/token`)
          config.headers.Authorization = `Bearer ${response.data.accessToken}`
          setToken(response.data.accessToken)
          const decoded = jwt_decode(response.data.accessToken)
          setName(decoded.name)
          setExpire(decoded.exp)
      }
      return config
  }, (error) => Promise.reject(error))

  


const getLaporan = async () => {
    const response = await axiosJWT.get(`${url_patch}/cetaklaporankantor/${kodekakas}/${tglmulailap}/${tglselesailap}`,{
          headers: {
              Authorization: `Bearer ${token}`
          }
        });
        setDataLaporan(response.data) 
        // setNamaDesa(response.data[0].nama_desa) 
        // setNamaKecamatan(response.data[0].nama_kecamatan) 

}

const getInstansi = async () => {
  const response = await axiosJWT.get(`${url_patch}/instansi`,{
    headers: {
        Authorization: `Bearer ${token}`
    }
  });
  setLogo(`${url_patch}/images/${response.data[0].instansi_logo}`);
  setNamaInstansi(response.data[0].instansi_nama);
  setAlamatInstansi(response.data[0].instansi_alamat);
  setTelpInstansi(response.data[0].instansi_telp);
};


// Komponen Header
const PageHeader = () => {
  const styles = StyleSheet.create({
    header: {
      marginBottom: 10,
      textAlign: "center",
      fontSize: 10,
    },
    hr: {
      borderBottomColor: "black",
      borderBottomWidth: 1,
      marginVertical: 10,
    },
    image: {
      width: 150,
      height: 30,
    },
    Row: { flexDirection: 'row' },

  });

  return (
    <View fixed style={styles.header}>
      <View style={styles.Row}>
      <Image src={logo} style={styles.image} />
      <Text style={{textAlign:'left', fontSize:'10', marginLeft:'5', paddingTop:'5', paddingLeft: '5', borderLeftWidth:'1', borderLeftColor: "grey"}}>{namainstansi}{'\n'}
      {alamatinstansi}{'\n'}
      {telpinstansi}
      </Text>
      </View>
      <View style={styles.hr} />
    </View>
    
  );
};


const HorizontalLine = () => {
  return (
    <div
      style={{
        borderTop: '1px solid #333',
        width: '100%',
      }}
    ></div>
  );
};





const generateTableDataLaporan = () => {
  let i = 1;
  let tableDataLap = []; 
  let tableLap = []; 

  
    tableDataLap.push(
      <View fixed style={styles.tableRow}>
      <View style={[styles.tableHeader, {width:'20px', borderLeft: '1px solid black',  borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>No</Text>
      </View>
      <View style={[styles.tableHeader, {width:'80px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>Tgl Entry</Text>
      </View>
      <View style={[styles.tableHeader, {width:'120px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>ID Pencairan</Text>
      </View>
      <View style={[styles.tableHeader, {width:'120px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>No SPP</Text>
      </View>
      <View style={[styles.tableHeader, {width:'80px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>No. Rekening Desa</Text>
      </View>
      <View style={[styles.tableHeader, {width:'150px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>Nama Desa</Text>
      </View>
      <View style={[styles.tableHeader, {width:'120px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>Nama Kecamatan</Text>
      </View>
      <View style={[styles.tableHeader, {width:'150px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>Rincian Trans</Text>
      </View>
      <View style={[styles.tableHeader, {width:'100px', borderColor: '#000', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black'}}>Nominal</Text>
      </View>
    </View>
  );


  
    
  if(datalaporan.length > 0){
      datalaporan.forEach((item, index) => {
          const nominal = Number(item.total_nominal);
          if (!isNaN(nominal)) {
            grandtotal += nominal;
          }
      const StatusTrans = item.status_trans.split('|');

            tableDataLap.push(
              <View style={styles.tableRow} key={index}>
                <View style={[styles.tableCell, { width: '20px', padding: 2, borderLeft: '1px dotted black', textAlign: 'center' }]}>
                  <Text>{i++}</Text>
                </View>
                <View style={[styles.tableCell, { width: '80px', padding: 2, textAlign: 'center' }]}>
                  <Text>{settanggal(item.tgl_entry)}</Text>
                </View>
                <View style={[styles.tableCell, { width: '120px', padding: 2 }]}>
                  <Text>{item.pencairan_id}</Text>
                </View>
                <View style={[styles.tableCell, { width: '120px', padding: 2}]}>
                  <Text>{item.no_spp}</Text>
                </View>
                <View style={[styles.tableCell, { width: '80px', padding: 2 }]}>
                  <Text>{item.norek_desa}</Text>
                </View>
                <View style={[styles.tableCell, { width: '150px', padding: 2 }]}>
                  <Text>{item.nama_desa}</Text>
                </View>
                <View style={[styles.tableCell, { width: '120px', padding: 2 }]}>
                  <Text>{item.nama_kecamatan}</Text>
                </View>
                <View style={[styles.tableCell, { width: '150px', padding: 2 }]}>
                <Text>
                  {StatusTrans.map((statustrans, index) => (
                    <Text key={index}>
                      {index > 0 && '\n'}{statustrans}
                    </Text>
                  ))}
                </Text>
              </View>
                <View style={[styles.tableCell, { width: '100px', padding: 2 }]}>
                  <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.total_nominal)}</Text>
                </View>
              </View>
            )
      });

        tableDataLap.push(
          <View fixed style={styles.tableRow}>
            <View style={[styles.tableCell, {width:'840px', borderColor: '#000', borderLeft: '1px dotted black', backgroundColor: '#dcdcdc', padding: 2}]}>
              <Text style={{color: 'black', textAlign: 'center'}}>Total</Text>
            </View>
            <View style={[styles.tableCell, {width:'100px', padding: 2}]}>
            <Text style={{ textAlign: "right", fontWeight: "bold" }}>{new Intl.NumberFormat('id-ID').format(grandtotal)}</Text>
            </View>
          </View>
        )

  }else if(datalaporan.length == 0){
    tableDataLap.push(
      <View fixed style={styles.tableRow}>
      <View style={[styles.tableCell, {width:'840px', borderColor: '#000', borderLeft: '1px dotted white', backgroundColor: '#dcdcdc', padding: 2}]}>
        <Text style={{color: 'black', textAlign: 'center'}}>Tidak Ada Data</Text>
      </View>
    </View>
  );
  }

  tableLap.push(
    <View>
      <View style={[styles.table, {pageBreakInside : "auto", marginLeft : '8'}]}>
        {tableDataLap}
      </View>
    </View>
  );


  return tableLap;
};



var TampilTgl = function (yourDate) {
  var date = new Date(yourDate);
  var tahun = date.getFullYear();
  var bulan = date.getMonth();
  var tanggal = date.getDate();
  var hari = date.getDay();
  var jam = date.getHours();
  var menit = date.getMinutes();
  var detik = date.getSeconds();
  switch(hari) {
   case 0: hari = "Minggu"; break;
   case 1: hari = "Senin"; break;
   case 2: hari = "Selasa"; break;
   case 3: hari = "Rabu"; break;
   case 4: hari = "Kamis"; break;
   case 5: hari = "Jum'at"; break;
   case 6: hari = "Sabtu"; break;
  }
  switch(bulan) {
   case 0: bulan = "Januari"; break;
   case 1: bulan = "Februari"; break;
   case 2: bulan = "Maret"; break;
   case 3: bulan = "April"; break;
   case 4: bulan = "Mei"; break;
   case 5: bulan = "Juni"; break;
   case 6: bulan = "Juli"; break;
   case 7: bulan = "Agustus"; break;
   case 8: bulan = "September"; break;
   case 9: bulan = "Oktober"; break;
   case 10: bulan = "November"; break;
   case 11: bulan = "Desember"; break;
  }
  
  var TampilTgl = tanggal + " " + bulan + " " + tahun;
  
  
  return TampilTgl;
  }



const settanggal = (e) => {
  var date = new Date(e); 
  return (("0" + date.getDate()).slice(-2)  + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()).toLocaleString('en-ID', { hour12:false } );
  
}


    return (

      <PDFViewer style={styles.viewer}>
      <Document title='Slip'>
       <Page size='A4' style={styles.page} orientation="landscape">
       <View style={[styles.table, { pageBreakInside : "auto" }]}>
       <PageHeader />
        <View style={styles.h3}><Text>REKAP LAPORAN DATA TRANSAKSI DESA</Text></View>
       <View style={{fontSize: '10', textAlign: "center"}}><Text>Periode : ({TampilTgl(tglmulailap)} - {TampilTgl(tglselesailap)})</Text></View>
                  <View style={[styles.Row, {paddingLeft: '3', paddingRight: '13',  paddingTop: '5px', paddingBottom: '5px'}]}>
                     {generateTableDataLaporan()}
                  </View>                 
        </View>

      </Page>
    </Document>
      </PDFViewer>
    );
  }

// Stylesheet untuk PDF
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 8,
    padding: 40,
    paddingBottom: 50,
  },

  section: {
      paddingLeft: 40,
      paddingTop: 20,
  },
  viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
  },
  Row: {
    flexDirection: "row",
  },
  image: {
    width: 120,
    height: 20,
  },
  h3: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },

  table: { display: 'table', width: '100%', borderColor: '#0070C0', borderStyle: 'solid', borderWidth: 0,  overflow: 'wrap'  },
  tableRow: { flexDirection: 'row' },
  tableHeader: { backgroundColor: '#f0f0f0', width: '100%', borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', borderTop: '1px solid black',},
  tableCell: { borderBottom: '1px dotted black', borderRight: '1px dotted black' },

  textContainer:{
    left: '10px',
  }
});



  export default PrintLaporanKantor;

  