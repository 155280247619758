import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Kantor from "./pages/Kantor";
import Kecamatan from "./pages/Kecamatan";
import Aba from "./pages/Aba";
import Desa from "./pages/Desa";
import Tnt from "./pages/Tnt";
import UsersExt from "./pages/UsersExt";
import ConfigApp from "./pages/ConfigApp";
import Instansi from "./pages/Instansi";
import PencairanDesa from "./pages/PencairanDesa";
import TransDesa from "./pages/TransDesa";
import PrintSlip from './components/PrintSlip';
import PrintLaporanDesa from './components/PrintLaporanDesa';
import PrintLaporanRincianTrans from './components/PrintLaporanRincianTrans';
import PrintLaporanKantor from './components/PrintLaporanKantor';
import PrintLaporanRincianTransKantor from './components/PrintLaporanRincianTransKantor';
import PrintLaporanTnt from './components/PrintLaporanTnt';
import { LogOut, reset, getMe } from "./features/authSlice";
import { useDispatch } from "react-redux";
// import env from "react-dotenv";
// import axios from "axios";


function App() {
  const dispatch = useDispatch();


  useEffect(() => {
    let isLogged = sessionStorage.getItem("islogin");
    if (!isLogged) {
      dispatch(LogOut());
      dispatch(reset());
    } else {
      const interval = setInterval(() => {
        window.location.reload(); // Memuat ulang halaman
        dispatch(getMe());   
      }, 601000);
      return () => clearInterval(interval);
    }
  }, []);

   return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/listtnt" element={<Tnt />} />
          <Route path="/usersext" element={<UsersExt />} />
          <Route path="/configapp" element={<ConfigApp />} />
          <Route path="/instansi" element={<Instansi />} />
          <Route path="/PrintSlip/:pencairanid" element={<PrintSlip />} />
          <Route path="/printlaporandesa/:role/:kodedesa/:tglmulailap/:tglselesailap" element={<PrintLaporanDesa />} />
          <Route path="/printrinciantrans/:role/:kodedesa/:tglmulailap/:tglselesailap/:jenistrans" element={<PrintLaporanRincianTrans />} />
          <Route path="/printlaporankantor/:kodekakas/:tglmulailap/:tglselesailap" element={<PrintLaporanKantor />} />
          <Route path="/printrinciantranskantor/:kodekakas/:tglmulailap/:tglselesailap/:jenistrans" element={<PrintLaporanRincianTransKantor />} />
          <Route path="/printlaporantnt/:kodekakas/:tglmulailap/:tglselesailap" element={<PrintLaporanTnt />} />
          <Route path="/pencairandesa" element={<PencairanDesa />} />
          <Route path="/transdesa" element={<TransDesa />} />
          <Route path="/reffkantor" element={<Kantor />} />
          <Route path="/reffkecamatan" element={<Kecamatan />} />
          <Route path="/reffaba" element={<Aba />} />
          <Route path="/reffdesa" element={<Desa />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
