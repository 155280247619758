import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SideNavBar from "../components/SideNavBar";

const Layout = ({ children }) => {
  const [sideNavExpanded, setSideNavExpanded] = useState(false);

  function handleResize() {
    // iPhone X width, for example
    if (window.innerWidth <= 375) {
      setSideNavExpanded(false);
      // write other logic here such as disabling hamburger button
    }
  }


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // on-component-mount, check already to see if user has a small device
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // initialize event listeners on-mount & clean on-unmount

  const contentStyle = {
    marginLeft: sideNavExpanded ? "250px" : "64px", // arbitrary values
    transition: "margin 0.2s ease",
    marginTop: '40px'
  };


  return (
    <React.Fragment>
      <div>
      <Navbar />
        <SideNavBar
          setSideNavExpanded={setSideNavExpanded}
          sideNavExpanded={sideNavExpanded}
        />
        <div style={contentStyle}>{children}</div>
        <Footer /> 
      </div>


    </React.Fragment>
  );
};

export default Layout;
