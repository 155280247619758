import React from "react";

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p style={{ fontSize: "14px" }}>
        Copyright &copy; 2023 All Rights Reserved.
      </p>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  textAlign: "center",
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
};

export default Footer;