import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import * as Io5Icons from 'react-icons/io5';
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText
} from "@trendmicro/react-sidenav";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import Swal from 'sweetalert2';
import { insertLogUser } from '../app/GlobalVar';
import axios from "axios";
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import validator from 'validator';

const SideNavBar = ({ sideNavExpanded, setSideNavExpanded }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const [menu, setMenu] = useState([]);


  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAbout, setIsModalOpenAbout] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState('')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()
  const [msg, setMsg] = useState("");

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModalAbout = () => {
    setIsModalOpenAbout(true);
  };

  const handleCloseModalAbout = () => {
    setIsModalOpenAbout(false);
  };

  const resetForm = () => {
    setOldPassword("")
    setNewPassword("")
    setConfPassword("")
  }

  
  const logout = () => {
    Swal.fire({
      title: 'Logout',
      text: "Anda yakin akan log out?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Log Out'
  }).then(async(result) => {
      if (result.isConfirmed) {
          dispatch(LogOut());
          dispatch(reset());
          navigate('/')
          localStorage.clear()
          sessionStorage.clear()
          insertLogUser(user && user.kode_user_ibs, 'Logout', 'users')
      }
  })
  };

//   const password_validate = (password) => {
//     var re = {
//         capital: /(?=.*[A-Z])/,
//         length: /(?=.{6,40}$)/,
//         specialChar: /[ -\/:-@\[-\`{-~]/,
//         digit: /(?=.*[0-9])/,
//     };
//     return (
//         re.capital.test(password) &&
//         re.length.test(password) &&
//         re.specialChar.test(password) &&
//         re.digit.test(password)
//     );
//  };

  const savePassword = async (e) => {
      dispatch(getMe());
      e.preventDefault();

      if (oldpassword == '' ){
        Swal.fire(
          'Gagal!',
          'Password lama wajib di isi',
          'error'
        )
      }else if(newpassword == ''){
        Swal.fire(
          'Gagal!',
          'Password baru wajib di isi',
          'error'
        )
      
      }else if(confpassword == ''){
        Swal.fire(
          'Gagal!',
          'Konfirmasi password wajib di isi',
          'error'
        )
      }else if (!validator.isStrongPassword(newpassword, { minLength: 6, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
        Swal.fire(
          'Gagal!',
          'Panjang minimum password 6 karakter, harus mengandung huruf besar, huruf kecil, angka, dan simbol!',
          'error'
      )
      }else if(newpassword != confpassword){
        Swal.fire(
          'Gagal!',
          'Password baru tidak sama dengan konfirmasi password',
          'error'
        )
      }else{
      try {
        await axios.patch(`${env.API_URL}/changepassword/${user && user.uuid}`
        , {
          oldpassword: oldpassword,
          newpassword: newpassword,
        }
        ).then((response) => {
          Swal.fire(
              'Berhasil!',
              response.data.msg,
              'success'
          )
      })
      handleCloseModal()
      } catch (error) {
        if (error.response) {
          Swal.fire(
            'Gagal!',
            error.response.data.msg,
            'error'
          )
        }
      }
    }
    
  };

  const HorizontalLine = () => {
    return (
      <div
        style={{
          borderTop: '1px solid #cfcfcf',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      ></div>
    );
  };


  
  useEffect(() => {
    getMenu(user && user.menu);
  }, [user && user.menu]);


  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
  }, (error) => Promise.reject(error))


  const getMenu = async (id) => {
    dispatch(getMe());
    try {
      const response = await axiosJWT.get(`${env.API_URL}/menu/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setMenu(response.data);      
  console.log(response.data)
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const navItems = menu.map((menuItem) => {
    const IconComponent = Io5Icons[menuItem.iconmenu];
    const dtsubmenu = menuItem.datasubmenu;
    const jmlsub = dtsubmenu.length;
  
    if (jmlsub > 1) {
      return (
        <NavItem eventKey={menuItem.namamenu} key={menuItem.namamenu}>
          <NavIcon style={{ paddingTop: '5px' }}>
            <IconComponent size={20} />
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>{menuItem.namamenu}</NavText>
  
          {dtsubmenu.map((submenu) => {
          
          const IconComponentSub = Io5Icons[submenu.iconsubmenu];
          
          return (
            <NavItem style={{ paddingLeft: '10px' }}
              eventKey={submenu.keyactivesubmenu}
              active={location.pathname === `/${submenu.keyactivesubmenu}`}
              onClick={() => {
                navigate(`/${submenu.keyactivesubmenu}`);
              }}
              key={submenu.keyactivesubmenu}
            >
              <NavText style={{ fontSize: '15px' }}><IconComponentSub size={14}/> {submenu.namasubmenu}</NavText>
            </NavItem>
          );
            
          })}
          
        </NavItem>
      );
    } else if (jmlsub === 0) {
      return (
        <NavItem 
          eventKey={menuItem.keyactivemenu}
          key={menuItem.keyactivemenu}
          active={location.pathname === '/' + menuItem.keyactivemenu}
          onClick={() => navigate('/' + menuItem.keyactivemenu)}
          >
          <NavIcon style={{ paddingTop: '5px' }}>
            <IconComponent size={20} />
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>{menuItem.namamenu}</NavText>
        </NavItem>
      );
  
    } else {
      return null;
    }
  });

  
    return (
      <div>
      <SideNav 
      onToggle={() => {
        setSideNavExpanded(!sideNavExpanded);
      }}
      expanded={sideNavExpanded}
      >
      <SideNav.Toggle  />
      <SideNav.Nav>

      {navItems}
        <NavItem eventKey="about">
          <NavIcon style={{paddingTop: '5px'}}>
            <Io5Icons.IoInformationCircleSharp  size={20}/>
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>About</NavText>
          <NavItem onClick={() => {handleOpenModalAbout()}}>
            <NavText>About This App</NavText>
          </NavItem>
          <NavItem onClick={() => {handleOpenModal();resetForm()}}>
            <NavText>Change Password</NavText>
          </NavItem>
          <NavItem onClick={logout}>
            <NavText>Logout</NavText>
          </NavItem>
        </NavItem>
      </SideNav.Nav>
    </SideNav>


     {/* Modal change password */}
     <div className={`modal modal-fx-3dSlit ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Change Password</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
        {/* <form> */}

              <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password Lama</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={oldpassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            placeholder="Password Lama"
                          />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password Baru</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={newpassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Password Baru"
                          />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Conf Password</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={confpassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                            placeholder="Konfirmasi Password"
                          />
                        </p>
                      </div>
                      </div>
                  </div>

            {/* </form> */}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {savePassword(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>


 {/* Modal about */}
 <div className={`modal modal-fx-newsPaper ${isModalOpenAbout ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">

        <header className="modal-card-head" style={{background:'#474747'}}>
              <p className="modal-card-title" style={{fontSize: '20px', color:'#b8b8b8'}}><b>About This App</b></p>
              <button
                onClick={handleCloseModalAbout}
                className="delete"
                aria-label="close"
              />
            </header>

        <section className="modal-card-body" style={{background:'#b8b8b8'}}>
        {/* <form> */}
        <p><b>Transaksi Non Tunai Desa [TNTD]</b> version : 1.0.1<br />
        <HorizontalLine />
         <center>Designed & Developed by : <br />
        <img src='/images/timitbankboy.png' width="150" alt="logo" /> <br />
        &copy; 2023 Tim IT Bank Boyolali - All Rights Reserved.
        </center> </p> 

            {/* </form> */}
            </section>

            <footer className="modal-card-foot" style={{background:'#474747'}}>
              <button onClick={handleCloseModalAbout} className="button is-small is-dark">
                Close
              </button>
            </footer>

        </div>
      </div>



    </div>
    );
};

export default SideNavBar;
