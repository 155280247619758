import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoTrash, IoSearch, IoDocumentAttach, IoDocumentAttachOutline, IoCheckmark, IoPrint, IoHourglassOutline, IoCreate, IoEye} from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import { NumericFormat } from 'react-number-format';
import { useDropzone } from 'react-dropzone';
import './FileUpload.css';
import LoadingFile from './LoadingFile';
import Loading from './Loading';
import { getLocalIP, formatTanggal} from '../app/GlobalVar';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Iframe from 'react-iframe';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Multiselect from 'multiselect-react-dropdown';
import * as XLSX from 'xlsx';


const PencairanDesaList = () => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()
  const [msg, setMsg] = useState("");
  const [tglmulai, setTglMulai] = useState('');
  const [tglselesai, setTglSelesai] = useState('');
  const [tglmulailap, setTglMulaiLap] = useState('');
  const [tglselesailap, setTglSelesaiLap] = useState('');
  const [tglsekarang, setTglSekarang] = useState('');;

  const [createdat, setCreatedAt] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesdb, setSelectedFilesDb] = useState([]);
  const [selectedFilesdbdel, setSelectedFilesDbDel] = useState([]);
  const [mergedFiles, setMergedFiles] = useState([]);


  const [uploading, setUploading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [role, setRole] = useState('');
  const [action, setAction] = useState('');

  const [jam, setJam] = useState('');
  const [lokalip, setLokalIP] = useState('');
  
  const [tanggalformatindo, setTanggalFormatIndo] = useState('');

  const [urlFile, setUrlFile] = useState("");
  const [nameFile, setNameFile] = useState("");
  const [extFile, setExtFile] = useState("");

  const sliderRef = useRef(null);
  const fileInputRefInt = useRef(null);
  const fileInputRefTransfer = useRef(null);
  const fileInputRefPajak = useRef(null);
  const fileInputRefBpjs = useRef(null);

  const [kodebankall, setKodeBankAll] = useState([]);

  const [isModalOpenFile, setIsModalOpenFile] = useState(false);
  const [isModalOpenFileRespon, setIsModalOpenFileRespon] = useState(false);
  const [isModalOpenPrintLaporan, setIsModalOpenPrintLaporan] = useState(false);
  const [isModalOpenPrintRincianTrans, setIsModalOpenPrintRincianTrans] = useState(false);
  const [ismodalopendesa, setIsModalOpenDesa] = useState(false);
  const [isModalOpenCetakFile, setisModalOpenCetakFile] = useState(false);
  const [isModalOpenCetakLaporan, setisModalOpenCetakLaporan] = useState(false);
  const [isModalOpenCetakRincianTrans, setisModalOpenCetakRincianTrans] = useState(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);
  const [isModalDataInternalOpen, setIsModalDataInternalOpen] = useState(false);
  const [isModalDataTransferOpen, setIsModalDataTransferOpen] = useState(false);
  const [isModalDataPajakOpen, setIsModalDataPajakOpen] = useState(false);
  const [isModalDataBpjsOpen, setIsModalDataBpjsOpen] = useState(false);

  const [pencairandesa, setPencairanDesa] = useState([]);
  const [filteredpencairandesa, setFilteredPencairanDesa] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState('');


  const [tglentry, setTglEntry] = useState("");
  const [kodedesa, setKodeDesa] = useState("");
  const [jenistransselect, setJenisTransSelect] = useState("");
  const [kodekecamatanselect, setKodeKecamatanSelect] = useState("");
  const [kodekecamatanall, setKodeKecamatanAll] = useState([]);
  const [kodedesaselect, setKodeDesaSelect] = useState([]);
  const [kodedesa2, setKodeDesa2] = useState([]);
  const [kodedesaall, setKodeDesaAll] = useState([]);
  const [namadesa, setNamaDesa] = useState("");
  const [norekdesa, setNorekDesa] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [idpencairan, setIdPencairan] = useState("");
  const [nospp, setNoSpp] = useState("");

  const [datarekeninginternal, setDataRekeningInternal] = useState([]);
  const [datarekeningtransfer, setDataRekeningTransfer] = useState([]);
  const [datapajak, setDataPajak] = useState([]);
  const [databpjs, setDataBpjs] = useState([]);
  const [verifypencairandesa, setVerifyPencairanDesa] = useState("");
  
  const [disabledbtnsavepencairandesa, setDisabledBtnSavePencairanDesa] = useState(false);
  
  const dateInputRefRekapLapMulai = useRef(null);
  const dateInputRefRekapLapSelesai = useRef(null);
  const dateInputRefTransLapMulai = useRef(null);
  const dateInputRefTransLapSelesai = useRef(null);

  const datePeriodeMulai = useRef(null);
  const datePeriodeSelesai = useRef(null);
  const dateFormat = 'd/m/Y';

  const [dataarrayinternal, setDataArrayInternal] = useState({
    'idurut': '',
    'statustrans': '',
    'no_rekening': '',
    'nama': '',
    'nominal': '',
    'keterangan': '',
  });


  const [dataarraytransfer, setDataArrayTransfer] = useState({
    'statustrans': '',
    'no_rekening': '',
    'nama': '',
    'jumlahsetoran': '',
    'keterangan': '',
    'kodebank': '',
    'namabank': '',
  });

  const [dataarraypajak, setDataArrayPajak] = useState({
    'statustrans': '',
    'idbilling': '',
    'jenispajak': '',
    'masapajak': '',
    'jumlahsetoran': '',
    'keterangan': '',
  });

  const [dataarraybpjs, setDataArrayBpjs] = useState({
    'statustrans': '',
    'nova': '',
    'jumlahsetoran': '',
    'keterangan': '',
  });

  const [indexdatainternal, setIndexDataInternal] = useState('');
  const [indexdatatransfer, setIndexDataTransfer] = useState('');
  const [indexdatapajak, setIndexDataPajak] = useState('');
  const [indexdatabpjs, setIndexDataBpjs] = useState('');
  const [expand, setExpand] = useState("");
  const [urlbyid, setUrlById] = useState('');
  const [urlbyidrinciantrans, setUrlByIdRincianTrans] = useState('');
 


  
  useEffect(() => {
  
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(selectedImageIndex);
    }

  }, [selectedImageIndex]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: selectedImageIndex,
    afterChange: (currentSlide) => {
      setSelectedImageIndex(currentSlide);
    },

    };


  const handleOpenModalCetakSlip = (pencairanid) => {
        setisModalOpenCetakFile(true);
        setUrlById('/printslip/'+pencairanid);
      };
    
  const handleCloseModalCetakSlip = () => {
        setisModalOpenCetakFile(false);
        setUrlById('');
      };

  const handleOpenModalFileRespon = (url, name) => {
        setIsModalOpenFileRespon(true);
        setUrlFile(url);
        setNameFile(name);
      };
    
  const handleCloseModalFileRespon = () => {
        setIsModalOpenFileRespon(false);
        setUrlFile("");
        setNameFile("");
      };

  const handleOpenModalFile = (url, name, ext) => {
        setIsModalOpenFile(true);
        setUrlFile(url);
        setNameFile(name);
        setExtFile(ext);
      };
    
  const handleCloseModalFile = () => {
        setIsModalOpenFile(false);
        setUrlFile("");
        setNameFile("");
        setExtFile("");
      };

  const handleOpenModalPrintLaporan = () => {
        setIsModalOpenPrintLaporan(true);
        getKodeDesa();
        getKodeKecamatan();

        flatpickr(dateInputRefRekapLapMulai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d', 
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglMulaiLap(dateStr)     
          },
        });
    
        flatpickr(dateInputRefRekapLapSelesai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d', 
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglSelesaiLap(dateStr)
          },
        });

      };
    
 
  const handleCloseModalPrintLaporan = () => {
        setIsModalOpenPrintLaporan(false);
        setTglMulaiLap("")
        setTglSelesaiLap("")
        setKodeDesa2([])
        setKodeDesaSelect([])
        setKodeKecamatanSelect("")
        dateInputRefRekapLapMulai.current._flatpickr.clear();
        dateInputRefRekapLapSelesai.current._flatpickr.clear();
      };

  const handleOpenModalPrintRincianTrans = () => {
        setIsModalOpenPrintRincianTrans(true);
        getKodeDesa();
        getKodeKecamatan();

        flatpickr(dateInputRefTransLapMulai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d', 
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglMulaiLap(dateStr)     
          },
        });
    
        flatpickr(dateInputRefTransLapSelesai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d', 
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglSelesaiLap(dateStr)
          },
        });

      };
    
 
  const handleCloseModalPrintRincianTrans = () => {
        setIsModalOpenPrintRincianTrans(false);
        setTglMulaiLap("")
        setTglSelesaiLap("")
        setKodeDesa2([])
        setKodeDesaSelect([])
        setKodeKecamatanSelect("")
        dateInputRefTransLapMulai.current._flatpickr.clear();
        dateInputRefTransLapSelesai.current._flatpickr.clear();
      };

  const handleOpenModalCetakLaporan = () => {
      if(role == ''){
        Swal.fire(  
          'Gagal!',
          'Role user masih kosong!',
          'error'
        )
      }else if(tglmulailap == ''){
        Swal.fire(  
          'Gagal!',
          'Periode tanggal mulai belum di isi!',
          'error'
        )
      }else if(tglselesailap == ''){
        Swal.fire(  
          'Gagal!',
          'Periode tanggal selesai belum di isi!',
          'error'
        )
      }else if(tglmulailap > tglselesailap){
        Swal.fire(  
          'Gagal!',
          'Periode tanggal mulai tidak boleh lebih besar dari tanggal selesai!',
          'error'
        )
      }else if(role == 'des'  && kodedesa == ''){
        Swal.fire(  
          'Gagal!',
          'Kode desa masih kosong!',
          'error'
        )
      }else if(role != 'des'  && kodedesa2 == ''){
        Swal.fire(  
          'Gagal!',
          'Kode desa belum di pilih!',
          'error'
        )
      }else{

        setisModalOpenCetakLaporan(true);
       if(role == 'des'){
        setUrlById('/printlaporandesa/'+role+'/'+kodedesa+'/'+tglmulailap+'/'+tglselesailap);
       }else if(role != 'des'){
        setUrlById(`/printlaporandesa/${role}/${kodedesa2}/${tglmulailap}/${tglselesailap}`);
       }
      }

      };
    
  const handleCloseModalCetakLaporan = () => {
        setisModalOpenCetakLaporan(false);    
      };

  const handleOpenModalCetakRincianTrans = () => {
        if(role == ''){
          Swal.fire(  
            'Gagal!',
            'Role user masih kosong!',
            'error'
          )
        }else if(tglmulailap == ''){
          Swal.fire(  
            'Gagal!',
            'Periode tanggal mulai belum di isi!',
            'error'
          )
        }else if(tglselesailap == ''){
          Swal.fire(  
            'Gagal!',
            'Periode tanggal selesai belum di isi!',
            'error'
          )
        }else if(tglmulailap > tglselesailap){
          Swal.fire(  
            'Gagal!',
            'Periode tanggal mulai tidak boleh lebih besar dari tanggal selesai!',
            'error'
          )
        }else if(kodedesa == ''){
          Swal.fire(  
            'Gagal!',
            'Kode desa masih kosong!',
            'error'
          )
        }else if(jenistransselect == ''){
          Swal.fire(  
            'Gagal!',
            'Jenis Transaksi belum di pilih!',
            'error'
          )
        }else{
  
          setisModalOpenCetakRincianTrans(true);
         if(role == 'des'){
          setUrlByIdRincianTrans('/printrinciantrans/'+role+'/'+kodedesa+'/'+tglmulailap+'/'+tglselesailap+'/'+jenistransselect);
         }else if(role != 'des'){
          setUrlByIdRincianTrans(`/printrinciantrans/${role}/${kodedesaselect}/${tglmulailap}/${tglselesailap}/${jenistransselect}`);
         }
        }
  
        };

    const handleCloseModalCetakRincianTrans = () => {
          setisModalOpenCetakRincianTrans(false);    
        };


  const handleExpand = () => {
    setExpand('modal-full-screen');
  };

  const handleReduce = () => {
    setExpand('');
  };
  

  const handleFileInputInt = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseExcelInternal(file);
    }
  };

  const handleFileInputTransfer = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseExcelTransfer(file);
    }
  };

  const handleFileInputPajak = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseExcelPajak(file);
    }
  };

  const handleFileInputBpjs = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseExcelBpjs(file);
    }
  };


  const options = kodedesaall.map(desa => ({
    label: `${desa.KODE_DESA} - ${desa.DESA}`,
    value: desa.KODE_DESA,
  }));

  const handleDesaSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setKodeDesaSelect(selectedOptions);
    setKodeDesa2(selectedValues);
  }


  const parseExcelInternal = async (file) => {
    setUploading(true);
    const reader = new FileReader();
    reader.onload = async  (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 1 }); // Mulai dari baris kedua

      const formattedData = [];
      let i = 0;
      for (const row of excelData) {
        i++
        const noRekening = row[0];
        const nama = row[1];
        const nominal = new Intl.NumberFormat("id-ID").format(row[2]);
        const keterangan = row[3];


        formattedData.push({
          'idurut': i,
          'statustrans': '0',
          'no_rekening': noRekening,
          'nama': nama,
          'nominal': nominal,
          'keterangan': keterangan,
        });
      }


       setDataRekeningInternal(Array.isArray(formattedData) ? formattedData : []);
       setUploading(false);
    };

    reader.readAsBinaryString(file);
   
  };



  const parseExcelTransfer = async (file) => {
    setUploading(true);
    const reader = new FileReader();
    reader.onload = async  (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 1 }); // Mulai dari baris kedua

      const formattedData = [];

      for (const row of excelData) {
        const noRekening = row[0];
        const nama = row[1];
        const jumlahsetoran = new Intl.NumberFormat("id-ID").format(row[2]);
        const keterangan = row[3];
        const kodeBank = row[4];
        const namaBank = row[5];


        formattedData.push({
          'statustrans': '0',
          'no_rekening': noRekening,
          'nama': nama,
          'jumlahsetoran': jumlahsetoran,
          'keterangan': keterangan,
          'kodebank': kodeBank,
          'namabank': namaBank,
        });
      }
  
      setDataRekeningTransfer(Array.isArray(formattedData) ? formattedData : []);

       setUploading(false);
    };

    reader.readAsBinaryString(file);
   
  };



  const getIdPencairan = async () => {
    try {
        const response = await axiosJWT.get(`${env.API_URL}/idpencairan/${kodedesa}/${tglsekarang}`,{
          headers: {
            Authorization: `Bearer ${token}`
        }
        });

        // console.log(response.data)
        setIdPencairan(response.data);
       
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }

  };


  const parseExcelPajak = async (file) => {
    setUploading(true);
    const reader = new FileReader();
    reader.onload = async  (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 1 }); // Mulai dari baris kedua

      const formattedData = [];

      for (const row of excelData) {
        const idbilling = row[0];
        const jenispajak = row[1];
        const masapajak = row[2];
        const jumlahsetoran = new Intl.NumberFormat("id-ID").format(row[3]);
        const keterangan = row[4];


        formattedData.push({
          'statustrans': '0',
          'idbilling': idbilling,
          'jenispajak': jenispajak,
          'masapajak': masapajak,
          'jumlahsetoran': jumlahsetoran,
          'keterangan': keterangan,
        });
      }
  
        setDataPajak(Array.isArray(formattedData) ? formattedData : []);

       setUploading(false);
    };

    reader.readAsBinaryString(file);
   
  };


  const parseExcelBpjs = async (file) => {
    setUploading(true);
    const reader = new FileReader();
    reader.onload = async  (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 1 }); // Mulai dari baris kedua

      const formattedData = [];

      for (const row of excelData) {
        const nova = row[0];
        const jumlahsetoran = new Intl.NumberFormat("id-ID").format(row[1]);
        const keterangan = row[2];


        formattedData.push({
          'statustrans': '0',
          'nova': nova,
          'jumlahsetoran': jumlahsetoran,
          'keterangan': keterangan,
        });
      }
  
      setDataBpjs(Array.isArray(formattedData) ? formattedData : []);
    
       setUploading(false);
    };

    reader.readAsBinaryString(file);
   
  };
 

  const getVerifyPencairanDesa = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/configvalue/verify_pencairan_desa`,{
      headers: {
          Authorization: `Bearer ${token}`
      }
    });
    setVerifyPencairanDesa(response.data.keyvalue)
  };
  


  const handleInputChangeInternal = (e) => {
    const { name, value } = e.target;
    setDataArrayInternal({
      ...dataarrayinternal,
      [name]: value,
    });

  };

  const handleInputChangeTransfer = (e) => {
    const { name, value } = e.target;
    setDataArrayTransfer({
      ...dataarraytransfer,
      [name]: value,
    });

  };

  const handleInputChangePajak = (e) => {
    const { name, value } = e.target;
    setDataArrayPajak({
      ...dataarraypajak,
      [name]: value,
    });

  };

  const handleInputChangeBpjs = (e) => {
    const { name, value } = e.target;
    setDataArrayBpjs({
      ...dataarraybpjs,
      [name]: value,
    });

  };



const addDataTransSatuanInternal = async (e) => {

if(dataarrayinternal['no_rekening'] == ''){
  Swal.fire(  
    'Gagal!',
    'Nomor rekening wajib di isi!',
    'error'
  )
}else if(dataarrayinternal['nama'] == ''){
  Swal.fire(  
    'Gagal!',
    'Nama nasabah wajib di isi!',
    'error'
  )
}else if(dataarrayinternal['nominal'] == ''){
  Swal.fire(  
    'Gagal!',
    'Nominal transaksi wajib di isi!',
    'error'
  )
}else{
  dataarrayinternal['statustrans'] = '0';
  if (indexdatainternal !== '') {
    const newDataArray = [...datarekeninginternal];
    newDataArray[indexdatainternal] = dataarrayinternal;
    setDataRekeningInternal(newDataArray);
  } else {
    setDataRekeningInternal([...datarekeninginternal, dataarrayinternal]);
  }

  handleCloseModalDataInternal();
  
  setDataArrayInternal({
    'idurut':'',
    'statustrans':'',
    'no_rekening': '',
    'nama': '',
    'nominal': '',
    'keterangan': '',
  });
}

 };

 const addDataTransSatuanTransfer= async (e) => {

  if(dataarraytransfer['no_rekening'] == ''){
    Swal.fire(  
      'Gagal!',
      'Nomor rekening wajib di isi!',
      'error'
    )
  }else if(dataarraytransfer['nama'] == ''){
    Swal.fire(  
      'Gagal!',
      'Nama nasabah wajib di isi!',
      'error'
    )
  }else if(dataarraytransfer['jumlahsetoran'] == ''){
    Swal.fire(  
      'Gagal!',
      'Jumlah setoran transaksi wajib di isi!',
      'error'
    )
  }else if(dataarraytransfer['namabank'] == ''){
    Swal.fire(  
      'Gagal!',
      'Bank tujuan wajib di isi!',
      'error'
    )
  }else{
    dataarraytransfer['statustrans'] = '0';
    if (indexdatatransfer !== '') {
      const newDataArray = [...datarekeningtransfer];
      newDataArray[indexdatatransfer] = dataarraytransfer;
      setDataRekeningTransfer(newDataArray);
    } else {
      setDataRekeningTransfer([...datarekeningtransfer, dataarraytransfer]);
    }
  
    handleCloseModalDataTransfer();
    
    setDataArrayTransfer({
      'statustrans':'',
      'no_rekening': '',
      'nama': '',
      'jumlahsetoran': '',
      'keterangan': '',
      'kodebank': '',
      'namabank': '',
    });
  }
  
   };


   const addDataTransSatuanPajak= async (e) => {

    if(dataarraypajak['idbilling'] == ''){
      Swal.fire(  
        'Gagal!',
        'ID Billing wajib di isi!',
        'error'
      )
    }else if(dataarraypajak['jumlahsetoran'] == ''){
      Swal.fire(  
        'Gagal!',
        'Jumlah setoran transaksi wajib di isi!',
        'error'
      )
    }else{
      dataarraypajak['statustrans'] = '0';
      if (indexdatapajak !== '') {
        const newDataArray = [...datapajak];
        newDataArray[indexdatapajak] = dataarraypajak;
        setDataPajak(newDataArray);
      } else {
        setDataPajak([...datapajak, dataarraypajak]);
      }
    
      handleCloseModalDataPajak();
      
      setDataArrayPajak({
        'statustrans':'',
        'idbilling': '',
        'jenispajak': '',
        'masapajak': '',
        'jumlahsetoran': '',
        'keterangan': '',
      });
    }
    
     };


     const addDataTransSatuanBpjs= async (e) => {

      if(dataarraybpjs['nova'] == ''){
        Swal.fire(  
          'Gagal!',
          'Nomor Virtual Account wajib di isi!',
          'error'
        )
      }else if(dataarraybpjs['jumlahsetoran'] == ''){
        Swal.fire(  
          'Gagal!',
          'Jumlah setoran transaksi wajib di isi!',
          'error'
        )
      }else{
        dataarraybpjs['statustrans'] = '0';
        if (indexdatabpjs !== '') {
          const newDataArray = [...databpjs];
          newDataArray[indexdatabpjs] = dataarraybpjs;
          setDataBpjs(newDataArray);
        } else {
          setDataBpjs([...databpjs, dataarraybpjs]);
        }
      
        handleCloseModalDataBpjs();
        
        setDataArrayBpjs({
          'statustrans':'',
          'nova': '',
          'jumlahsetoran': '',
          'keterangan': '',
        });
      }
      
       };
  


      const onDrop = (acceptedFiles) => {
        setUploading(true);
      
        const oversizedFiles = [];
        const validExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];
        const newFilesWithPreviews = acceptedFiles
          .filter((file) => {
            const size = file.size; // Ukuran file dalam byte
            const ext = file.name.split('.').pop().toLowerCase(); // Ekstensi file dalam huruf kecil
      
            if (size > 4 * 1024 * 1024) {
              oversizedFiles.push(file);
              return false;
            }
      
            if (!validExtensions.includes(`.${ext}`)) {
              Swal.fire({
                icon: 'error',
                title: 'Ekstensi file tidak valid',
                text: `File dengan ekstensi ${ext} tidak diperbolehkan.`,
              });
              return false;
            }
      
            return true;
          })
          .map((file) => {
            const objectURL = URL.createObjectURL(file);
            const type = (file.type);
            const ext = type.split('/');
            return { file, src: objectURL, ext: '.'+ext[1] };
          });
      
        if (oversizedFiles.length > 0) {
          const oversizedFileNames = oversizedFiles.map((file) => file.name).join(', ');
      
          Swal.fire({
            icon: 'error',
            title: 'Ukuran file terlalu besar',
            html: `File-file berikut melebihi 4 MB: <br>${oversizedFileNames}`,
          });
        }
      
        new Promise((resolve) => {
          setTimeout(resolve, 1500);
        }).then(() => {
          const updatedFiles = [...selectedFiles, ...newFilesWithPreviews];
          setSelectedFiles(updatedFiles);
          setUploading(false);
        });
    
      };
    


  const removeFile = (index) => {
    const fileToRemove = mergedFiles[index];

    if (fileToRemove.file.type === 'server') {
      const updatedFilesDb = selectedFilesdb.filter(
        (file) => file.file.name !== fileToRemove.file.name
      );
      setSelectedFilesDb(updatedFilesDb);
      
      setSelectedFilesDbDel(prevDeletedFiles => [...prevDeletedFiles, fileToRemove]);
    } else {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index - selectedFilesdb.length, 1);
      setSelectedFiles(updatedFiles);
      
    }
  };



  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    const newMergedFiles = [...selectedFilesdb, ...selectedFiles];
    setMergedFiles(newMergedFiles);

  }, [selectedFilesdb, selectedFiles]);


const isVerified = async (pencairanid, verified, action, status, islock) => {

    if(role == 'admin'){
      Swal.fire({
          title: 'Konfirmasi!',
          text: " Anda yakin akan "+action+" data ini?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, '+action+'!'
      }).then(async (result) => {
        if (result.isConfirmed) {
      
          await axiosJWT.patch(`${env.API_URL}/verifypencairandesa/${pencairanid}`,{
            verified: verified
          },{
            headers: {
              Authorization: `Bearer ${token}`
          }
          })
      
          getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
      
          }
          
        })

    }else if(role == verifypencairandesa  && role != 'admin'){
      if ((status == '0' || status == null) ){
        if(islock == 'unlock'){
        Swal.fire({
          title: 'Konfirmasi!',
          html: `Anda yakin akan melakukan verifikasi data ini?<br>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Verifikasi!'
      }).then(async (result) => {
        if (result.isConfirmed) {
      
          await axiosJWT.patch(`${env.API_URL}/verifypencairandesa/${pencairanid}`,{
            verified: verified
          },{
            headers: {
              Authorization: `Bearer ${token}`
          }
          })
      
          getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
      
          }
          
        })

      }else if(islock == 'lock'){
        Swal.fire(
          'Gagal!',
          'Data sudah diproses oleh teller, anda tidak bisa membatalkan verifikasi data!',
          'error'
        );
      }

      }else if(status == '1') {
          Swal.fire(
          'Gagal!',
          'Status trans sudah selesai, anda tidak bisa membatalkan verifikasi data!',
          'error'
        );
      }
      }else{
        Swal.fire(
          'Gagal!',
          'Anda tidak berhak melakukan '+action+' data ini!',
          'error'
        );
      }


    // if((verifypencairandesa == role && (status == '0' && isverified == 'unverified')) || role == 'admin'){
    //   Swal.fire({
    //     title: 'Konfirmasi!',
    //     text: " Anda yakin akan "+action+" data ini?",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Ya, '+action+'!'
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    
    //     await axiosJWT.patch(`${env.API_URL}/verifypencairandesa/${pencairanid}`,{
    //       verified: verified
    //     },{
    //       headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    //     })
    
    //     getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
    
    //     }
        
    //   })
    
    // }else if((verifypencairandesa == role && ((status == '0' && isverified == 'verified') || status == '1')) && role != 'admin'){
    //   Swal.fire(
    //     'Error!',
    //     'Status trans sudah selesai, anda tidak bisa membatalkan verifikasi data!',
    //     'error'
    //   );

    //   }else{
    //     Swal.fire(
    //       'Gagal!',
    //       'Anda tidak berhak melakukan '+action+' data ini!',
    //       'error'
    //     );
    //   }
    
      // getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
       
      }


  const handleOpenModalDesa = (key, isverified) => {
    setAction(key);
    if(isverified == 'verified' && role != 'admin'){
      Swal.fire(  
        'Gagal!',
        'Data sudah di verifikasi!',
        'error'
    )
      setIsModalOpenDesa(false)
    }else{
      setIsModalOpenDesa(true)
    }

    if(key == 'view'){
      setDisabledBtnSavePencairanDesa(true)
    }else{
      setDisabledBtnSavePencairanDesa(false)
    }

    if(key == 'add'){
      getIdPencairan();
    }
  };

  const handleCloseModalDesa = () => {
    setIsModalOpenDesa(false);
    resetForm();
  };

  
  const handleOpenModalImage = (pencairanid, index) => {
    getPencairanDesaById(pencairanid);
    setIsModalImageOpen(true);
    setSelectedImageIndex(index);
  };

  const handleCloseModalImage = () => {
    setIsModalImageOpen(false);
    setSelectedImageIndex(null);
  };
 

  const handleOpenModalDataInternal = () => {
    setIsModalDataInternalOpen(true);    

    const valuesArray = Object.values(datarekeninginternal);
    const objWithLargestIndex = valuesArray.reduce((prev, current) => {
      return (current.idurut > prev.idurut) ? current : prev;
    });

    setDataArrayInternal({ ...dataarrayinternal, 'idurut':parseInt(objWithLargestIndex.idurut+1)});  
  };

  const handleOpenModalDataInternaledit = (row, rowIndex) => {
    setIsModalDataInternalOpen(true);
    setDataArrayInternal(row);
    setIndexDataInternal(rowIndex);
  };

  const handleCloseModalDataInternal = () => {
    setIsModalDataInternalOpen(false);
    setIndexDataInternal('');

    setDataArrayInternal({
      'idurut': '',
      'no_rekening': '',
      'nama': '',
      'nominal': '',
      'keterangan': '',
    });
  };


  const handleOpenModalDataTransfer = (index) => {
    setIsModalDataTransferOpen(true);
    getKodeBank();
    
  };

  const handleOpenModalDataTransferedit = (row, rowIndex) => {
    setIsModalDataTransferOpen(true);
    setDataArrayTransfer(row);
    setIndexDataTransfer(rowIndex);
    getKodeBank();
  };

  const handleCloseModalDataTransfer = () => {
    setIsModalDataTransferOpen(false);
    setIndexDataTransfer('');

    setDataArrayTransfer({
      'no_rekening': '',
      'nama': '',
      'jumlahsetoran': '',
      'keterangan': '',
      'kodebank': '',
      'namabank': '',
    });
  };


  const handleOpenModalDataPajak = (index) => {
    setIsModalDataPajakOpen(true);
    
  };

  const handleOpenModalDataPajakedit = (row, rowIndex) => {
    setIsModalDataPajakOpen(true);
    setDataArrayPajak(row);
    setIndexDataPajak(rowIndex);
  };

  const handleCloseModalDataPajak = () => {
    setIsModalDataPajakOpen(false);
    setIndexDataPajak('');

    setDataArrayPajak({
      'idbilling': '',
      'jenispajak': '',
      'masapajak': '',
      'jumlahsetoran': '',
      'keterangan': '',
    });
  };


  const handleOpenModalDataBpjs = (index) => {
    setIsModalDataBpjsOpen(true);
    
  };

  const handleOpenModalDataBpjsedit = (row, rowIndex) => {
    setIsModalDataBpjsOpen(true);
    setDataArrayBpjs(row);
    setIndexDataBpjs(rowIndex);
  };

  const handleCloseModalDataBpjs = () => {
    setIsModalDataBpjsOpen(false);
    setIndexDataBpjs('');

    setDataArrayBpjs({
      'nova': '',
      'jumlahsetoran': '',
      'keterangan': '',
    });
  };


const deleteDataInternal = async (rowIndex) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
          const newDataArray = [...datarekeninginternal];
          newDataArray.splice(rowIndex, 1);
          setDataRekeningInternal(newDataArray);
        }
    })
}

const deleteDataTransfer = async (rowIndex) => {
  dispatch(getMe());
  Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
  }).then(async (result) => {
      if (result.isConfirmed) {
        const newDataArray = [...datarekeningtransfer];
        newDataArray.splice(rowIndex, 1);
        setDataRekeningTransfer(newDataArray);
      }
  })
}

const deleteDataPajak = async (rowIndex) => {
  dispatch(getMe());
  Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
  }).then(async (result) => {
      if (result.isConfirmed) {
        const newDataArray = [...datapajak];
        newDataArray.splice(rowIndex, 1);
        setDataPajak(newDataArray);
      }
  })
}

const deleteDataBpjs = async (rowIndex) => {
  dispatch(getMe());
  Swal.fire({
      title: 'Konfirmasi!',
      text: "Anda akan menghapus data ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus!'
  }).then(async (result) => {
      if (result.isConfirmed) {
        const newDataArray = [...databpjs];
        newDataArray.splice(rowIndex, 1);
        setDataBpjs(newDataArray);
      }
  })
}



  const resetForm = () => {
    setIdPencairan('');
    setKeterangan('');
    setActiveTab(0);
    setSelectedFiles([]);
    setSelectedFilesDb([]);
    setSelectedFilesDbDel([]);
    setDataRekeningInternal([]);
    setDataRekeningTransfer([]);
    setDataPajak([]);
    setDataBpjs([]);
    setExpand('');
  }

  const resetDataArrayInternal = () => {
    setDataRekeningInternal([]);
    if (fileInputRefInt.current) {
      fileInputRefInt.current.value = null;
    }
  }

  const resetDataArrayTransfer = () => {
    setDataRekeningTransfer([]);
    if (fileInputRefTransfer.current) {
      fileInputRefTransfer.current.value = null;
    }
  }

  const resetDataArrayPajak = () => {
    setDataPajak([]);
    if (fileInputRefPajak.current) {
      fileInputRefPajak.current.value = null;
    }
  }

  const resetDataArrayBpjs = () => {
    setDataBpjs([]);
    if (fileInputRefBpjs.current) {
      fileInputRefBpjs.current.value = null;
    }
  }

  const settanggal = (e) => {
    var date = new Date(e); 
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)).toLocaleString('en-ID', { hour12:false } );
  }

  const getPencairanDesaById = async (pencairanid, key) => {
    dispatch(getMe());
    try {
      const response = await axiosJWT.get(`${env.API_URL}/pencairan/${pencairanid}`,{
       responseType: 'json',
        headers: {
          Authorization: `Bearer ${token}`
      }
      });

      const filesFromServer = response.data.formattedFiles.map((file) => ({
        file:{name: file.name,
              type: file.type},
        ext: file.ext,
        src: env.API_URL+file.src 
      }));



      const datatransInternal = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '0') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        idurut: dt.urut_excel,
        statustrans: dt.status_trans,
        no_rekening: dt.no_rekening,
        nama: dt.nama, 
        nominal: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan, 
      }
      ));

      const datatransTransfer = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '1') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        kodebank: dt.kode_bank_umum,
        statustrans: dt.status_trans,
        no_rekening: dt.no_rekening,
        nama: dt.nama, 
        jumlahsetoran: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan, 
        namabank: dt.namabank,
        filerespontnt: dt.filerespontnt
      }
      ));

      const datatransPajak = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '2') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        idbilling: dt.id_biling, 
        statustrans: dt.status_trans,
        jenispajak: dt.jenis_pajak, 
        masapajak: dt.masa_pajak, 
        jumlahsetoran: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan,
        filerespontnt: dt.filerespontnt
      }
      ));

      const datatransBpjs = response.data.pencairan_trans
      .filter((dt) => dt.jenistransaksi === '3') // Filter data berdasarkan jenisrekening
      .map((dt) => ({
        nova: dt.nomor_virtual_account,
        statustrans: dt.status_trans,
        jumlahsetoran: new Intl.NumberFormat("id-ID").format(dt.nominal), 
        keterangan: dt.keterangan,
        filerespontnt: dt.filerespontnt 
      }
      ));


      // setDataRekeningInternal(datatransInternal);
      setDataRekeningInternal(Array.isArray(datatransInternal) ? datatransInternal : []);
      setDataRekeningTransfer(Array.isArray(datatransTransfer) ? datatransTransfer : []);
      setDataPajak(Array.isArray(datatransPajak) ? datatransPajak : []);
      setDataBpjs(Array.isArray(datatransBpjs) ? datatransBpjs : []);
      setSelectedFilesDb(filesFromServer);
      setIdPencairan(response.data.response.pencairan_id);
      setNoSpp(response.data.response.no_spp);
      setTglEntry(response.data.response.tgl_entry);
      setKodeDesa(response.data.response.kode_desa);
      setNamaDesa(response.data.response.nama_desa);
      setNorekDesa(response.data.response.norek_desa);
      setKeterangan(response.data.response.keterangan);
    
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  const fetchDataTgl = async () => {
      const tanggal = await formatTanggal();
      setTanggalFormatIndo(tanggal.tanggalformatindo);
      setTglSekarang(tanggal.tgl_trans);   
      setTglMulai(tanggal.tgl_trans); 
      setTglSelesai(tanggal.tgl_trans);    
      setCreatedAt(tanggal.tgl_trans+' '+tanggal.jam_trans);     
      setJam(tanggal.jam_trans); 

      flatpickr(datePeriodeMulai.current, {
        altInput: true,
        altFormat: dateFormat,
        dateFormat: 'Y-m-d',
        defaultDate: tanggal.tgl_trans,
        disableMobile: "true",
        onChange: function(selectedDates, dateStr) {
          setTglMulai(dateStr);
        },
      });
  
      flatpickr(datePeriodeSelesai.current, {
        altInput: true,
        altFormat: dateFormat,
        dateFormat: 'Y-m-d',
        defaultDate: tanggal.tgl_trans,
        disableMobile: "true",
        onChange: function(selectedDates, dateStr) {
          setTglSelesai(dateStr);
        },
      });

      };




axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Tanggal',
        selector: row => row.tgl_entry,
        sortable: true,
        width: "100px"
    },
    {
        name: 'ID Data',
        selector: row => row.pencairan_id,
        sortable: true,
        width: "160px"
    },
    {
        name: 'No SPP',
        selector: row => row.no_spp,
        sortable: true,
        width: "160px"
    },
    {
        name: 'Kd Desa',
        selector: row => row.kode_desa,
        sortable: true,
        width: "100px"
    },
    {
      name: 'Nama Desa',
      selector: row => row.nama_desa,
      sortable: true,
      width: "150px",
      cell: row => (
        <div title={row.nama_desa}>
          {row.nama_desa}
        </div>
      )
    },
    {
        name: 'Kantor',
        selector: row => row.nama_kantor,
        sortable: true,
        width: "120px",
        cell: row => (
          <div title={row.nama_kantor}>
            {row.nama_kantor}
          </div>
        )
    },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "200px",
        cell: row => (
          <div title={row.keterangan}>
            {row.keterangan}
          </div>
        )
    },
    {
      name: 'Status Trans',  
      sortable: false,
      width: "100px",
      selector: row => row.status_pencairan_desa,
      cell: (row) => {
        if ((row.status_pencairan_desa === '0' || row.status_pencairan_desa === null) && row.is_verified == 'unverified') {
          return (
            <div>
              <span           
                onClick={() => {
                  Swal.fire(
                    'Warning!',
                    'Data belum di proses, karena belum di verifikasi',
                    'warning'
                  );
                }}               
              className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Belum di proses"><IoHourglassOutline size={20} /></span>
            </div>
          );
        } else if ((row.status_pencairan_desa === '0' || row.status_pencairan_desa === null) && row.is_verified == 'verified') {
          return (
            <div>
              <span           
                onClick={() => {
                  Swal.fire(
                    'Warning!',
                    'Dalam proses transaksi',
                    'warning'
                  );
                }}               
              className="is-small mr-1" style={{ color: '#ff8400', cursor: 'pointer'}} title="Dalam Proses"><IoHourglassOutline size={20} /></span>
            </div>
          );
        } else if (row.status_pencairan_desa === '1') {
          return (
            <div>
              <span           
                onClick={() => {
                  Swal.fire(
                    'Berhasil!',
                    'Data sudah ditransaksikan',
                    'success'
                  );
                }}               
              className="is-small mr-1" style={{ color: '#48c78e', cursor: 'pointer'}} title="Selesai transaksi"><IoCheckmark size={20} /></span>
            </div>
          );
        
        } else {
          return null; 
        }
      }
   },
    {
      name: 'Rincian Trans',
      selector: row => {
        if (row.status_trans) {
          const StatusTrans = row.status_trans.split('|'); 
          return (
              <ul>
                  {StatusTrans.map((statustrans, index) => (
                     <li key={index}>
                         &nbsp;{statustrans}
                 </li>
                  ))}
              </ul>
          );
        }
        return 'Tidak Ada Data Transaksi';
      },
      sortable: true,
      width: "220px"

    },

    {

        name: 'File',
        selector: row => {
          if (row.filepencairan) {
            const fileNames = row.filepencairan.split('|'); 
            return (
                <ul>
                    {fileNames.map((fileName, index) => (
                       <li key={index}>
                        <a onClick={() => {handleOpenModalImage(row.pencairan_id, index)}}  >
                           <IoDocumentAttach /> &nbsp;{fileName}
                       </a>
                   </li>
                    ))}
                </ul>
            );
          }
          return 'Tidak Ada File';
        },
        sortable: true,
        width: "240px"

    },
  
    {
      name: 'Is Verified',  
      sortable: false,
      width: "90px",
      selector: row => row.is_verified,
      cell: (row) => {
        if (row.is_verified === 'unverified') {
          return (
            <div>
                <span
                onClick={() => {isVerified(row.pencairan_id, 'verified', 'verifikasi', row.status_pencairan_desa, row.is_lock)}}                
                className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Data belum di verifikasi"><IoHourglassOutline size={20} /></span>
            </div>
          );
        } else if (row.is_verified === 'verified') {
          return (
            <div>
                <span
                onClick={() => {isVerified(row.pencairan_id, 'unverified', 'batalkan verifikasi', row.status_pencairan_desa, row.is_lock)}}                  
                className="is-small mr-1" style={{ color: '#48c78e', cursor: 'pointer'}} title="Data sudah di verifikasi"><IoCheckmark size={20} /></span>
            </div>
          );
        } else {
          return null; 
        }
      }
  },
    {
        name: 'Action',
        sortable: false,
        selector: row => row.pencairan_id,

        cell: (row) => {
          if(role != 'admin'){
            if(role == 'des' && row.user_created == kodedesa ){
                if(row.is_verified === 'verified'){
                  return (
                    <div>
                      <span
                      onClick={() => {setIdPencairan(row.pencairan_id);getPencairanDesaById(row.pencairan_id, 'view');handleOpenModalDesa('view');resetForm();}}            
                      className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Lihat data"><IoEye size={20} /></span>
                    </div>
                    );
                }else{
                  return (
                  <div>
                    <span
                    onClick={() => {setIdPencairan(row.pencairan_id);getPencairanDesaById(row.pencairan_id, 'edit');handleOpenModalDesa('edit', row.is_verified);resetForm();}}          
                    className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Edit data"><IoCreate size={20} /></span>
                    <span
                    onClick={() => handleOpenModalCetakSlip(row.pencairan_id)}          
                    className="is-small mr-1" style={{ color: '#bf9000', cursor: 'pointer'}} title="Cetak slip"><IoPrint size={20} /></span>
                    <span           
                    onClick={() => {deletePencairanDesa(row.pencairan_id,  row.is_verified);}}
                    className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Hapus data"><IoTrash size={20} /></span>  
                  </div>
                    );
                }
            }else if(role == 'kec' || role == 'kab'){
              return (
              <span
              onClick={() => {setIdPencairan(row.pencairan_id);getPencairanDesaById(row.pencairan_id, 'view');handleOpenModalDesa('view');resetForm();}}            
              className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Lihat data"><IoEye size={20} /></span>
              );
            }
          }else if(role == 'admin'){
            return (
              <div>
                <span
                onClick={() => {setIdPencairan(row.pencairan_id);getPencairanDesaById(row.pencairan_id, 'view');handleOpenModalDesa('view');resetForm();}}            
                className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Lihat data"><IoEye size={20} /></span>
                <span
                onClick={() => {setIdPencairan(row.pencairan_id);getPencairanDesaById(row.pencairan_id, 'edit');handleOpenModalDesa('edit', row.is_verified);resetForm();}}         
                className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Edit data"><IoCreate size={20} /></span>
                <span
                onClick={() => handleOpenModalCetakSlip(row.pencairan_id)}         
                className="is-small mr-1" style={{ color: '#bf9000', cursor: 'pointer'}} title="Cetak slip"><IoPrint size={20} /></span>
                <span           
                onClick={() => {deletePencairanDesa(row.pencairan_id,  row.is_verified);}}
                className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Hapus data"><IoTrash size={20} /></span>  
            
            </div>
              );
          }
         
        },
        width: "180px"
    }
  ];

   
  const columnsInternal = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Status Trans',
      selector: row => row.statustrans,
      sortable: true,
      cell: row => {
      if (row.statustrans === '0') {
        return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
      }else if (row.statustrans === '1'){
        return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
      }else if (row.statustrans === '2'){
        return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoHourglassOutline size={20} /></span>;
      }
      },
      width: "120px"
    },
    {
        name: 'No Rekening',
        selector: row => row.no_rekening,
        sortable: true,
        cell: (row) => <div title={`Bank Tujuan ${row.namabank}.`}>{row.no_rekening}</div>,
        width: "150px"
    },
    {
        name: 'Nama',
        selector: row => row.nama,
        sortable: true,
        width: "300px",
        cell: row => (
          <div title={row.nama}>
            {row.nama}
          </div>
        )
    },
    {
        name: 'Nominal',
        selector: row => row.nominal,
        sortable: true,
        width: "150px"
   },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "400px"
    },
    {
      name: 'Action',
      sortable: false,
      selector: (row, index) => index,
      cell: (row, rowIndex) => {
       
        return (
          <div>
          <span
          onClick={() => handleOpenModalDataInternaledit(row, rowIndex)}          
          className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
          <span           
          onClick={() => {deleteDataInternal(rowIndex)}}
          className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>    
        </div>
          );
      }

  }

  ];

  const columnsTransfer = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Status Trans',
      selector: row => row.statustrans,
      sortable: true,
      cell: row => {
      if (row.statustrans === '0') {
        return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
      }else if (row.statustrans === '1'){
        return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
      }else if (row.statustrans === '2'){
        return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoHourglassOutline size={20} /></span>;
      }
      },
      width: "120px"
    },
    {
        name: 'No Rekening',
        selector: row => row.no_rekening,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Nama Penerima',
        selector: row => row.nama,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jumlah Setoran',
        selector: row => row.jumlahsetoran,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "250px"
    },
    {
        name: 'Kode Bank Tujuan',
        selector: row => row.kodebank,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Bank Tujuan',
        selector: row => row.namabank,
        sortable: true,
        width: "300px"
    },
    {
      name: 'File Respon',
      selector: row => {
        if (row.filerespontnt) {
          const fileNames = row.filerespontnt.split('|'); 
          return (
              <ul>
                  {fileNames.map((fileName, index) => ( 
                    
                     <li key={index}>
                      <a onClick={() => {handleOpenModalFileRespon(`${env.API_URL}/upload_tnt_respon/${fileName}`, `${fileName}`)}}  >
                         <IoDocumentAttach /> &nbsp;{fileName}
                     </a>
                 </li>
                  ))}
              </ul>
          );
        }
        return '-';
      },
      sortable: true,
      width: "250px"

  },
  {
    name: 'Action',
    sortable: false,
    selector: (row, index) => index,
    cell: (row, rowIndex) => {
     
      return (
        <div>
          <span
          onClick={() => handleOpenModalDataTransferedit(row, rowIndex)}          
          className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
          <span           
          onClick={() => {deleteDataTransfer(rowIndex)}}
          className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>    
      </div>
        );
    }

  }
  ];

  const columnsPajak = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Status Trans',
      selector: row => row.statustrans,
      sortable: true,
      cell: row => {
      if (row.statustrans === '0') {
        return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
      }else if (row.statustrans === '1'){
        return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
      }else if (row.statustrans === '2'){
        return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoHourglassOutline size={20} /></span>;
      }
      },
      width: "120px"
    },
    {
        name: 'Id Billing',
        selector: row => row.idbilling,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jenis Pajak',
        selector: row => row.jenispajak,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Masa Pajak',
        selector: row => row.masapajak,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jumlah Setoran',
        selector: row => row.jumlahsetoran,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "150px"
    },
    {

      name: 'File Respon',
      selector: row => {
        if (row.filerespontnt) {
          const fileNames = row.filerespontnt.split('|'); 
          return (
              <ul>
                  {fileNames.map((fileName, index) => ( 
                    
                     <li key={index}>
                      <a onClick={() => {handleOpenModalFileRespon(`${env.API_URL}/upload_tnt_respon/${fileName}`, `${fileName}`)}}  >
                         <IoDocumentAttach /> &nbsp;{fileName}
                     </a>
                 </li>
                  ))}
              </ul>
          );
        }
        return '-';
      },
      sortable: true,
      width: "250px"

  },
  {
    name: 'Action',
    sortable: false,
    selector: (row, index) => index,
    cell: (row, rowIndex) => {
     
      return (
        <div>
          <span
          onClick={() => handleOpenModalDataPajakedit(row, rowIndex)}        
          className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
          <span           
          onClick={() => {deleteDataPajak(rowIndex)}}
          className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>    
        </div>
        );
    }

  }
  ];
  const columnsBpjs = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Status Trans',
      selector: row => row.statustrans,
      sortable: true,
      cell: row => {
      if (row.statustrans === '0') {
        return <span style={{ color: '#f03a5f'}} title="Data belum ditransaksikan"><IoHourglassOutline size={20} /></span>;
      }else if (row.statustrans === '1'){
        return <span style={{ color: '#48c78e'}} title="Data sudah ditransaksikan"><IoCheckmark size={20} /></span>;
      }else if (row.statustrans === '2'){
        return <span style={{ color: '#ff8400'}} title="Data sudah dikirimkan ke admin TNT"><IoCheckmark size={20} /></span>;
      }
      },
      width: "120px"
    },
    {
        name: 'Nomor VA',
        selector: row => row.nova,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Jumlah Setoran',
        selector: row => row.jumlahsetoran,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Keterangan',
        selector: row => row.keterangan,
        sortable: true,
        width: "250px"
    },
    {

      name: 'File Respon',
      selector: row => {
        if (row.filerespontnt) {
          const fileNames = row.filerespontnt.split('|'); 
          return (
              <ul>
                  {fileNames.map((fileName, index) => ( 
                    
                     <li key={index}>
                      <a onClick={() => {handleOpenModalFileRespon(`${env.API_URL}/upload_tnt_respon/${fileName}`, `${fileName}`)}}  >
                         <IoDocumentAttach /> &nbsp;{fileName}
                     </a>
                 </li>
                  ))}
              </ul>
          );
        }
        return '-';
      },
      sortable: true,
      width: "250px"

    },
    {
      name: 'Action',
      sortable: false,
      selector: (row, index) => index,
      cell: (row, rowIndex) => {
       
        return (
          <div>
            <span
            onClick={() => handleOpenModalDataBpjsedit(row, rowIndex)}      
            className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
            <span           
            onClick={() => {deleteDataBpjs(rowIndex)}}
            className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>    
          </div>
          );
      }
  
    }
  ];

  useEffect(() => { 
   
    getVerifyPencairanDesa();
    setTglEntry(tanggalformatindo);
    setKodeDesa(user && user.kode_user_cbs);
    setNamaDesa(user && user.name);
    setNorekDesa(user && user.norek);
    getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
  
    fetchDataTgl(); 
    setRole(user && user.role);
    

  
  }, [user && user.kode_user_cbs, tglsekarang, user && user.is_tnt, user && user.role, user && user.name]);

useEffect(() => {
    const result = pencairandesa.filter((pencairandesa) => {
        return pencairandesa.pencairan_id.toLowerCase().match(search.toLowerCase())
    })
    setFilteredPencairanDesa(result)
 }, [search])


  const getPencairanDesa = async (id_user, tgl1, tgl2) => {
 
    const response = await axiosJWT.get(`${env.API_URL}/pencairan/${id_user}/${tgl1}/${tgl2}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })  
      setPencairanDesa(response.data)
      setFilteredPencairanDesa(response.data)
  }


  const getPencairanDesaFilter = async (e) => {
    e.preventDefault();
        var id_user = user && user.kode_user_cbs;
        var tglmulaif = settanggal(tglmulai);
        var tglselesaif = settanggal(tglselesai);
    const response = await axiosJWT.get(`${env.API_URL}/pencairan/${id_user}/${tglmulaif}/${tglselesaif}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setPencairanDesa(response.data)
    setFilteredPencairanDesa(response.data)
}


const handleBlurKodeBankUmum = async () => {


    try {
      const response = await axiosJWT.get(`${env.API_URL}/kodebank/${dataarraytransfer['kodebank']}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });

      if(response.data == ''){
        Swal.fire(  
          'Gagal!',
          'Kode Bank Umum Tidak Ditemukan!',
          'error'
      )
        setDataArrayTransfer({ ...dataarraytransfer, 'namabank':''});  
      } else {
        setDataArrayTransfer({ ...dataarraytransfer, 'namabank': response.data.nama_bank });
        
      }
     
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }


};

const getKodeBank = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/kodebank`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKodeBankAll(response.data);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const getKodeKecamatan = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/reffkecamatan`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKodeKecamatanAll(response.data);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const handleBlurKodeKecamatan = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/reffdesakec/${kodekecamatanselect}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    
    setKodeDesaAll(response.data);
    
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
      setKodeDesaAll([]);
    }
  }
};

const getKodeDesa = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/reffdesakec/${kodedesa}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKodeDesaAll(response.data);
    
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const savePencairanDesa = async (e) => {
  dispatch(getMe());
  e.preventDefault()


 if(user && user.kode_user_cbs == ''){
  Swal.fire(
    'Gagal!',
    'Kode user masih kosong!',
    'error'
)
}else if(user && user.name == ''){
  Swal.fire(
    'Gagal!',
    'Nama user masih kosong!',
    'error'
)
}else if(tglsekarang == ''){
  Swal.fire(
    'Gagal!',
    'Tanggal entry masih kosong!',
    'error'
)
}else if(idpencairan == ''){
  Swal.fire(
    'Gagal!',
    'Id Pencairan masih kosong!',
    'error'
)
}else if(nospp == ''){
  Swal.fire(
    'Gagal!',
    'Nomor SPP wajib di isi!',
    'error'
)
}else if(action == 'add' && selectedFiles == ''){
  Swal.fire(
    'Gagal!',
    'File belum diupload!',
    'error'
)
}else if(action == 'edit' && (selectedFiles == '' && selectedFilesdb == '')){
  Swal.fire(
    'Gagal!',
    'File belum diupload!',
    'error'
)
}else if(datarekeninginternal == '' && datarekeningtransfer == '' && datapajak == '' && databpjs == '' ){
  Swal.fire(
    'Gagal!',
    'Data transaksi masih kosong!',
    'error'
)
}else {
 
  if(action == 'add'){
    setLoading(true);
   
    const formData = new FormData();
    formData.append('nospp', nospp);
    formData.append('tglentry', tglsekarang);
    formData.append('kodedesa', user && user.kode_user_cbs);
    formData.append('norekdesa', user && user.norek);
    formData.append('alamatdesa', user && user.alamat);
    formData.append('namadesa', user && user.name);
    formData.append('kodekantor', user && user.kode_kantor);
    formData.append('kodekantorkas', user && user.kode_kantor_kas);
    formData.append('keterangan', keterangan);
    formData.append('usercreated', user && user.kode_user_cbs);
    formData.append('verified', 'unverified');
    formData.append('datarekeninginternal', JSON.stringify(datarekeninginternal));
    formData.append('datarekeningtransfer', JSON.stringify(datarekeningtransfer));
    formData.append('datapajak', JSON.stringify(datapajak));
    formData.append('databpjs', JSON.stringify(databpjs));

    selectedFiles.forEach((file) => {
      formData.append('files', file.file);
    });


    try {
      const response = await axiosJWT.post(`${env.API_URL}/pencairan`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
      });
      Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
      );
      setLoading(false);
      handleCloseModalDesa();
      getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
     
    } catch (error) {
      console.error('Error uploading files: ', error);
      Swal.fire(
        'Error!',
        'Terjadi kesalahan saat mengunggah data',
        'error'
      );
    }


}else if(action == 'edit'){
  setLoading(true);

  const formData = new FormData();
  formData.append('nospp', nospp);
  formData.append('tglentry', tglsekarang);
  formData.append('idpencairan', idpencairan);
  formData.append('norekdesa', user && user.norek);
  formData.append('namadesa', user && user.name);
  formData.append('kodekantor', user && user.kode_kantor);
  formData.append('keterangan', keterangan);
  formData.append('usermodified', user && user.kode_user_cbs);
  formData.append('datarekeninginternal', JSON.stringify(datarekeninginternal));
  formData.append('datarekeningtransfer', JSON.stringify(datarekeningtransfer));
  formData.append('datapajak', JSON.stringify(datapajak));
  formData.append('databpjs', JSON.stringify(databpjs));
   
        selectedFiles.forEach((file) => { 
          formData.append('files', file.file);
        });
      
        selectedFilesdbdel.forEach((file) => {
          formData.append('fileexistdel', file.file.name); 
        });
      
      
        try {
          const response = await axiosJWT.patch(`${env.API_URL}/pencairan/${idpencairan}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });

          Swal.fire(
            'Berhasil!',
            response.data.message,
            'success'
          );
          
          setLoading(false);
          handleCloseModalDesa();
          getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
       
        } catch (error) {
          Swal.fire(
            'Error!',
            error.response.data.msg,
            'error'
          );
        }

      }else{
        Swal.fire(
          'Gagal!',
          'Anda tidak berhak mengedit data ini!',
          'error'
        );
      }
  
 }


};


const deletePencairanDesa = async (idpencairan, isverified) => {
  if(isverified == 'verified' && role != 'admin' ){
    Swal.fire(  
      'Gagal!',
      'Data tidak bisa dihapus!',
      'error'
  )
  }else{
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosJWT.delete(`${env.API_URL}/pencairan/${idpencairan}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getPencairanDesa(user && user.kode_user_cbs, tglsekarang, tglsekarang);
    })
  }
};



return (
    
<div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>


      <h2 className="subtitle"><b>Pencairan Desa</b></h2>
   
      <div className="box">
          {role == "des" || role == 'admin'  ? (
          <button  onClick={() => {handleOpenModalDesa('add');resetForm()}} className="button is-small is-info" style={{marginRight:'5px'}}>
            Tambah Data
          </button>
          ): null}

          <button  onClick={() => {handleOpenModalPrintLaporan();}} className="button is-small is-warning" style={{marginRight:'5px'}}>
            Print Rekap Laporan
          </button>
          <button  onClick={() => {handleOpenModalPrintRincianTrans();}} className="button is-small is-warning">
            Print Rincian Transaksi
          </button>
    </div>
 
      <div className="box">
     
   
   
<div className="field is-horizontal mb-0">

    <label className="label is-small column is-1 pt-1">Periode</label>
    <input className="input is-small column is-2 mr-2" 
      ref={datePeriodeMulai}
      type="date" 
      value={tglmulai} 
    />
    <input className="input is-small column is-2 mr-2"  
     ref={datePeriodeSelesai}
     type="date" 
     value={tglselesai} 
    />
    <button className="button is-small is-primary" onClick={(e) => getPencairanDesaFilter(e)} title="Search Data"><IoSearch size="20"/></button>
   
</div>
     
            <DataTable
                        columns={columns}
                        data={Array.isArray(filteredpencairandesa) ? filteredpencairandesa : []}
                        // data={filteredpencairandesa}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />


<div className={`modal ${expand} modal-fx-3dRotateFromBottom ${ismodalopendesa ? 'is-active' : ''}`}>
        <div className="modal-background "></div>
        <div className="modal-content">
        <header className="modal-card-head">
              {expand === '' ? (
                <p className="modal-card-title" style={{marginLeft:'5px', fontSize: '20px',  cursor:'pointer'}} onClick={() => handleExpand()} title="Full Screen"><b>[Form Transaksi Desa]</b></p>
              ) : (
                <p className="modal-card-title" style={{marginLeft:'5px', fontSize: '20px',  cursor:'pointer'}} onClick={() => handleReduce()} title="Exit Full Screen"><b>[Form Transaksi Desa]</b></p>
              )}
              
              <button
                onClick={handleCloseModalDesa}
                className="delete"
                aria-label="close"
              />
            </header>


   
        <section className="modal-card-body" style={{padding:'5px'}} >
        {loading && <Loading />}
        <div className="tabs">
            <ul>
              <li
                className={activeTab === 0 ? 'is-active' : ''}
                onClick={() => handleTabClick(0)}>
                <div>Beranda</div>
              </li>
               <li
                className={activeTab === 1 ? 'is-active' : ''}
                onClick={() => handleTabClick(1)}>
               <div>Trans Internal</div>
              </li>
              <li
                className={activeTab === 2 ? 'is-active' : ''}
                onClick={() => handleTabClick(2)}>
               <div>Transfer Antar Bank</div>
              </li>
              <li
                className={activeTab === 3 ? 'is-active' : ''}
                onClick={() => handleTabClick(3)}>
               <div>Pajak</div>
              </li>
              <li
                className={activeTab === 4 ? 'is-active' : ''}
                onClick={() => handleTabClick(4)}>
               <div>BPJS</div>
              </li>
              
            </ul>
          </div>



          {activeTab === 0 && (
          <div>

            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                    <div className="columns mb-0">                              
                                  <div className="column is-half">
                                    <label className="label is-small mb-0">Kode User</label>
                                    <input
                                        title="Kode User"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={kodedesa}
                                        placeholder="Kode User"
                                        readOnly
                                        />
                                  </div>  

                                  <div className="column is-half">
                                    <label className="label is-small mb-0">Nama User</label>
                                    <input
                                        title="Nama User"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={namadesa}
                                        placeholder="Nama User"
                                        readOnly
                                        />
                                  </div>  


                    </div>  

                    <div className="columns  mb-0">
                                <div className="column is-half">
                                    <label className="label is-small mb-0">Tanggal Entry</label>
                                    <input
                                        title="Tanggal Permohonan"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={tglentry}
                                        placeholder="Tanggal Permohonan"
                                        readOnly
                                        />
                                  </div>  
                        </div>


                      </div>

                      <div>
                </div>

                      <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>

                      <div className="columns">
                                <div className="column is-half">
                                  <label className="label is-small mb-0">Id Pencairan</label>
                                  <input
                                      title="Id Pencairan"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={idpencairan}
                                      onChange={(e) => setIdPencairan(e.target.value)}
                                      placeholder="Id Pencairan"
                                      readOnly
                                    />
                                  </div>

                                  <div className="column is-half">
                                  <label className="label is-small mb-0">Nomor SPP</label>
                                  <input
                                      title="Nomor SPP"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={nospp}
                                      onChange={(e) => setNoSpp(e.target.value)}
                                      placeholder="Nomor SPP"
                                    />
                                  </div>

                          </div>

                          <div className="columns">
                                <div className="column is-full">
                                  <label className="label is-small mb-0">Keterangan</label>
                                  <input
                                      title="Keterangan"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={keterangan}
                                      onChange={(e) => setKeterangan(e.target.value)}
                                      placeholder="Keterangan"
                                    />
                                  </div>
                                </div>
            
                          </div>


                            
                                  
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p style={{fontSize:'14px'}}>Drag & drop some files here, or click to select files</p>
              </div>
           
              <div className="uploaded-files">
              <h3 style={{marginBottom:'5px', fontSize: '14px'}}>Uploaded Files:</h3>
                <ul>
                  {mergedFiles.map((file, index) => (
                    <li key={index} className="uploaded-file">
                      <span className="uploaded-file-icon" style={{fontSize: '20px'}}><IoDocumentAttachOutline /></span>
                      <span className="uploaded-file-name" onClick={() => handleOpenModalFile(file.src, file.file.name, file.ext)} style={{cursor:'pointer'}}>
                      {file.file.name}
                      </span>

                      <button className="button is-danger is-small" onClick={() => removeFile(index)}>
                        <IoTrash />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {uploading && <LoadingFile />}
            </div>


          </div>
          )}
          {activeTab === 1 && (
           <div>
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                <div className="columns">
                  <div className="column is-half">
                    <label className="label is-small mb-0">Nomor Rekening Sumber</label>
                    <input
                      title="Nomor Rekening"
                      type="text"
                      className="input is-small mb-0"
                      value={norekdesa}
                      placeholder="Nomor Rekening"
                      readOnly
                    />
                  </div>

                  <div className="column is-half">
                    <label className="label is-small mb-0">Nama Nasabah</label>
                      <input
                        title="Nama Nasabah"
                        type="text"
                        className="input is-small mb-0"
                        value={namadesa}
                        placeholder="Nama Nasabah"
                        readOnly
                        />
                  </div>
                </div>
          </div>

          

        <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
          <div className="columns mb-0"> 
                <div className="column is-half">
                <label className="label is-small mb-0">Import Data Rekening Internal Via Excel</label>
                </div>

                <div className="column is-half">
                  <label className="label is-small mb-0 is-pulled-right">Tambah Data Rekening Internal (Satuan)</label>
                </div>
          </div>

          <div className="columns mb-0"> 
              <div className="column is-half">
                <div className="control">
                  <input 
                    type="file"
                    className="input is-small"
                    onChange={handleFileInputInt}
                    placeholder="Choose a file..."
                    ref={fileInputRefInt}
                  />
                </div>
              </div>

              <div className="column is-half">
                <button className="button is-info is-small is-pulled-right"  onClick={() => handleOpenModalDataInternal()}>Add Data</button>
              </div>
          </div>

          
          <div className="columns mb-0"> 
            <div className="column is-full">
              <a href={`${env.API_URL}/format_upload_excel/Format_Overbooking_Internal.xlsx`} download>Download format upload excel (Transaksi Internal) </a>
            </div>
          </div>


        </div>

        {uploading && <LoadingFile />}

                  <DataTable
                    columns={columnsInternal}
                    data={Array.isArray(datarekeninginternal) ? datarekeninginternal : []}
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    highlightOnHover
                  />

        {datarekeninginternal != ''  ? (
          <div className="mr-2"> 
               <button className="button is-warning is-small is-pulled-right" onClick={() => {resetDataArrayInternal();}} >Reset Data</button>
          </div> 
        ):null}


        </div>
          )}
        {activeTab === 2 && (
           <div>
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                <div className="columns">
                  <div className="column is-half">
                    <label className="label is-small mb-0">Nomor Rekening Sumber</label>
                    <input
                      title="Nomor Rekening"
                      type="text"
                      className="input is-small mb-0"
                      value={norekdesa}
                      placeholder="Nomor Rekening"
                      readOnly
                    />
                  </div>

                  <div className="column is-half">
                    <label className="label is-small mb-0">Nama Nasabah</label>
                      <input
                        title="Nama Nasabah"
                        type="text"
                        className="input is-small mb-0"
                        value={namadesa}
                        placeholder="Nama Nasabah"
                        readOnly
                        />
                  </div>
                </div>
          </div>

          

        <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
          <div className="columns mb-0"> 
                <div className="column is-half">
                <label className="label is-small mb-0">Import Data Rekening Tujuan Via Excel</label>
                </div>

                <div className="column is-half">
                  <label className="label is-small mb-0 is-pulled-right">Tambah Data Rekening Tujuan (Satuan)</label>
                </div>
          </div>

          <div className="columns mb-0"> 
              <div className="column is-half">
                <div className="control">
                  <input 
                    type="file"
                    className="input is-small"
                    onChange={handleFileInputTransfer}
                    placeholder="Choose a file..."
                    ref={fileInputRefTransfer}
                  />
                </div>
              </div>

              <div className="column is-half">
                <button className="button is-info is-small is-pulled-right"  onClick={() => handleOpenModalDataTransfer()}>Add Data</button>
              </div>
          </div>

          <div className="columns mb-0"> 
            <div className="column is-full">
                <a href={`${env.API_URL}/format_upload_excel/Format_Transfer_Antar_Bank.xlsx`} download>Download format upload excel (Transfer Antar Bank) </a>
            </div>
          </div>

        </div>

        {uploading && <LoadingFile />}
                  
              <DataTable
                    columns={columnsTransfer}
                    data={Array.isArray(datarekeningtransfer) ? datarekeningtransfer : []}
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    highlightOnHover
                  />
       

        {datarekeningtransfer != ''  ? (
          <div className="mr-2"> 
               <button className="button is-warning is-small is-pulled-right" onClick={() => {resetDataArrayTransfer();}} >Reset Data</button>
          </div> 
        ):null}


        </div>
          )}
        {activeTab === 3 && (
           <div>
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                <div className="columns">
                  <div className="column is-half">
                    <label className="label is-small mb-0">Nomor Rekening Sumber</label>
                    <input
                      title="Nomor Rekening"
                      type="text"
                      className="input is-small mb-0"
                      value={norekdesa}
                      placeholder="Nomor Rekening"
                      readOnly
                    />
                  </div>

                  <div className="column is-half">
                    <label className="label is-small mb-0">Nama Nasabah</label>
                      <input
                        title="Nama Nasabah"
                        type="text"
                        className="input is-small mb-0"
                        value={namadesa}
                        placeholder="Nama Nasabah"
                        readOnly
                        />
                  </div>
                </div>
          </div>

          

        <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
          <div className="columns mb-0"> 
                <div className="column is-half">
                <label className="label is-small mb-0">Import Data Pembayaran Pajak Via Excel</label>
                </div>

                <div className="column is-half">
                  <label className="label is-small mb-0 is-pulled-right">Tambah Data Pembayaran Pajak (Satuan)</label>
                </div>
          </div>

          <div className="columns mb-0"> 
              <div className="column is-half">
                <div className="control">
                  <input 
                    type="file"
                    className="input is-small"
                    onChange={handleFileInputPajak}
                    placeholder="Choose a file..."
                    ref={fileInputRefPajak}
                  />
                </div>
              </div>

              <div className="column is-half">
                <button className="button is-info is-small is-pulled-right"  onClick={() => handleOpenModalDataPajak()}>Add Data</button>
              </div>
          </div>

          <div className="columns mb-0"> 
            <div className="column is-full">
                <a href={`${env.API_URL}/format_upload_excel/Format_Pembayaran_Pajak.xlsx`} download>Download format upload excel (Pembayaran Pajak) </a>
            </div>
          </div>


        </div>

        {uploading && <LoadingFile />}
            
              <DataTable
                    columns={columnsPajak}
                    data={Array.isArray(datapajak) ? datapajak : []}
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    highlightOnHover
                  />
       

        {datapajak != ''  ? (
          <div className="mr-2"> 
               <button className="button is-warning is-small is-pulled-right" onClick={() => {resetDataArrayPajak();}} >Reset Data</button>
          </div> 
        ):null}


        </div>
          )}
        {activeTab === 4 && (
           <div>
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                <div className="columns">
                  <div className="column is-half">
                    <label className="label is-small mb-0">Nomor Rekening Sumber</label>
                    <input
                      title="Nomor Rekening"
                      type="text"
                      className="input is-small mb-0"
                      value={norekdesa}
                      placeholder="Nomor Rekening"
                      readOnly
                    />
                  </div>

                  <div className="column is-half">
                    <label className="label is-small mb-0">Nama Nasabah</label>
                      <input
                        title="Nama Nasabah"
                        type="text"
                        className="input is-small mb-0"
                        value={namadesa}
                        placeholder="Nama Nasabah"
                        readOnly
                        />
                  </div>
                </div>
          </div>

          

        <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
          <div className="columns mb-0"> 
                <div className="column is-half">
                <label className="label is-small mb-0">Import Data Pembayaran BPJS Via Excel</label>
                </div>

                <div className="column is-half">
                  <label className="label is-small mb-0 is-pulled-right">Tambah Data Pembayaran BPJS (Satuan)</label>
                </div>
          </div>

          <div className="columns mb-0"> 
              <div className="column is-half">
                <div className="control">
                  <input 
                    type="file"
                    className="input is-small"
                    onChange={handleFileInputBpjs}
                    placeholder="Choose a file..."
                    ref={fileInputRefBpjs}
                  />
                </div>
              </div>

              <div className="column is-half">
                <button className="button is-info is-small is-pulled-right"  onClick={() => handleOpenModalDataBpjs()}>Add Data</button>
              </div>
          </div>

          <div className="columns mb-0" > 
            <div className="column is-full">
              <a  href={`${env.API_URL}/format_upload_excel/Format_Pembayaran_BPJS.xlsx`} download>Download format upload excel (Pembayaran BPJS) </a>
            </div>
          </div>

        </div>

        {uploading && <LoadingFile />}

                <DataTable
                    columns={columnsBpjs}
                    data={Array.isArray(databpjs) ? databpjs : []}
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    highlightOnHover
                  />
       

        {databpjs != ''  ? (
          <div className="mr-2"> 
               <button className="button is-warning is-small is-pulled-right" onClick={() => {resetDataArrayBpjs();}} >Reset Data</button>
          </div> 
        ):null}


        </div>
          )}
        </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {savePencairanDesa(e);}} disabled={disabledbtnsavepencairandesa} >Save</button>
              <button onClick={handleCloseModalDesa} className="button is-small is-danger">Close</button>  
            </footer>
        </div>
      </div>
      
      </div>




  <div className={`modal modal-fx-3dSlit ${isModalImageOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalImage()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Slider ref={sliderRef} {...sliderSettings} >
           
              {selectedFilesdb.map((item, index) => (
                <div key={index}                          
                className={`Image ${index === selectedImageIndex ? 'active' : ''}`}
                >

                  {item.ext === '.jpg' || item.ext === '.jpeg' || item.ext === '.png' ? (
                    <img src={item.src} alt={`Image ${index + 1}`} width="100%" height="400px" />
                  ) : (
                    <embed src={item.src} ext="application/pdf" width="100%" height="500px" />
                  )}

                </div>                              
              ))}
            </Slider>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalImage()}></button>
        </div>
      </div>


  <div className={`modal modal-fx-3dSlit ${isModalDataInternalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalDataInternal()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
           
                              <div className="columns mb-0">                              
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">No Rekening</label>
                                    <input
                                        title="No Rekening"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="no_rekening"
                                        value={dataarrayinternal['no_rekening']}
                                        onChange={handleInputChangeInternal}                            
                                        placeholder="No Rekening"                                       
                                        />
                                  </div>
                              </div>  


                              <div className="columns mb-0">  
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Nama Nasabah</label>
                                    <input
                                        title="Nama Nasabah"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="nama"
                                        value={dataarrayinternal['nama']}
                                        onChange={handleInputChangeInternal}
                                        placeholder="Nama Nasabah"               
                                        />
                                  </div>
                              </div>  
                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Nominal</label>
                                    <NumericFormat 
                                      title="nominal"
                                      thousandSeparator="."
                                      decimalSeparator="," 
                                      name="nominal"
                                      value={dataarrayinternal['nominal']}
                                      onChange={handleInputChangeInternal}
                                      className="input is-small mb-0"
                                      placeholder={'nominal'}
                                      decimalScale={2}
                                      />

                                  </div>  
                              </div>
                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Keterangan</label>
                                    <input
                                        title="Keterangan"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="keterangan"
                                        value={dataarrayinternal['keterangan']}
                                        onChange={handleInputChangeInternal}
                                        placeholder="Keterangan"
                                       
                                        />
                                  </div>  
                             </div> 

          </div>
         
          </section>

          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={(e) => {addDataTransSatuanInternal(e); }}>Save</button>
        <button className="button is-danger is-small" onClick={handleCloseModalDataInternal}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalDataInternal()}></button>
        </div>
</div>




<div className={`modal modal-fx-3dSlit ${isModalDataTransferOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalDataTransfer()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>

                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Nama Bank Tujuan</label>
                                    <p className="control">
                                    <div className="select is-fullwidth is-small mb-0">
                                    <select
                                      title="Bank Tujuan"
                                      name="kodebank"
                                      value={dataarraytransfer['kodebank']}
                                      onChange={handleInputChangeTransfer}
                                      onBlur={handleBlurKodeBankUmum}
                                      >
                                      <option value="">-- Pilih Bank Tujuan --</option>
                                            {kodebankall.map(bank => (
                                              <option key={bank.kode_bank} value={bank.kode_bank}>
                                                {bank.kode_bank} - {bank.nama_bank}
                                              </option>
                                            ))}
                                    </select>
                                    </div>
                                    </p>
                                  </div>  
                             </div> 

                              <div className="columns mb-0">                              
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">No Rekening Bank Tujuan</label>
                                    <input
                                        title="No Rekening Bank Tujuan"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="no_rekening"
                                        value={dataarraytransfer['no_rekening']}
                                        onChange={handleInputChangeTransfer}
                                        placeholder="No Rekening Bank Tujuan"                                       
                                        />
                                  </div>
                              </div>  


                              <div className="columns mb-0">  
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Nama Penerima</label>
                                    <input
                                        title="Nama Penerima"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="nama"
                                        value={dataarraytransfer['nama']}
                                        onChange={handleInputChangeTransfer}
                                        placeholder="Nama Penerima"
                                        />
                                  </div>
                              </div>  

                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Jumlah Setoran</label>
                                    <NumericFormat 
                                      title="Jumlah Setoran"
                                      thousandSeparator="."
                                      decimalSeparator="," 
                                      name="jumlahsetoran"
                                      value={dataarraytransfer['jumlahsetoran']}
                                      onChange={handleInputChangeTransfer}
                                      className="input is-small mb-0"
                                      placeholder={'Jumlah Setoran'}
                                      decimalScale={2}
                                      />

                                  </div>  
                              </div>

                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Keterangan</label>
                                    <input
                                        title="Keterangan"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="keterangan"
                                        value={dataarraytransfer['keterangan']}
                                        onChange={handleInputChangeTransfer}
                                        placeholder="Keterangan"
                                       
                                        />
                                  </div>  
                             </div> 


          </div>
         
          </section>

          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={(e) => {addDataTransSatuanTransfer(e); }}>Save</button>
        <button className="button is-danger is-small" onClick={handleCloseModalDataTransfer}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalDataTransfer()}></button>
        </div>
</div>




<div className={`modal modal-fx-3dSlit ${isModalDataPajakOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalDataPajak()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>

                              <div className="columns mb-0">                              
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Id Billing</label>
                                    <input
                                        title="Id Billing"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="idbilling"
                                        value={dataarraypajak['idbilling']}
                                        onChange={handleInputChangePajak}
                                        placeholder="Id Billing"                                       
                                        />
                                  </div>
                              </div>  


                              <div className="columns mb-0">  
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Jenis Pajak</label>
                                    <input
                                        title="Jenis Pajak"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="jenispajak"
                                        value={dataarraypajak['jenispajak']}
                                        onChange={handleInputChangePajak}
                                        placeholder="Jenis Pajak"
                                        />
                                  </div>
                              </div>  


                              <div className="columns mb-0">  
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Masa Pajak</label>
                                    <input
                                        title="Masa Pajak"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="masapajak"
                                        value={dataarraypajak['masapajak']}
                                        onChange={handleInputChangePajak}
                                        placeholder="Masa Pajak"
                                        />
                                  </div>
                              </div>  


                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Jumlah Setoran</label>
                                    <NumericFormat 
                                      title="Jumlah Setoran"
                                      thousandSeparator="."
                                      decimalSeparator="," 
                                      name="jumlahsetoran"
                                      value={dataarraypajak['jumlahsetoran']}
                                      onChange={handleInputChangePajak}
                                      className="input is-small mb-0"
                                      placeholder={'Jumlah Setoran'}
                                      decimalScale={2}
                                      />

                                  </div>  
                              </div>

                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Keterangan</label>
                                    <input
                                        title="Keterangan"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="keterangan"
                                        value={dataarraypajak['keterangan']}
                                        onChange={handleInputChangePajak}
                                        placeholder="Keterangan"
                                       
                                        />
                                  </div>  
                             </div> 


          </div>
         
          </section>

          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={(e) => {addDataTransSatuanPajak(e); }}>Save</button>
        <button className="button is-danger is-small" onClick={handleCloseModalDataPajak}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalDataPajak()}></button>
        </div>
</div>




<div className={`modal modal-fx-3dSlit ${isModalDataBpjsOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalDataBpjs()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
                             <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">No. Virtual Account</label>
                                    <input
                                        title="Nomor Virtual Account"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="nova"
                                        value={dataarraybpjs['nova']}
                                        onChange={handleInputChangeBpjs}
                                        placeholder="Nomor Virtual Account"
                                       
                                        />
                                  </div>  
                             </div> 

                           
                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Jumlah Setoran</label>
                                    <NumericFormat 
                                      title="Jumlah Setoran"
                                      thousandSeparator="."
                                      decimalSeparator="," 
                                      name="jumlahsetoran"
                                      value={dataarraybpjs['jumlahsetoran']}
                                      onChange={handleInputChangeBpjs}
                                      className="input is-small mb-0"
                                      placeholder={'Jumlah Setoran'}
                                      decimalScale={2}
                                      />

                                  </div>  
                              </div>

                              <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Keterangan</label>
                                    <input
                                        title="Keterangan"
                                        type="text"
                                        className="input is-small mb-0"
                                        name="keterangan"
                                        value={dataarraybpjs['keterangan']}
                                        onChange={handleInputChangeBpjs}
                                        placeholder="Keterangan"
                                       
                                        />
                                  </div>  
                             </div> 


          </div>
         
          </section>

          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={(e) => {addDataTransSatuanBpjs(e); }}>Save</button>
        <button className="button is-danger is-small" onClick={handleCloseModalDataBpjs}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalDataBpjs()}></button>
        </div>
</div>




      <div className={`modal modal-fx-3dSlit ${isModalOpenFile ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalFile()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <b>File : {nameFile}</b><br /><br />
            {extFile == '.pdf' ? (
            <Iframe src={urlFile}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
            ): extFile == '.jpg' || extFile == '.jpeg' || extFile == '.gif' || extFile == '.png' ? (
              <img src={urlFile} alt={nameFile} />
            ): null}
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalFile()}></button>
        </div>
      </div>



      <div className={`modal modal-fx-3dSlit ${isModalOpenCetakFile ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalCetakSlip()}></div>
        <div className="modal-content is-huge" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Iframe src={urlbyid}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalCetakSlip()}></button>
        </div>
      </div>

      <div className={`modal modal-fx-3dSlit ${isModalOpenFileRespon ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalFileRespon()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <b>File : {nameFile}</b><br /><br />
            <Iframe src={urlFile}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalFileRespon()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenPrintLaporan ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalPrintLaporan()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Rekap Laporan</b></p>
              <button
                onClick={handleCloseModalPrintLaporan}
                className="delete"
                aria-label="close"
              />
            </header>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
        

                                <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Mulai</label>

                                      <input                                
                                        ref={dateInputRefRekapLapMulai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Mulai (dd/MM/yyyy)"   
                                      />

                                   

                                  </div>  
                             </div> 

                             <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Selesai</label>

                                      <input
                                        ref={dateInputRefRekapLapSelesai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Selesai (dd/MM/yyyy)"                              
                                      />
                                   
                                        
                                  </div>  
                             </div> 

                            { role == 'kab' || role == 'admin' || role == 'supervisor' ? (
                              <div className="columns mb-0"> 
                              <div className="column is-full">
                                <label className="label is-small mb-0">Kode Kecamatan</label>
                                      <p className="control">
                                      <div className="select is-fullwidth is-small mb-0">
                                      <select
                                        title="Kode Kecamatan"
                                        value={kodekecamatanselect}
                                        onChange={(e) => setKodeKecamatanSelect(e.target.value)}
                                        onBlur={handleBlurKodeKecamatan}
                                        >
                                        <option value="">-- Pilih Nama Kecamatan --</option>
                                              {kodekecamatanall.map(kec => (
                                                <option key={kec.KODE_KECAMATAN} value={kec.KODE_KECAMATAN}>
                                                  {kec.KODE_KECAMATAN} - {kec.KECAMATAN}
                                                </option>
                                              ))}
                                      </select>
                                      </div>
                                      </p>
                              </div>  
                              </div> 
                            ):null}


                            { role == 'kec' || role == 'kab' || role == 'admin' || role == 'supervisor' ? (
                              <div className="columns mb-0">
                              <div className="column is-full">
                                <label className="label is-small mb-0">Kode Desa</label>
                               
                                <Multiselect
                                  options={options} 
                                  selectedValues={kodedesaselect} 
                                  onSelect={handleDesaSelect} 
                                  onRemove={handleDesaSelect} 
                                  displayValue="label" 
                                  placeholder="Pilih Desa"
                                  />
                            

                              </div>
                              </div>
                            ):null}
                            
          </div>
         
          </section>
          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={handleOpenModalCetakLaporan}>OK</button>
        <button className="button is-danger is-small" onClick={handleCloseModalPrintLaporan}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalPrintLaporan()}></button>
        </div>
      </div>



      <div className={`modal modal-fx-3dSlit ${isModalOpenPrintRincianTrans ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalPrintRincianTrans()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Rincian Transaksi</b></p>
              <button
                onClick={handleCloseModalPrintRincianTrans}
                className="delete"
                aria-label="close"
              />
            </header>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
        

                                <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Mulai</label>

                                      <input                                
                                        ref={dateInputRefTransLapMulai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Mulai (dd/MM/yyyy)"   
                                      />

                                   

                                  </div>  
                             </div> 

                             <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Selesai</label>

                                      <input
                                        ref={dateInputRefTransLapSelesai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Selesai (dd/MM/yyyy)"                              
                                      />
                                   
                                        
                                  </div>  
                             </div> 

                            { role == 'kab' || role == 'admin' || role == 'supervisor' ? (
                              <div className="columns mb-0"> 
                              <div className="column is-full">
                                <label className="label is-small mb-0">Kode Kecamatan</label>
                                      <p className="control">
                                      <div className="select is-fullwidth is-small mb-0">
                                      <select
                                        title="Kode Kecamatan"
                                        value={kodekecamatanselect}
                                        onChange={(e) => setKodeKecamatanSelect(e.target.value)}
                                        onBlur={handleBlurKodeKecamatan}
                                        >
                                        <option value="">-- Pilih Nama Kecamatan --</option>
                                              {kodekecamatanall.map(kec => (
                                                <option key={kec.KODE_KECAMATAN} value={kec.KODE_KECAMATAN}>
                                                  {kec.KODE_KECAMATAN} - {kec.KECAMATAN}
                                                </option>
                                              ))}
                                      </select>
                                      </div>
                                      </p>
                              </div>  
                              </div> 
                            ):null}
                            


                            { role == 'kec' || role == 'kab' || role == 'admin' || role == 'supervisor' ? (
                              <div className="columns mb-0">
                              <div className="column is-full">
                                <label className="label is-small mb-0">Kode Desa</label>
                                {/*                                
                                <Multiselect
                                  options={options} 
                                  selectedValues={kodedesaselect} 
                                  onSelect={handleDesaSelect} 
                                  onRemove={handleDesaSelect} 
                                  displayValue="label" 
                                  placeholder="Pilih Desa"
                                  /> */}

                                    <p className="control">
                                      <div className="select is-fullwidth is-small mb-0">
                                      <select
                                        title="Kode Kecamatan"
                                        value={kodedesaselect}
                                        onChange={(e) => setKodeDesaSelect(e.target.value)}
                                        >
                                        <option value="">-- Pilih Nama Desa --</option>
                                              {kodedesaall.map(des => (
                                                <option key={des.KODE_DESA} value={des.KODE_DESA}>
                                                  {des.KODE_DESA} - {des.DESA}
                                                </option>
                                              ))}
                                      </select>
                                      </div>
                                      </p>
                            

                              </div>
                              </div>
                            ):null}

                            <div className="columns mb-0"> 
                              <div className="column is-full">
                                <label className="label is-small mb-0">Jenis Transaksi</label>
                                      <p className="control">
                                      <div className="select is-fullwidth is-small mb-0">
                                      <select
                                        title="Jenis Transaksi"
                                        value={jenistransselect}
                                        onChange={(e) => setJenisTransSelect(e.target.value)}
                                        >
                                        <option value="">-- Pilih Jenis Transaksi --</option>
                                        <option value="0">Transaksi Internal</option>
                                        <option value="1">Transaksi Antar Bank</option>
                                        <option value="2">Pembayaran Pajak</option>
                                        <option value="3">Pembayaran BPJS</option>
                                          
                                      </select>
                                      </div>
                                      </p>
                              </div>  
                              </div> 

          </div>
         
          </section>
          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={handleOpenModalCetakRincianTrans}>OK</button>
        <button className="button is-danger is-small" onClick={handleCloseModalPrintRincianTrans}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalPrintRincianTrans()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenCetakLaporan ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalCetakLaporan()}></div>
        <div className="modal-content is-huge" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Iframe src={urlbyid}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalCetakLaporan()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenCetakRincianTrans ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalCetakRincianTrans()}></div>
        <div className="modal-content is-huge" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Iframe src={urlbyidrinciantrans}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalCetakRincianTrans()}></button>
        </div>
      </div>

      
      </div>
  );
};



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}




export default PencairanDesaList;
