import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoRefresh, IoPerson, IoKey, IoKeypad } from "react-icons/io5";
import { LoginUser, reset } from "../features/authSlice";
import axios from "axios";
import 'bulma/css/bulma.css';
import env from "react-dotenv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { insertLogUser } from '../app/GlobalVar';

const Login = ({localIP}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [captchaImage, setCaptchaImage] = useState('');
  const [captchaText, setCaptchaText] = useState('');

  const [captchaCode, setCaptchaCode] = useState('');
  const [logo, setLogo] = useState('');


  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const notify = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }



  useEffect(() => {
    if (user || isSuccess) {
      sessionStorage.setItem('islogin', true);
      navigate("/dashboard");
      insertLogUser(user && user.kode_user_ibs, 'Login', 'users')
    } else if (isError) {
      notify(message);
    }
    dispatch(reset());
  }, [user, isSuccess, isError, isLoading, dispatch, navigate]);

  useEffect(() => {
    fetchCaptcha();
    logoLogin();
  }, []);

  const reloadCaptcha = () => {
    fetchCaptcha();
  };


  const fetchCaptcha = async () => {
    try {
      const response = await axios.get(`${env.API_URL}/captcha`, { responseType: 'json' });
      const { captcha, image } = response.data;

      setCaptchaText(captcha);

      setCaptchaImage(image);
    } catch (error) {
      console.error(error);
    }
  };


  const logoLogin = async () => {
    try {
      const response = await axios.get(`${env.API_URL}/instansilogin`, { responseType: 'json' });

      setLogo(`${env.API_URL}/images/${response.data.instansi_logo}`);
    } catch (error) {
      console.error(error);
    }
  };


  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password, captchaCode, captchaText }));
  };

  const HorizontalLine = () => {
    return (
      <div
        style={{
          borderTop: '1px solid #cfcfcf',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      ></div>
    );
  };

  return (
    <div>
      <div className="section hero is-fullheight is-fullwidth" style={{ backgroundImage: 'linear-gradient(#d90101, #a24bb1)' }}>

        <ToastContainer />

        <div className="columns is-centered is-vcentered mt-3">
          <div className="column is-one-quarter">
            <div className="card" style={{ borderRadius: '10px' }}>
              <div className="card-content">
                <form>
                  <div className="field">
                    <div className="is-fullwidth"><center><img src={logo} width="250px" alt="logo" /></center></div>
                  </div>
                  <HorizontalLine />
                  <div className="field">
                    <center><h3 style={{ fontSize: '20px' }}>Sign In TNTD</h3></center>
                  </div>
                  <div className="control has-icons-left has-icons-right mb-2">
                    <input
                      className="input"
                      type="email"
                      placeholder="ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} />
                    <span className="icon is-small is-left">
                      <IoPerson />
                    </span>
                  </div>

                  <div className="control has-icons-left has-icons-right mb-2">
                    <input
                      className="input"
                      type="Password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} />
                    <span className="icon is-small is-left">
                      <IoKey />
                    </span>
                  </div>

                  <div className="control has-icons-left has-icons-right">
                    {captchaImage && <img src={`data:image/png;base64,${captchaImage}`} alt="Captcha Image" />}
                    <div className="button is-normal is-default ml-2" title="Refresh Captcha" onClick={reloadCaptcha}><IoRefresh /></div>
                  </div>

                  <div className="control has-icons-left has-icons-right mb-2">
                    <input
                      className="input"
                      type="text"
                      placeholder="Kode Keamanan"
                      value={captchaCode}
                      onChange={(e) => setCaptchaCode(e.target.value)} />
                    <span className="icon is-small is-left">
                      <IoKeypad />
                    </span>
                    <input
                      className="input"
                      type="hidden"
                      value={captchaText}
                      disabled
                    />
                  </div>

                  <div className="field">
                    <div className="control">
                      <button className="button is-danger is-fullwidth" style={{ borderRadius: '10px' }} onClick={(e) => { Auth(e); }}>
                        {isLoading ?   "Proses Login..." : "Sign In"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="is-fullwidth" style={{ height: '30px', padding: '2px', textAlign: 'center' }}>
          <h3 style={{ color: '#fff' }}> Copyright © 2023 <b>All Rights Reserved</b></h3>
        </div>

      </div>
    </div>
  );
};

export default Login;
