import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoEllipsisVertical, IoCreate } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";


const DesaList = () => {
  const dispatch = useDispatch();
  const [name, setName] = useLocalStorage('name', '');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const axiosJWT = axios.create();
  const [msg, setMsg] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [active, setActive] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [desa, setDesa] = useState([]);
  const [filteredDesa, setFilteredDesa] = useState([]);
  const [search, setSearch] = useState('');
  const [kodedesa, setKodeDesa] = useState("");
  const [namadesa, setNamaDesa] = useState("");
  const [kodekecamatan, setKodeKecamatan] = useState("");
  const [namakecamatan, setNamaKecamatan] = useState("");
  const [norekening, setNoRekening] = useState("");
  const [kodekantorkas, setKodeKantorKas] = useState("");
  const [kodekantor, setKodeKantor] = useState("");
  const [idedit, setIdEdit] = useState("");
  const [kodekantorall, setKodeKantorAll] = useState([]);
  const [kodekantorkasall, setKodeKantorKasAll] = useState([]);


  const handleOpenModal = (key) => {
    setIsModalOpen(true);
    setActive(key);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm()
    setActive("");
  };

  
  const resetForm = () => {
    setKodeDesa("")
    setNamaDesa("")
    setKodeKecamatan("")
    setNamaKecamatan("")
    setNoRekening("")
    setKodeKantorKas("")
    setKodeKantor("")
    setIdEdit("")
  }

  useEffect(() => {
    getDesa();  
    getKantor();
    getKantorKas();
  }, []);

  useEffect(() => {
    const result = desa.filter((desa) => {
        return desa.DESA.toLowerCase().match(search.toLowerCase())
    })
    setFilteredDesa(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Kode Desa',
        selector: row => row.KODE_DESA,
        sortable: true,
        width: "120px"
    },
    {
        name: 'Nama Desa',
        selector: row => row.DESA,
        sortable: true,
        width: "200px"
    },
    {
        name: 'Kode Kecamatan',
        selector: row => row.KODE_KECAMATAN,
        sortable: true,
        width: "150px"
    },
    {
      name: 'Nama Kecamatan',
      selector: row => row.KECAMATAN,
      sortable: true,
      width: "220px"
   },
    {
      name: 'No Rekening',
      selector: row => row.NO_REKENING,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Kode Kantor',
      selector: row => row.KODE_KANTOR,
      sortable: true,
      width: "120px"
    },
    {
      name: 'Kode Kantor Kas',
      selector: row => row.KODE_KANTOR_KAS,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Action',
      sortable: false,
      selector: row => row.id,
      cell: (row) => [
        <div>
        <span
         onClick={() => {setIdEdit(row.id);getDesaById(row.id, 'edit');handleOpenModal('edit');resetForm();}}            
        className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
        <span           
        onClick={() => {deleteDesa(row.id);}}
        className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
      </div>
        ]
  }
  ];


const getKantor = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/kantorcabang`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKodeKantorAll(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  
const getKantorKas = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/kantor`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKodeKantorKasAll(response.data);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const getDesa = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/reffdesa`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setDesa(response.data)
    setFilteredDesa(response.data)
}



const getDesaById = async (id) => {
  dispatch(getMe());
  try {
    const response = await axiosJWT.get(`${env.API_URL}/reffdesa/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });

    setKodeDesa(response.data.KODE_DESA);
    setNamaDesa(response.data.DESA);
    setKodeKecamatan(response.data.KODE_KECAMATAN);
    setNamaKecamatan(response.data.KECAMATAN);
    setNoRekening(response.data.NO_REKENING);
    setKodeKantorKas(response.data.KODE_KANTOR_KAS);
    setKodeKantor(response.data.KODE_KANTOR);
    setIdEdit(response.data.id);
   
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};

const saveDesa = async (e) => {
  dispatch(getMe());
  e.preventDefault();
 
  
if(active == 'add'){
if(kodedesa == ''){
  Swal.fire(
    'Gagal!',
    'Kode desa wajib di isi!',
    'error'
)
}else if (namadesa == ''){
  Swal.fire(
    'Gagal!',
    'Nama desa wajib di isi!',
    'error'
)
}else if (kodekecamatan == ''){
  Swal.fire(
    'Gagal!',
    'Kode kecamatan wajib di isi!',
    'error'
)
}else if (namakecamatan == ''){
  Swal.fire(
    'Gagal!',
    'Nama kecamatan wajib di isi!',
    'error'
)
}else if (norekening == ''){
  Swal.fire(
    'Gagal!',
    'No Rekening desa wajib di isi!',
    'error'
)
}else if (kodekantorkas == ''){
  Swal.fire(
    'Gagal!',
    'Kode kantor kas belum di pilih!',
    'error'
)
}else if (kodekantor == ''){
  Swal.fire(
    'Gagal!',
    'Kode kantor belum di pilih!',
    'error'
)
}else{
  try {

    await axiosJWT.post(`${env.API_URL}/reffdesa`, {
      kodedesa: kodedesa,
      desa: namadesa,
      kodekecamatan: kodekecamatan,
      kecamatan: namakecamatan,
      norekening: norekening,
      kodekantor: kodekantor,
      kodekantorkas: kodekantorkas

    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })

} catch (error) {
  Swal.fire(
    'Gagal!',
    error.response.data.msg,
    'error'
)
}

}


}else if(active == 'edit'){
  if (namadesa == ''){
    Swal.fire(
      'Gagal!',
      'Nama desa wajib di isi!',
      'error'
  )
  }else if (kodekecamatan == ''){
    Swal.fire(
      'Gagal!',
      'Kode kecamatan wajib di isi!',
      'error'
  )
  }else if (namakecamatan == ''){
    Swal.fire(
      'Gagal!',
      'Nama kecamatan wajib di isi!',
      'error'
  )
  }else if (norekening == ''){
    Swal.fire(
      'Gagal!',
      'No Rekening desa wajib di isi!',
      'error'
  )
  }else if (kodekantorkas == ''){
    Swal.fire(
      'Gagal!',
      'Kode kantor kas belum di pilih!',
      'error'
  )
  }else if (kodekantor == ''){
    Swal.fire(
      'Gagal!',
      'Kode kantor belum di pilih!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/reffdesa/${idedit}`, {
      kodedesa: kodedesa,
      desa: namadesa,
      kodekecamatan: kodekecamatan,
      kecamatan: namakecamatan,
      norekening: norekening,
      kodekantor: kodekantor,
      kodekantorkas: kodekantorkas

  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
}
getDesa()
};

  const deleteDesa = async (idedit) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosJWT.delete(`${env.API_URL}/reffdesa/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getDesa();
    })
}





return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>Daftar Referensi Desa</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal('add'); resetForm()}} className="button is-small is-info">
      Tambah Data
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredDesa}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />

<div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form Referensi Desa</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
                  <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Kode Desa</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={kodedesa}
                            onChange={(e) => setKodeDesa(e.target.value)}
                            placeholder="Kode Desa"
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Nama Desa</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={namadesa}
                          onChange={(e) => setNamaDesa(e.target.value)}
                          placeholder="Nama Desa"
                        />
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Kode Kecamatan</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={kodekecamatan}
                            onChange={(e) => setKodeKecamatan(e.target.value)}
                            placeholder="Kode Kecamatan"
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Nama Kecamatan</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={namakecamatan}
                          onChange={(e) => setNamaKecamatan(e.target.value)}
                          placeholder="Nama Kecamatan"
                        />
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Kantor</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={kodekantor}
                          onChange={(e) => setKodeKantor(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                                {kodekantorall.map(ktr => (
                                  <option key={ktr.kode_kantor} value={ktr.kode_kantor}>
                                    {ktr.nama_kantor}
                                  </option>
                                ))}
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Kantor Kas</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={kodekantorkas}
                          onChange={(e) => setKodeKantorKas(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                                {kodekantorkasall.map(ktr => (
                                  <option key={ktr.kode_kantor_kas} value={ktr.kode_kantor_kas}>
                                    {ktr.nama_kantor}
                                  </option>
                                ))}
                        </select>
                        </div>
                        </p>
                      </div>
                    </div>



                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">No. Rekening</label>
                           <input
                            type="text"
                            className="input is-small"
                            value={norekening}
                            onChange={(e) => setNoRekening(e.target.value)}
                            placeholder="Nomor Rekening"
                            />
                      </div>
                    </div>

            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveDesa(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default DesaList;
