import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoEllipsisVertical, IoCreate } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import { NumericFormat } from 'react-number-format';
import Multiselect from 'multiselect-react-dropdown';
import validator from 'validator';


const UserList = () => {

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState([]);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const [name, setName] = useLocalStorage('name', '');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const axiosJWT = axios.create();
  const [msg, setMsg] = useState("");

  const [idedit, setIdEdit] = useState("");
  const [kodeusercbs, setKodeUserCbs] = useState("");
  const [namausercbs, setNamaUserCbs] = useState("");
  const [nameedit, setNameEdit] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [kodekantor, setKodeKantor] = useState("");
  const [kodekantorkas, setKodeKantorKas] = useState("");

  const [menu, setMenu] = useState([]);
  const [menu2, setMenu2] = useState([]);
  const [menuall, setMenuAll] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [kantorcbg, setKantorcbg] = useState([]);
  const [kantorkas, setKantorkas] = useState([]);


  const [islogin, setIsLogin] = useState("");
  const [istnt, setIsTnt] = useState("");
  const [disablededit, setDisabledEdit] = useState(false);
  
  const handleOpenModal = (key) => {
    setIsModalOpen(true);
    getMenu();
    if(key == 'add'){
      setMenu([])
      setMenu2([])
      setMenuAll([])
      setDisabledEdit(false)
    }else if(key == 'edit'){
      setDisabledEdit(true)
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  
  const resetForm = () => {
    setIdEdit("")
    setKodeUserCbs("")
    setNameEdit("")
    setEmail("")
    setPassword("")
    setConfPassword("")
    setRole("")
    setStatus("")
    setKodeKantor("")
    setKodeKantorKas("")
    setNamaUserCbs("")
    setIsLogin("")
    setIsTnt("")
  }


  useEffect(() => {
    getUsers();
    getKantor();
    getKantorKas();
    getMenu();
  }, []);

  useEffect(() => {
    const result = users.filter((users) => {
        return users.name.toLowerCase().match(search.toLowerCase())
    })
    setFilteredUser(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Id CBS',
        selector: row => row.kode_user_cbs,
        sortable: true,
        width: "100px"
    },
    {
        name: 'Username CBS',
        selector: row => row.nama_user_cbs,
        sortable: true,
        width: "130px"
    },
    {
        name: 'Nama Lengkap',
        selector: row => row.name,
        sortable: true,
        width: "300px"
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        width: "300px"
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
        width: "100px"
    },
    {
        name: 'Is TNT',
        selector: row => row.is_tnt,
        sortable: true,
        width: "150px"
    },
    {
      name: 'Kode Kantor',
      selector: row => row.kode_kantor,
      sortable: true,
      width: "120px"
    },
    {
      name: 'Kode Kantor Kas',
      selector: row => row.kode_kantor_kas,
      sortable: true,
      width: "140px"
    },
    {
      name: 'Menu',
      selector: row => row.menu,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Is Login',
      selector: row => row.is_login,
      sortable: true,
      width: "100px"
    },
    {
        name: 'Action',
        sortable: false,
        selector: row => row.uuid,
        cell: (row) => [
          <div>
          <span
          onClick={() => {setIdEdit(row.uuid);getUserById(row.uuid, 'edit');handleOpenModal('edit');}}            
          className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
          <span           
          onClick={() => {deleteUser(row.uuid);}}
          className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
        </div>
          ],

        width: "80px"
    }
  ];

const getKantor = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/kantorcabang`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKantorcbg(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  const getMenu = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/menu`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setMenuAll(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };




  const options = menuall.map(mn => ({
    label: `${mn.id} - ${mn.nama_menu}`,
    value: mn.id,
  }));

  const handleMenuSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setMenu(selectedOptions);
    setMenu2(selectedValues);
  }


const getKantorKas = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/kantor`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKantorkas(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


const getUsers = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/users`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setUsers(response.data)
    setFilteredUser(response.data)
}

const getUserById = async (uuid) => {
  dispatch(getMe());
  try {
    const response = await axiosJWT.get(`${env.API_URL}/users/${uuid}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setIdEdit(response.data.uuid);
    setKodeUserCbs(response.data.kode_user_cbs);
    setNamaUserCbs(response.data.nama_user_cbs);
    setNameEdit(response.data.name);
    setEmail(response.data.email);
    setRole(response.data.role);
    setStatus(response.data.flag);
    setKodeKantor(response.data.kode_kantor);
    setKodeKantorKas(response.data.kode_kantor_kas);
    setIsLogin(response.data.is_login);
    setIsTnt(response.data.is_tnt);

    const menuuser = response.data.menu
    const menu = menuall
    .filter((dt) => menuuser.includes(dt.id)) // Filter data berdasarkan jenisrekening
    .map((dt) => ({
      label: `${dt.id} - ${dt.nama_menu}`,
      value: dt.id,
    }
    ));
    const menuValues = menu.map(option => option.value);
    setMenu(menu);
    setMenu2(menuValues);

  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};

const saveUser = async (e) => {
  dispatch(getMe());
  e.preventDefault();

  
if(idedit == ''){
if(kodeusercbs == ''){
  Swal.fire(
    'Gagal!',
    'Id user IBS wajib di isi!',
    'error'
)
}else if (namausercbs == ''){
  Swal.fire(
    'Gagal!',
    'Nama user wajib di isi!',
    'error'
)
}else if (nameedit == ''){
  Swal.fire(
    'Gagal!',
    'Nama user wajib di isi!',
    'error'
)
}else if (email == ''){
  Swal.fire(
    'Gagal!',
    'Email user wajib di isi!',
    'error'
)
}else if (!validator.isEmail(email)){
  Swal.fire(
    'Gagal!',
    'Invalid format email!',
    'error'
)
}else if (password == ''){
  Swal.fire(
    'Gagal!',
    'Password wajib di isi!',
    'error'
)
}else if (confPassword == ''){
  Swal.fire(
    'Gagal!',
    'Konfirmasi password wajib di isi!',
    'error'
)
}else if (!validator.isStrongPassword(password, { minLength: 6, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
  Swal.fire(
    'Gagal!',
    'Panjang minimum password 6 karakter, harus mengandung huruf besar, huruf kecil, angka, dan simbol!',
    'error'
)
}else if (password !== confPassword){
  Swal.fire(
    'Gagal!',
    'Password dan konfirmasi password tidak sama!',
    'error'
)
}else if (role == ''){
  Swal.fire(
    'Gagal!',
    'Role user wajib di isi!',
    'error'
)
}else if (status == ''){
  Swal.fire(
    'Gagal!',
    'Status user wajib di isi!',
    'error'
)
}else if (kodekantor == ''){
  Swal.fire(
    'Gagal!',
    'Kode Kantor wajib di isi!',
    'error'
)
}else if (kodekantorkas == ''){
  Swal.fire(
    'Gagal!',
    'Kode Kantor wajib di isi!',
    'error'
)
}else if (menu2 == ''){
  Swal.fire(
    'Gagal!',
    'Menu wajib di isi!',
    'error'
)
}
else{
  try {
    await axiosJWT.post(`${env.API_URL}/users`, {
      kode_user_cbs: kodeusercbs,
      nama_user_cbs: namausercbs,
      name: nameedit,
      email: email,
      password: password,
      confPassword: confPassword,
      role: role,
      status: status,
      kode_kantor: kodekantor,
      kode_kantor_kas: kodekantorkas,
      is_login: islogin,
      is_tnt: istnt,
      menu: menu2.toString(),
     
    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })

} catch (error) {
  Swal.fire(
    'Gagal!',
    error.response.data.msg,
    'error'
)
}

}


}else{
  if(kodeusercbs == ''){
    Swal.fire(
      'Gagal!',
      'Id user IBS wajib di isi!',
      'error'
  )
  }else if (namausercbs == ''){
    Swal.fire(
      'Gagal!',
      'Nama user wajib di isi!',
      'error'
  )
  }else if (nameedit == ''){
    Swal.fire(
      'Gagal!',
      'Nama user wajib di isi!',
      'error'
  )
  }else if (email == ''){
    Swal.fire(
      'Gagal!',
      'Email user wajib di isi!',
      'error'
  )
  }else if (!validator.isEmail(email)){
    Swal.fire(
      'Gagal!',
      'Invalid format email!',
      'error'
  )
  }else if (password != '' && !validator.isStrongPassword(password, { minLength: 6, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
    Swal.fire(
      'Gagal!',
      'Panjang minimum password 6 karakter, harus mengandung huruf besar, huruf kecil, angka, dan simbol!',
      'error'
  )
  }else if ((password != '') && (password != confPassword)){
    Swal.fire(
      'Gagal!',
      'Password dan konfirmasi password tidak sama!',
      'error'
  )
  }else if (role == ''){
    Swal.fire(
      'Gagal!',
      'Role user wajib di isi!',
      'error'
  )
  }else if (status == ''){
    Swal.fire(
      'Gagal!',
      'Status user wajib di isi!',
      'error'
  )
  }else if (kodekantor == ''){
    Swal.fire(
      'Gagal!',
      'Kode Kantor wajib di isi!',
      'error'
  )
  }else if (kodekantorkas == ''){
    Swal.fire(
      'Gagal!',
      'Kode Kantor wajib di isi!',
      'error'
  )
  }else if (menu == ''){
    Swal.fire(
      'Gagal!',
      'Menu wajib di isi!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/users/${idedit}`, {
      kode_user_cbs: kodeusercbs,
      nama_user_cbs: namausercbs,
      name: nameedit,
      email: email,
      password: password,
      confPassword: confPassword,
      role: role,
      status: status,
      kode_kantor: kodekantor,
      kode_kantor_kas: kodekantorkas,
      is_login: islogin,
      is_tnt: istnt,
      menu: menu2.toString(),
  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
}
getUsers()
};

  const deleteUser = async (idedit) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosJWT.delete(`${env.API_URL}/users/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getUsers();
    })
}





return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>List Users Internal</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal('add'); resetForm()}} className="button is-small is-info">
        Tambah Data
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredUser}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />


<div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form User</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
        
                  <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Id User CBS</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={kodeusercbs}
                            onChange={(e) => setKodeUserCbs(e.target.value)}
                            placeholder="Id User CBS"
                            disabled={disablededit}
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Username CBS</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={namausercbs}
                          onChange={(e) => setNamaUserCbs(e.target.value)}
                          placeholder="Username CBS"
                          
                        />
                      </div>
                    </div>


                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Nama Lengkap</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={nameedit}
                            onChange={(e) => setNameEdit(e.target.value)}
                            placeholder="Nama Lengkap"
                            
                            />
                      </div>

                      <div className="column is-half">
                        <label className="label is-small mb-0">Email</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email User"
                            />
                      </div>
                    </div>




                    {/* <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Maks Setoran</label>
                        
                     <NumericFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp '}
                        placeholder={'Maksimal Setoran'}
                        value={parseInt(maxsetoran)} 
                        onChange={(e) => setMaxSetoran(e.target.value)}
                        className="input is-small"
                        
                      />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Maks Penarikan</label>
                      <NumericFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp '}
                        placeholder={'Maksimal Penarikan'}
                        value={parseInt(maxpenarikan)} 
                        onChange={(e) => setMaxPenarikan(e.target.value)}
                        className="input is-small"
                        
                      />
                      </div>
                    </div> */}

                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Password</label>
                        <input
                            type="password"
                            className="input is-small"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Conf Password</label>
                      <input
                          type="password"
                          className="input is-small"
                          value={confPassword}
                          onChange={(e) => setConfPassword(e.target.value)}
                          placeholder="Konfirmasi Password"
                        />
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Role</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">-Pilih-</option>
                          <option value="admin">Administrator</option>
                          <option value="supervisor">Supervisor</option>
                          <option value="operator">Operator</option>
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Status Aktif</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="0">Aktif</option>
                          <option value="1">Blokir</option>
                        </select>
                        </div>
                        </p>
                      </div>
                    </div>


                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Kantor</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={kodekantor}
                          onChange={(e) => setKodeKantor(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                                {kantorcbg.map(ktr => (
                                  <option key={ktr.kode_kantor} value={ktr.kode_kantor}>
                                    {ktr.nama_kantor}
                                  </option>
                                ))}
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Kantor Kas</label>
                      <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={kodekantorkas}
                          onChange={(e) => setKodeKantorKas(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                                {kantorkas.map(ktr => (
                                  <option key={ktr.kode_kantor_kas} value={ktr.kode_kantor_kas}>
                                    {ktr.nama_kantor}
                                  </option>
                                ))}
                        </select>
                        </div>
                        </p>
                      </div>
                    </div>


                    {/* <div className="columns">

                      <div className="column is-half">
                      <label className="label is-small mb-0">Otorisator</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={isoto}
                          onChange={(e) => setIsOto(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="y">Ya</option>
                          <option value="n">Tidak</option>
                        </select>
                        </div>
                        </p>
                      </div>

                  <div className="column is-half">
                        <label className="label is-small mb-0">No. Rekening</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={norekening}
                            onChange={(e) => setNoRekening(e.target.value)}
                            placeholder="Nomor Rekening"
                            />
                      </div>
                             
                    </div> */}

                    <div className="columns">
                    <div className="column is-half">
                      <label className="label is-small mb-0">Status Login</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={islogin}
                          onChange={(e) => setIsLogin(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="1">Ya</option>
                          <option value="0">Tidak</option>
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Status TNT</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={istnt}
                          onChange={(e) => setIsTnt(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="requester">Requester</option>
                          <option value="executor">Executor</option>
                          <option value="controller">Controller</option>
                        </select>
                        </div>
                        </p>
                      </div>

                    </div>

                    <div className="columns">
                      <div className="column is-full">
                              <Multiselect
                              options={options} 
                              selectedValues={menu} 
                              onSelect={handleMenuSelect} 
                              onRemove={handleMenuSelect} 
                              displayValue="label" 
                              placeholder="Select Menu"
                              />
                        </div>
                    </div>




            {/* </form> */}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveUser(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default UserList;
