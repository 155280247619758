import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoCreate, IoEllipsisVertical } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";



const KantorList = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [active, setActive] = useState("");

  const [name, setName] = useLocalStorage('name', '');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const axiosJWT = axios.create();
  const [msg, setMsg] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const [kantor, setKantor] = useState([]);
  const [filteredKantor, setFilteredKantor] = useState([]);
  const [search, setSearch] = useState('');
  const [kodekantor, setKodeKantor] = useState("");
  const [kodekantorkas, setKodeKantorKas] = useState("");
  const [namakantor, setNamaKantor] = useState("");
  const [alamatkantor, setAlamatKantor] = useState("");
  const [status, setStatus] = useState("");
  const [idedit, setIdEdit] = useState("");


  const handleOpenModal = (key) => {
    setIsModalOpen(true);
    setActive(key);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm()
    setActive("")
  };

  
  const resetForm = () => {
    setKodeKantor("")
    setKodeKantorKas("")
    setNamaKantor("")
    setAlamatKantor("")
    setStatus("")
    setIdEdit("")
  }


  useEffect(() => {
    getKantor();  
  }, []);

  useEffect(() => {
    const result = kantor.filter((kantor) => {
        return kantor.nama_kantor.toLowerCase().match(search.toLowerCase())
    })
    setFilteredKantor(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Kode Kantor Kas',
        selector: row => row.kode_kantor_kas,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Kode Kantor',
        selector: row => row.kode_kantor,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Nama Kantor',
        selector: row => row.nama_kantor,
        sortable: true,
        width: "300px"
    },
    {
        name: 'Alamat kantor',
        selector: row => row.alamat_kantor,
        sortable: true,
        width: "400px"
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        width: "150px"
    },
    {
      name: 'Action',
      sortable: false,
      selector: row => row.id,
      cell: (row) => [
        <div>
        <span
        onClick={() => {setIdEdit(row.id);getKantorById(row.id, 'edit');handleOpenModal('edit');resetForm();}}            
        className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
        <span           
        onClick={() => {deleteKantor(row.id);}}
        className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
      </div>
        ]
    }
  ];



const getKantor = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/kantor`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setKantor(response.data)
    setFilteredKantor(response.data)
}



const getKantorById = async (id) => {
  dispatch(getMe());
  try {
    const response = await axiosJWT.get(`${env.API_URL}/kantor/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });

    setKodeKantor(response.data.kode_kantor);
    setKodeKantorKas(response.data.kode_kantor_kas);
    setNamaKantor(response.data.nama_kantor);
    setAlamatKantor(response.data.alamat_kantor);
    setStatus(response.data.status);
    setIdEdit(response.data.id);
   
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};

const saveKantor = async (e) => {
  dispatch(getMe());
  e.preventDefault();
  
if(active == 'add'){
if(kodekantorkas == ''){
  Swal.fire(
    'Gagal!',
    'Kode kantor wajib di isi!',
    'error'
)
}else if (kodekantor == ''){
  Swal.fire(
    'Gagal!',
    'Nama kantor wajib di isi!',
    'error'
)
}else if (namakantor == ''){
  Swal.fire(
    'Gagal!',
    'Nama kantor wajib di isi!',
    'error'
)
}else if (alamatkantor == ''){
  Swal.fire(
    'Gagal!',
    'Alamat kantor wajib di isi!',
    'error'
)
}else if (status == ''){
  Swal.fire(
    'Gagal!',
    'Status kantor belum di pilih!',
    'error'
)
}else{
  try {
    await axiosJWT.post(`${env.API_URL}/kantor`, {
      kodekantorkas: kodekantorkas,
      kodekantor: kodekantor,
      namakantor: namakantor,
      alamatkantor: alamatkantor,
      status: status

    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })

} catch (error) {
  Swal.fire(
    'Gagal!',
    error.response.data.msg,
    'error'
)
}

}

}else if(active == 'edit'){
  if(kodekantorkas == ''){
    Swal.fire(
      'Gagal!',
      'kode kantor kas wajib di isi!',
      'error'
  )
  }else if (kodekantor == ''){
    Swal.fire(
      'Gagal!',
      'Kode kantor wajib di isi!',
      'error'
  )
  }else if (namakantor == ''){
    Swal.fire(
      'Gagal!',
      'Nama user wajib di isi!',
      'error'
  )
  }else if (alamatkantor == ''){
    Swal.fire(
      'Gagal!',
      'Email user wajib di isi!',
      'error'
  )
  }else if (status == ''){
    Swal.fire(
      'Gagal!',
      'Status kantor belum di pilih!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/kantor/${idedit}`, {
    kodekantorkas: kodekantorkas,
    kodekantor: kodekantor,
    namakantor: namakantor,
    alamatkantor: alamatkantor,
    status: status
  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
}
getKantor()
};

  const deleteKantor = async (idedit) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosJWT.delete(`${env.API_URL}/kantor/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getKantor();
    })
}


return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>Daftar Referensi Kantor</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal('add'); resetForm()}} className="button is-small is-info">
      Tambah Data
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredKantor}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                 
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />

<div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form Referensi Kantor</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
      

                  <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Kode Kantor Kas</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={kodekantorkas}
                            onChange={(e) => setKodeKantorKas(e.target.value)}
                            placeholder="Kode Kantor Kas"
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Kode Kantor</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={kodekantor}
                          onChange={(e) => setKodeKantor(e.target.value)}
                          placeholder="Kode Kantor"
                        />
                      </div>
                    </div>


                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Nama Kantor</label>
                           <input
                            type="text"
                            className="input is-small"
                            value={namakantor}
                            onChange={(e) => setNamaKantor(e.target.value)}
                            placeholder="Nama Kantor"
                            />
                      </div>

                      <div className="column is-half">
                        <label className="label is-small mb-0">Alamat Kantor</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={alamatkantor}
                            onChange={(e) => setAlamatKantor(e.target.value)}
                            placeholder="Alamat Kantor"
                           
                            />
                      </div>
                    </div>

                 

                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Status</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="">-Pilih-</option>
                          <option value="cabang">Cabang</option>
                          <option value="kas">Kantor Kas</option>
                        </select>
                        </div>
                        </p>
                      </div>

                    
                    </div>

            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveKantor(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default KantorList;
