import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoEllipsisVertical, IoCreate } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";

const AbaList = () => {
  const dispatch = useDispatch();
  const [activeRow, setActiveRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useLocalStorage('name', '');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const axiosJWT = axios.create();
  const [msg, setMsg] = useState("");


  const [active, setActive] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aba, setAba] = useState([]);
  const [filteredaba, setFilteredAba] = useState([]);
  const [search, setSearch] = useState('');
  const [norekaba, setNorekAba] = useState("");
  const [namaaba, setNamaAba] = useState("");
  const [jenisaba, setJenisAba] = useState("");
  const [noalternatif, setNoAlternatif] = useState("");
  const [idedit, setIdEdit] = useState("");


  const handleOpenModal = (key) => {
    setIsModalOpen(true);
    setActive(key);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm()
    setActive("");
  };

  
  const resetForm = () => {
    setNorekAba("")
    setNamaAba("")
    setJenisAba("")
    setNoAlternatif("")
    setIdEdit("")
  }

  useEffect(() => {
    getAba();  
  }, []);

  useEffect(() => {
    const result = aba.filter((aba) => {
        return aba.norek_aba.toLowerCase().match(search.toLowerCase())
    })
    setFilteredAba(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Norek Aba',
        selector: row => row.norek_aba,
        sortable: true,
        width: "200px"
    },
    {
        name: 'Nama Aba',
        selector: row => row.nama_aba,
        sortable: true,
        width: "300px"
    },
    {
      name: 'Jenis ABA',
      selector: row => row.jenis_aba,
      sortable: true,
      cell: row => {
        let jenisabatext = '';
        if (row.jenis_aba === '10') {
          jenisabatext = 'Giro';
        } else if (row.jenis_aba === '20') {
          jenisabatext = 'Tabungan';
        } 
        return <span>{jenisabatext}</span>;
      },
      width: "300px"
  },

    {
        name: 'NO. Alternatif',
        selector: row => row.no_alternatif,
        sortable: true,
        width: "200px"
    },
    {
      name: 'Action',
      sortable: false,
      selector: row => row.id,
      cell: (row) => [
      <div>
        <span
         onClick={() => {setIdEdit(row.id);getAbaById(row.id, 'edit');handleOpenModal('edit');resetForm();}}             
        className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
        <span           
         onClick={() => {deleteAba(row.id);}}
        className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
      </div>     
        ]
    }
  ];


const getAba = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/aba`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setAba(response.data)
    setFilteredAba(response.data)
}

const getAbaById = async (id) => {
  dispatch(getMe());
  try {
    const response = await axiosJWT.get(`${env.API_URL}/aba/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });

    setNorekAba(response.data.norek_aba);
    setNamaAba(response.data.nama_aba);
    setJenisAba(response.data.jenis_aba);
    setNoAlternatif(response.data.no_alternatif);
    setIdEdit(response.data.id);
   
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const saveAba = async (e) => {
  dispatch(getMe());
  e.preventDefault();
 
  
if(active == 'add'){
if(norekaba == ''){
  Swal.fire(
    'Gagal!',
    'No. rekening ABA wajib di isi!',
    'error'
)
}else if (namaaba == ''){
  Swal.fire(
    'Gagal!',
    'Nama rekening ABA wajib di isi!',
    'error'
)
}else if (jenisaba == ''){
  Swal.fire(
    'Gagal!',
    'Jenis ABA belum di pilih!',
    'error'
)
}else{
  try {
    await axiosJWT.post(`${env.API_URL}/aba`, {
      norekaba: norekaba,
      namaaba: namaaba,
      jenisaba: jenisaba,
      noalternatif: noalternatif,
    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })

} catch (error) {
  Swal.fire(
    'Gagal!',
    error.response.data.msg,
    'error'
)
}
}
}else if(active == 'edit'){
  if(norekaba == ''){
    Swal.fire(
      'Gagal!',
      'No. rekening ABA wajib di isi!',
      'error'
  )
  }else if (namaaba == ''){
    Swal.fire(
      'Gagal!',
      'Nama rekening ABA wajib di isi!',
      'error'
  )
  }else if (jenisaba == ''){
    Swal.fire(
      'Gagal!',
      'Jenis ABA belum di pilih!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/aba/${idedit}`, {
    norekaba: norekaba,
    namaaba: namaaba,
    jenisaba: jenisaba,
    noalternatif: noalternatif,
  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
}
getAba()
};

  const deleteAba = async (idedit) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosJWT.delete(`${env.API_URL}/aba/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getAba();
    })
}





return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>Daftar Referensi ABA</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal('add'); resetForm()}} className="button is-small is-info">
      Tambah Data
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredaba}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />

<div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form Referensi ABA</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
      

                  <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Norek ABA</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={norekaba}
                            onChange={(e) => setNorekAba(e.target.value)}
                            placeholder="No. rekening ABA"
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Nama ABA</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={namaaba}
                          onChange={(e) => setNamaAba(e.target.value)}
                          placeholder="Nama ABA"
                        />
                      </div>
                    </div>
                    


                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Jenis Aba</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={jenisaba}
                          onChange={(e) => setJenisAba(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                           <option value="10">Giro</option>
                           <option value="20">Tabungan</option>      
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">No Alternatif</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={noalternatif}
                          onChange={(e) => setNoAlternatif(e.target.value)}
                          placeholder="No Alternatif"
                        />
                      </div>
                    </div>


            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveAba(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default AbaList;
