import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoArrowRedo } from "react-icons/io5";
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import { formatTanggal } from '../app/GlobalVar';
import Iframe from "react-iframe";

const Home = () => {
 
    var myDate = new Date(Date.now()); 
    var hours= myDate.getHours();
    var greet;

    if (hours < 10)
      greet =  "pagi";
    else if (hours >= 10 && hours <= 14)
      greet = "siang";
    else if (hours >= 14 && hours <= 18)
      greet = "sore";
    else if (hours >= 18 && hours <= 24)
      greet = "malam";

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useLocalStorage('name', '');
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()
  const [search, setSearch] = useState('')
  const [msg, setMsg] = useState("");
  const [namainstansi, setNamaInstansi] = useState('')
  const [tgltrans, setTglTrans] = useState('');



useEffect(() => {
       fetchDataTglTrans();
       getInstansi();
}, [])


axiosJWT.interceptors.request.use(async (config) => {
  const currentDate = new Date()
  if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${env.API_URL}/token`)
      config.headers.Authorization = `Bearer ${response.data.accessToken}`
      setToken(response.data.accessToken)
      const decoded = jwt_decode(response.data.accessToken)
      setName(decoded.name)
      setExpire(decoded.exp)
  }
  return config
}, (error) => Promise.reject(error))

const getInstansi = async () => {
  const response = await axiosJWT.get(`${env.API_URL}/instansi`,{
    headers: {
        Authorization: `Bearer ${token}`
    }
  });
  
  setNamaInstansi(response.data[0].instansi_nama);
};

  const fetchDataTglTrans = async () => {
    const tanggal = await formatTanggal();
    setTglTrans(tanggal.tgl_trans);
  };


return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{borderRadius: '5px'}}>
      <h2 className="subtitle"><b>Dashboard</b></h2>
      <div className="box">
      <h2 className="subtitle">
        Selamat {greet} <strong>{user && user.name}</strong>
      </h2>
      <p> Selamat datang di Aplikasi <b>Transaksi Non Tunai Desa (TNTD)</b> {namainstansi}</p><br />
    </div>
  
</div>

  );
};

export default Home;







