import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoEllipsisVertical, IoCreate } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import Multiselect from 'multiselect-react-dropdown';
import validator from 'validator';

const UserExtList = () => {

  const dispatch = useDispatch();
  const [usersext, setUsersExt] = useState([]);
  const [filteredUserext, setFilteredUserExt] = useState([]);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const [name, setName] = useLocalStorage('name', '');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const axiosJWT = axios.create();
  const [msg, setMsg] = useState("");


  const [iduserext, setIdUserExt] = useState("");
  const [kodeinstansi, setKodeInstansi] = useState("");
  const [norek, setNorek] = useState("");
  const [namaext, setNamaExt] = useState("");
  const [alamat, setAlamat] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [emailext, setEmailExt] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [islogin, setIsLogin] = useState("");

  const [menu, setMenu] = useState([]);
  const [menu2, setMenu2] = useState([]);
  const [menuall, setMenuAll] = useState([]);
  const [disablednorek, setDisabledNorek] = useState(false);
  const [disabledrole, setDisabledRole] = useState(false);

  const [kodekantorkas, setKodeKantorKas] = useState("");
  const [kodekantor, setKodeKantor] = useState("");
  const [kantorcbg, setKantorcbg] = useState([]);
  const [kantorkas, setKantorkas] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState("");

  const handleOpenModal = (key, role) => {
    setIsModalOpen(true);
    setAction(key);
    getMenu();
    if(key == 'add'){
      setMenu([])
      setMenu2([])
      setMenuAll([])
      setDisabledRole(false);
    }else if(key == 'edit'){
      setDisabledRole(true);
      if(role != 'des'){
        setDisabledNorek(true);
      }else{
        setDisabledNorek(false);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  
  const resetForm = () => {
    setIdUserExt("");
    setKodeInstansi("");
    setNorek("");
    setNamaExt("");
    setKecamatan("");
    setEmailExt("");
    setRole("");
    setStatus("");
    setPassword("");
    setConfPassword("");
    setKodeKantor("");
    setKodeKantorKas("");
    setIsLogin("");
  }

  const resetFormEdit = () => {
    setPassword("")
    setConfPassword("")
  }

  useEffect(() => {
    getUserExt();
    getKantor();
    getKantorKas();
    getMenu();
    
  }, []);

  useEffect(() => {
    const result = usersext.filter((usersext) => {
        return usersext.name.toLowerCase().match(search.toLowerCase())
    })
    setFilteredUserExt(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
      name: 'Role',  
      sortable: false,
      width: "100px",
      selector: row => row.role,
      cell: (row) => {
        if (row.role === 'des') {
          return <span>Desa</span>;
        } else if (row.role === 'kec') {
          return <span>Kecamatan</span>;
        } else if (row.role === 'kab') {
          return <span>Kabupaten</span>;
        } else {
          return null; 
        }
      }
    },
    {
        name: 'Kode Instansi',
        selector: row => row.kode_instansi,
        sortable: true,
        width: "150px"
    },
    {
        name: 'Nama User',
        selector: row => row.name,
        sortable: true,
        width: "350px"
    },
    {
        name: 'Kecamatan',
        selector: row => row.nama_kecamatan,
        sortable: true,
        width: "250px"
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        width: "250px"
    },
    {
        name: 'Kode Kantor Kas',
        selector: row => row.kode_kantor_kas,
        sortable: true,
        width: "150px"
    },
    {
      name: 'Status',
      selector: row => row.flag == '0' ? 'Aktif' : 'Blokir',
      sortable: true,
      width: "120px"
    },
    {
      name: 'Menu',
      selector: row => row.menu,
      sortable: true,
      width: "100px"
    },
    {
      name: 'Action',
      sortable: false,
      selector: row => row.uuid,
      cell: (row) => [
        <div>
        <span
        onClick={() => {setIdUserExt(row.uuid);getUserExtById(row.uuid);handleOpenModal('edit', row.role);resetForm();}}            
        className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
        <span           
        onClick={() => {deleteUserExt(row.uuid);}}
        className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
      </div>
        ],

      width: "80px"
  }
   
  ];


const getUserExt= async () => {
    const response = await axiosJWT.get(`${env.API_URL}/usersext`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setUsersExt(response.data)
    setFilteredUserExt(response.data)
}


const getUserExtById = async (uuid) => {
  dispatch(getMe());
  try {
    const response = await axiosJWT.get(`${env.API_URL}/usersext/${uuid}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });

    setIdUserExt(response.data.uuid)
    setKodeInstansi(response.data.kode_instansi);
    setNorek(response.data.norek);
    setNamaExt(response.data.name);
    setAlamat(response.data.alamat);
    setKecamatan(response.data.nama_kecamatan);
    setEmailExt(response.data.email);
    setRole(response.data.role);
    setStatus(response.data.flag);
    setKodeKantor(response.data.kode_kantor);
    setKodeKantorKas(response.data.kode_kantor_kas);
    setIsLogin(response.data.is_login);

    const menuuser = response.data.menu
    const menu = menuall
    .filter((dt) => menuuser.includes(dt.id)) // Filter data berdasarkan jenisrekening
    .map((dt) => ({
      label: `${dt.id} - ${dt.nama_menu}`,
      value: dt.id,
    }
    ));
    const menuValues = menu.map(option => option.value);
    setMenu(menu);
    setMenu2(menuValues);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const getMenu = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/menu`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setMenuAll(response.data);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};

const options = menuall.map(mn => ({
  label: `${mn.id} - ${mn.nama_menu}`,
  value: mn.id,
}));

const handleMenuSelect = (selectedOptions) => {
  const selectedValues = selectedOptions.map(option => option.value);
  setMenu(selectedOptions);
  setMenu2(selectedValues);
}



const saveUserExt = async (e) => {
  dispatch(getMe());
  e.preventDefault();
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

if(action == 'add'){
  if (role == ''){
    Swal.fire(
      'Gagal!',
      'Role user wajib di isi!',
      'error'
  )
  }else if(role == 'des' && norek == ''){
  Swal.fire(
    'Gagal!',
    'Nomor Rekening wajib di isi!',
    'error'
)
}else if (kodeinstansi == ''){
  Swal.fire(
    'Gagal!',
    'Kode Instansi wajib di isi!',
    'error'
)
}else if(namaext == ''){
  Swal.fire(
    'Gagal!',
    'Nama Instansi wajib di isi!',
    'error'
)
}else if (emailext == ''){
  Swal.fire(
    'Gagal!',
    'Email wajib di isi!',
    'error'
)
}else if (!validator.isEmail(emailext)){
  Swal.fire(
    'Gagal!',
    'Invalid format email!',
    'error'
)
}else if (password == ''){
  Swal.fire(
    'Gagal!',
    'Password wajib di isi!',
    'error'
)
}else if (confPassword == ''){
  Swal.fire(
    'Gagal!',
    'Konfirmasi password wajib di isi!',
    'error'
)
}else if (!validator.isStrongPassword(password, { minLength: 6, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
  Swal.fire(
    'Gagal!',
    'Panjang minimum password 6 karakter, harus mengandung huruf besar, huruf kecil, angka, dan simbol!',
    'error'
)
}else if (password !== confPassword){
  Swal.fire(
    'Gagal!',
    'Password dan konfirmasi password tidak sama!',
    'error'
)
}else if (status == ''){
  Swal.fire(
    'Gagal!',
    'Status user wajib di isi!',
    'error'
)
}else if (menu2 == ''){
  Swal.fire(
    'Gagal!',
    'Menu wajib di isi!',
    'error'
)
}else{
  try {
    await axiosJWT.post(`${env.API_URL}/usersext`, {
      kodeinstansi: kodeinstansi,
      norek: norek,
      namaext: namaext,
      alamat: alamat,
      kecamatan: kecamatan,
      emailext: emailext,
      role: role,
      status: status,
      password: password,
      confPassword: confPassword,
      kodekantor: kodekantor,
      kodekantorkas: kodekantorkas,
      islogin: islogin,
      menu: menu2.toString(),
    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })
} catch (error) {
  Swal.fire(
    'Gagal!',
    error.response.data.msg,
    'error'
)
}
}
}else if(action == 'edit'){

  if(namaext == ''){
    Swal.fire(
      'Gagal!',
      'Nama Instansi wajib di isi!',
      'error'
  )
  }else if (role != 'kab' && kecamatan == ''){
    Swal.fire(
      'Gagal!',
      'Kecamatan wajib di isi!',
      'error'
  )
  }else if (emailext == ''){
    Swal.fire(
      'Gagal!',
      'Email wajib di isi!',
      'error'
  )
  }else if (!validator.isEmail(emailext)){
    Swal.fire(
      'Gagal!',
      'Invalid format email!',
      'error'
  )
  }else if (password != '' && !validator.isStrongPassword(password, { minLength: 6, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
    Swal.fire(
      'Gagal!',
      'Panjang minimum password 6 karakter, harus mengandung huruf besar, huruf kecil, angka, dan simbol!',
      'error'
  )
  }else if ((password != '') && password !== confPassword){
    Swal.fire(
      'Gagal!',
      'Password dan konfirmasi password tidak sama!',
      'error'
  )
  }else if (menu2 == ''){
    Swal.fire(
      'Gagal!',
      'Menu wajib di isi!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/usersext/${iduserext}`, {
        namaext: namaext,
        alamat: alamat,
        kecamatan: kecamatan,
        role: role,
        status: status,
        password: password,
        confPassword: confPassword,
        kodekantor: kodekantor,
        kodekantorkas: kodekantorkas,
        islogin: islogin,
        menu: menu2.toString(),
  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
} // END IF ADD ATAU EDIT

getUserExt()
};

  const deleteUserExt = async (apikey) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Konfirmasi!',
        text: "Anda akan menghapus data ini?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axios.delete(`${env.API_URL}/usersext/${apikey}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getUserExt();
    })
}


const getKantor = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/kantorcabang`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKantorcbg(response.data);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};

const getKantorKas = async () => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/kantor`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKantorkas(response.data);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const handleBlurRole = async () => {
  if(role != 'des'){
    setDisabledNorek(true);
    setNorek('');
    setKodeInstansi('');
    setNamaExt('');
    setKecamatan('');
    setKodeKantor('');
    setKodeKantorKas('');
  }else{
    setDisabledNorek(false);
  }

};


const handleBlurNorek = async () => {
  if(role == 'des'){
    // try {
    //   const response = await axiosJWT.get(`${env.API_URL}/getsaldo/${norek}`,{
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //   }
    //   });
    //   setNamaExt(response.data[0].NAMA_NASABAH);
    //   setAlamat(response.data[0].ALAMAT);
    //   setKecamatan(response.data[0].KECAMATAN);
    //   setKodeKantor(response.data[0].KODE_KANTOR);
    //   setKodeKantorKas(response.data[0].kode_kantor_kas);
    // } catch (error) {
    //   if (error.response) {
    //     setMsg(error.response.data.msg);
    //   }
    // }

  try {
      const response = await axiosJWT.get(`${env.API_URL}/reffdesabynorek/${norek}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKodeInstansi(response.data.KODE_DESA);
      setNamaExt(response.data.DESA);
      setKecamatan(response.data.KECAMATAN);
      setKodeKantor(response.data.KODE_KANTOR);
      setKodeKantorKas(response.data.KODE_KANTOR_KAS);

    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  }

};

const handleBlurKodeInstansi = async () => {
  if(role == 'kec'){
  try {
      const response = await axiosJWT.get(`${env.API_URL}/reffkecbykodeinstansi/${kodeinstansi}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setNamaExt(response.data.KECAMATAN);
      setAlamat(response.data.ALAMAT);
      setKecamatan(response.data.KECAMATAN);
      setKodeKantor(response.data.KODE_KANTOR);
      setKodeKantorKas(response.data.KODE_KANTOR_KAS);

    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  }
};


return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>List Users Eksternal</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal('add'); resetForm()}} className="button is-small is-info">
      Tambah Data
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredUserext}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />


<div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form User Eksternal</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">                   
                  
                    <div className="columns">

                    <div className="column is-half">
                        <label className="label is-small mb-0">Role</label>
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          onBlur={handleBlurRole}
                          disabled={disabledrole}
                        >
                          <option value="">-Pilih-</option>
                          <option value="des">Desa</option>
                          <option value="kec">Kecamatan</option>
                          <option value="kab">Kabupaten</option>
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">No Rekening</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={norek}
                          onChange={(e) => setNorek(e.target.value)}
                          onBlur={handleBlurNorek}
                          disabled={disablednorek}
                          placeholder="Nomor rekening desa"
                        />
                      </div>

                    </div>


                    <div className="columns">
                    <div className="column is-half">
                        <label className="label is-small mb-0">Kode Instansi</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={kodeinstansi}
                            onChange={(e) => setKodeInstansi(e.target.value)}
                            onBlur={handleBlurKodeInstansi}
                            placeholder="Kode instansi"
                            />
                      </div>


                      <div className="column is-half">
                        <label className="label is-small mb-0">Nama User Eksternal</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={namaext}
                            onChange={(e) => setNamaExt(e.target.value)}
                            placeholder="Nama user eksternal"
                            />
                      </div>

                    </div>



                  <div className="columns">

                    <div className="column is-half">
                      <label className="label is-small mb-0">Email</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={emailext}
                          onChange={(e) => setEmailExt(e.target.value)}
                          placeholder="Email"
                        />
                      </div>

                      <div className="column is-half">
                        <label className="label is-small mb-0">Kecamatan</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={kecamatan}
                            onChange={(e) => setKecamatan(e.target.value)}
                            placeholder="Kecamatan"
                            />
                      </div>

                    
                    </div>

                      
                    <div className="columns">
                      <div className="column is-full">
                        <label className="label is-small mb-0">Alamat</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={alamat}
                            onChange={(e) => setAlamat(e.target.value)}
                            placeholder="Alamat"
                            />
                      </div>
                    </div>


                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Kantor</label>
                        <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={kodekantor}
                          onChange={(e) => setKodeKantor(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                                {kantorcbg.map(ktr => (
                                  <option key={ktr.kode_kantor} value={ktr.kode_kantor}>
                                    {ktr.nama_kantor}
                                  </option>
                                ))}
                        </select>
                        </div>
                        </p>
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Kantor Kas</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={kodekantorkas}
                          onChange={(e) => setKodeKantorKas(e.target.value)}
                          >
                           <option value="">-- Select --</option>
                                {kantorkas.map(ktr => (
                                  <option key={ktr.kode_kantor_kas} value={ktr.kode_kantor_kas}>
                                    {ktr.nama_kantor}
                                  </option>
                                ))}
                        </select>
                        </div>
                        </p>
                        </div>
                        </p>
                      </div>

                    </div>


                    <div className="columns">
                      <div className="column is-half">
                        <label className="label is-small mb-0">Password</label>
                        <input
                            type="password"
                            className="input is-small"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            />
                      </div>

                      <div className="column is-half">
                      <label className="label is-small mb-0">Conf Password</label>
                      <input
                          type="password"
                          className="input is-small"
                          value={confPassword}
                          onChange={(e) => setConfPassword(e.target.value)}
                          placeholder="Konfirmasi Password"
                        />
                      </div>
                    </div>

                    <div className="columns">

                    <div className="column is-half">
                      <label className="label is-small mb-0">Status Login</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={islogin}
                          onChange={(e) => setIsLogin(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="1">Ya</option>
                          <option value="0">Tidak</option>
                        </select>
                        </div>
                        </p>
                      </div>


                    <div className="column is-half">
                      <label className="label is-small mb-0">Status Aktif</label>
                      <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="0">Aktif</option>
                          <option value="1">Blokir</option>
                        </select>
                        </div>
                        </p>
                      </div>

                      </div>

                      <div className="columns">
                      <div className="column is-full">
                        <Multiselect
                              options={options} 
                              selectedValues={menu} 
                              onSelect={handleMenuSelect} 
                              onRemove={handleMenuSelect} 
                              displayValue="label" 
                              placeholder="Select Menu"
                              />
                      </div>
                      </div>

            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveUserExt(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default UserExtList;
