import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoKeyOutline, IoLogOut, IoPersonCircle, IoKey} from "react-icons/io5";
import { LogOut, reset } from "../features/authSlice";
import Swal from 'sweetalert2';
import axios from "axios";
import env from "react-dotenv";
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import { insertLogUser } from '../app/GlobalVar';
import { getMe } from "../features/authSlice";
import { getLocalIP, formatTanggal, getTglServer} from '../app/GlobalVar';
import validator from 'validator';


const Navbar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [msg, setMsg] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [milliseconds, setMilliseconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [jam, setJam] = useState(false);
  
  const [logo, setLogo] = useState('')
  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const resetForm = () => {
    setOldPassword("")
    setNewPassword("")
    setConfPassword("")
  }

  useEffect(() => { 
    fetchDataTgl();
    getLogoNav(); 
    // getMaxAge();

  
  }, []);


  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))


const getLogoNav = async () => {
  const response = await axiosJWT.get(`${env.API_URL}/instansi`,{
    headers: {
        Authorization: `Bearer ${token}`
    }
  });
  
  setLogo(`${env.API_URL}/images/${response.data[0].instansi_logo}`);
};

const getMaxAge = async () => {

  const response = await axios.get(`${env.API_URL}/getmaxage`, {
    responseType: 'json',
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

  console.log(response.data)

}



  const logout = () => {
    Swal.fire({
      title: 'Logout',
      text: "Anda yakin akan log out?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Log Out'
  }).then(async(result) => {
      if (result.isConfirmed) {
          dispatch(LogOut());
          dispatch(reset());
          navigate('/')
          localStorage.clear()
          sessionStorage.clear()
          insertLogUser(user && user.kode_user_ibs, 'Logout', 'users')
      }
  })
  };

//   const password_validate = (password) => {
//     var re = {
//         capital: /(?=.*[A-Z])/,
//         length: /(?=.{6,40}$)/,
//         specialChar: /[ -\/:-@\[-\`{-~]/,
//         digit: /(?=.*[0-9])/,
//     };
//     return (
//         re.capital.test(password) &&
//         re.length.test(password) &&
//         re.specialChar.test(password) &&
//         re.digit.test(password)
//     );
//  };



 const fetchDataTgl = async () => {
  const tanggal = await formatTanggal();   
  setJam(tanggal.jam_trans); 
 };

  const savePassword = async (e) => {
      dispatch(getMe());
      e.preventDefault();

      if (oldpassword == '' ){
        Swal.fire(
          'Gagal!',
          'Password lama wajib di isi',
          'error'
        )
      }else if(newpassword == ''){
        Swal.fire(
          'Gagal!',
          'Password baru wajib di isi',
          'error'
        )
      }else if (!validator.isStrongPassword(newpassword, { minLength: 6, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
        Swal.fire(
          'Gagal!',
          'Panjang minimum password 6 karakter, harus mengandung huruf besar, huruf kecil, angka, dan simbol!',
          'error'
      )
      }else if(confpassword == ''){
        Swal.fire(
          'Gagal!',
          'Konfirmasi password wajib di isi',
          'error'
        )
      }else if(newpassword != confpassword){
        Swal.fire(
          'Gagal!',
          'Password baru tidak sama dengan konfirmasi password',
          'error'
        )
      }else{
      try {
        await axios.patch(`${env.API_URL}/changepassword/${user && user.uuid}`
        , {
          oldpassword: oldpassword,
          newpassword: newpassword,
        }
        ).then((response) => {
          Swal.fire(
              'Berhasil!',
              response.data.msg,
              'success'
          )
      })
      handleCloseModal()
      } catch (error) {
        if (error.response) {
          Swal.fire(
            'Gagal!',
            error.response.data.msg,
            'error'
          )
        }
      }
    }
    
  };


  return (  
    <div>
      <nav
        className="navbar is-fixed-top has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <NavLink to="/dashboard" className="navbar-item">
            <img src={logo} alt="logo" />
          </NavLink>
        <div
          className={`navbar-burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={isActive ? "true" : "false"}
          onClick={toggleDropdown}
        >
          <div style={{marginLeft:'15px', marginTop:'15px'}}>
                 <IoPersonCircle size={24}/>
         </div>
        </div>

        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable pr-6">
                  <a className="navbar-link ml-2" href="#">
                      {user && user.name}
                    </a>
                    <div className="navbar-dropdown pr-4">
                      <a className="navbar-item" onClick={() => {handleOpenModal();resetForm()}} href="#"><IoKeyOutline />  Change Password</a>
                      <a className="navbar-item" onClick={() => {logout()}} href="#"><IoLogOut />  Logout</a>
                    </div>
            </div>
          </div>    
        </div>

        <div className={`modal modal-fx-3dSign ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Change Password</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">

              <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password Lama</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={oldpassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            placeholder="Password Lama"
                          />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password Baru</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={newpassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Password Baru"
                          />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Conf Password</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={confpassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                            placeholder="Konfirmasi Password"
                          />
                        </p>
                     
                      </div>
                      </div>
                  </div>

            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {savePassword(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>

        
      </nav>

    </div>
  );
};

export default Navbar;
