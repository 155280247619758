import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoEllipsisVertical, IoSearch, IoDocumentAttach, IoDocumentAttachOutline, IoLockClosed, IoLockOpen, IoArrowUndo, IoCreate, IoEye } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";
import { NumericFormat } from 'react-number-format';
import { useDropzone } from 'react-dropzone';
import './FileUpload.css';
import LoadingFile from './LoadingFile';
import Loading from './Loading';
import { getLocalIP, formatTanggal, getTglServer} from '../app/GlobalVar';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Iframe from 'react-iframe';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';



const Tntlist = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [tnt, setTnt] = useState([]);
  const [filteredtnt, setFilteredTnt] = useState([])
  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()
  const [search, setSearch] = useState('')
  const [msg, setMsg] = useState("");
  const [useribs, setUserIbs] = useState('');
  const [activeRow, setActiveRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [ismodalopentnt, setIsModalOpenTnt] = useState(false);
  const [tglmulai, setTglMulai] = useState('');
  const [tglselesai, setTglSelesai] = useState('');
  const [tglsekarang, setTglSekarang] = useState('');
  const [kodekantor, setKodeKantor] = useState("");
  const [jenistnt, setJenisTnt] = useState('');
  const [userexecutor, setUserExecutor] = useState("");
  const [executor, setExecutor] = useState([]);
  const [idedit, setIdEdit] = useState("");

  const [norek, setNorek] = useState('');
  const [namanasabah, setNamaNasabah] = useState('');
  const [alamatnasabah, setAlamatNasabah] = useState('');

  const [showformtransfer, setShowFormTransfer] = useState(false);
  const [showformpajak, setShowFormPajak] = useState(false);
  const [showformbpjs, setShowFormBpjs] = useState(false);
  
  const [kodebankall, setKodeBankAll] = useState([]);
  const [kodebank, setKodeBank] = useState("");
  const [rekeningpenerima, setRekeningPenerima] = useState("");
  const [namapenerima, setNamaPenerima] = useState("");
  const [jumlahsetorantransfer, setJumlahSetoranTransfer] = useState("");

  const [perihaltransfer, setPerihalTransfer] = useState('');
  const [catatantransfer, setCatatanTransfer] = useState('');

  const [idbilling, setIdBilling] = useState('');
  const [jenispajak, setJenisPajak] = useState('');
  const [masapajak, setMasaPajak] = useState('');
  const [jumlahsetoranpajak, setJumlahSetoranPajak] = useState("");
  const [catatanpajak, setCatatanPajak] = useState('');

  const [virtualaccount, setVirtualAccount] = useState('');
  const [jumlahsetoranbpjs, setJumlahSetoranBpjs] = useState("");
  const [catatanbpjs, setCatatanBpjs] = useState('');

  const [kodekantorkas, setKodeKantorKas] = useState('');
  const [kodekakas, setKodeKaKas] = useState("");
  const [kodekakasall, setKodeKaKasAll] = useState([]);
  const [createdat, setCreatedAt] = useState('');
  const [disabledjenistnt, setDisabledJenisTnt] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesdb, setSelectedFilesDb] = useState([]);
  const [selectedFilesdbdel, setSelectedFilesDbDel] = useState([]);
  const [mergedFiles, setMergedFiles] = useState([]);

  const [resbanksumberall, setResBankSumberAll] = useState([]);
  const [resbanksumber, setResBankSumber] = useState('');
  const [resnamabanksumber, setResNamaBankSumber] = useState("");
  const [resnorek, setResNorek] = useState('');
  const [resnoalt, setResNoAlt] = useState('');
  const [resjenisaba, setResJenisAba] = useState('');

  const [uploading, setUploading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [resselectedFiles, setResSelectedFiles] = useState([]);
  const [resselectedFilesdb, setResSelectedFilesDb] = useState([]);
  const [resselectedFilesdbdel, setResSelectedFilesDbDel] = useState([]);
  const [resmergedFiles, setResMergedFiles] = useState([]);
  const [resuploading, setResUploading] = useState(false);

  const [istnt, setIsTnt] = useState('');
  const [role, setRole] = useState('');
  const [usercreated, setUserCreated] = useState('');
  const [userprocessed, setUserProcessed] = useState('');
  const [lock, setLock] = useState('');
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState('');

  const [nominal, setNominal] = useState('');
  const [uraian, setUraian] = useState('');
  const [jam, setJam] = useState('');
  const [tanggalformatindo, setTanggalFormatIndo] = useState('');

  const [isModalOpentranstnt, setIsModalOpenTransTnt] = useState(false);
  const sliderRefRequest = useRef(null);
  const sliderRefRespon = useRef(null);
  const [selectedImageIndexRequest, setSelectedImageIndexRequest] = useState(null);
  const [selectedImageIndexRespon, setSelectedImageIndexRespon] = useState(null);
  const [isModalImageOpenRequest, setIsModalImageOpenRequest] = useState(false);
  const [isModalImageOpenRespon, setIsModalImageOpenRespon] = useState(false);
  const [isModalOpenPrintLaporan, setIsModalOpenPrintLaporan] = useState(false);
  const [isModalOpenCetakLaporan, setisModalOpenCetakLaporan] = useState(false);
  const [isModalOpenFile, setIsModalOpenFile] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const [nameFile, setNameFile] = useState("");
  const [extFile, setExtFile] = useState("");
  const [urlbyid, setUrlById] = useState('');

  const [tglmulailap, setTglMulaiLap] = useState('');
  const [tglselesailap, setTglSelesaiLap] = useState('');
  const dateInputRefLapMulai = useRef(null);
  const dateInputRefLapSelesai = useRef(null);
  const datePeriodeMulai = useRef(null);
  const datePeriodeSelesai = useRef(null);
  const dateFormat = 'd/m/Y';


  useEffect(() => {

    if (sliderRefRequest.current) {
      sliderRefRequest.current.slickGoTo(selectedImageIndexRequest);
    }

    
    if (sliderRefRespon.current) {
      sliderRefRespon.current.slickGoTo(selectedImageIndexRespon);
    }

  }, [selectedImageIndexRequest, selectedImageIndexRespon]);


  
  const sliderSettingsRequest = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: selectedImageIndexRequest,
    afterChange: (currentSlide) => {
      setSelectedImageIndexRequest(currentSlide);
    },

    };

    const sliderSettingsRespon = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: selectedImageIndexRespon,
      afterChange: (currentSlide) => {
        setSelectedImageIndexRespon(currentSlide);
      },
  
      };
  

  const handleOpenModalFile = (url, name, ext) => {
        setIsModalOpenFile(true);
        setUrlFile(url);
        setNameFile(name);
        setExtFile(ext);
      };
    
  const handleCloseModalFile = () => {
        setIsModalOpenFile(false);
        setUrlFile("");
        setNameFile("");
        setExtFile("");
      };


  const handleOpenModalTransTnt = (tntid) => {
    setIsModalOpenTransTnt(true);
    getTntById(tntid, 'trans');
  };

  const handleCloseModalTransTnt = () => {
    setIsModalOpenTransTnt(false);
    resetForm();
  };

  
  const handleOpenModalPrintLaporan = () => {
    setIsModalOpenPrintLaporan(true);
   
    flatpickr(dateInputRefLapMulai.current, {
      altInput: true,
      altFormat: dateFormat,
      dateFormat: 'Y-m-d', 
      disableMobile: "true",
      onChange: function(selectedDates, dateStr) {
        setTglMulaiLap(dateStr)     
      },
    });

    flatpickr(dateInputRefLapSelesai.current, {
      altInput: true,
      altFormat: dateFormat,
      dateFormat: 'Y-m-d', 
      disableMobile: "true",
      onChange: function(selectedDates, dateStr) {
        setTglSelesaiLap(dateStr)
      },
    });

  };
  
  const handleCloseModalPrintLaporan = () => {
    setIsModalOpenPrintLaporan(false);
    setTglMulaiLap("")
    setTglSelesaiLap("")
    setKodeKaKas("")
    dateInputRefLapMulai.current._flatpickr.clear();
    dateInputRefLapSelesai.current._flatpickr.clear();
  };

  const handleOpenModalCetakLaporan = () => {
   if(role == ''){
     Swal.fire(  
       'Gagal!',
       'Role user masih kosong!',
       'error'
     )
   }else if(tglmulailap == ''){
     Swal.fire(  
       'Gagal!',
       'Periode tanggal mulai belum di isi!',
       'error'
     )
   }else if(tglselesailap == ''){
     Swal.fire(  
       'Gagal!',
       'Periode tanggal selesai belum di isi!',
       'error'
     )
   }else if(tglmulailap > tglselesailap){
     Swal.fire(  
       'Gagal!',
       'Periode tanggal mulai tidak boleh lebih besar dari tanggal selesai!',
       'error'
     )
   }else{

     setisModalOpenCetakLaporan(true);
     setUrlById('/printlaporantnt/'+kodekakas+'/'+tglmulailap+'/'+tglselesailap);
  
   }

   };
 
const handleCloseModalCetakLaporan = () => {
     setisModalOpenCetakLaporan(false);   
   };


  const onDropReq = (acceptedFiles) => {
    setUploading(true);
  
    console.log(selectedFiles);
    const oversizedFiles = [];
    const validExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];
    const newFilesWithPreviews = acceptedFiles
      .filter((file) => {
        const size = file.size; // Ukuran file dalam byte
        const ext = file.name.split('.').pop().toLowerCase(); // Ekstensi file dalam huruf kecil
  
        if (size > 4 * 1024 * 1024) {
          oversizedFiles.push(file);
          return false;
        }
  
        if (!validExtensions.includes(`.${ext}`)) {
          Swal.fire({
            icon: 'error',
            title: 'Ekstensi file tidak valid',
            text: `File dengan ekstensi ${ext} tidak diperbolehkan.`,
          });
          return false;
        }
  
        return true;
      })
      .map((file) => {
        const objectURL = URL.createObjectURL(file);
        const type = (file.type);
        const ext = type.split('/');
        return { file, src: objectURL, ext: '.'+ext[1] };
      });
  
    if (oversizedFiles.length > 0) {
      const oversizedFileNames = oversizedFiles.map((file) => file.name).join(', ');
  
      Swal.fire({
        icon: 'error',
        title: 'Ukuran file terlalu besar',
        html: `File-file berikut melebihi 4 MB: <br>${oversizedFileNames}`,
      });
    }
  
    new Promise((resolve) => {
      setTimeout(resolve, 1500);
    }).then(() => {
      const updatedFiles = [...selectedFiles, ...newFilesWithPreviews];
      setSelectedFiles(updatedFiles);
      setUploading(false);
    });

  };


  const removeFile = (index) => {
    const fileToRemove = mergedFiles[index];

    if (fileToRemove.file.type === 'server') {
      const updatedFilesDb = selectedFilesdb.filter(
        (file) => file.file.name !== fileToRemove.file.name
      );
      setSelectedFilesDb(updatedFilesDb);
      
      setSelectedFilesDbDel(prevDeletedFiles => [...prevDeletedFiles, fileToRemove]);
    } else {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index - selectedFilesdb.length, 1);
      setSelectedFiles(updatedFiles);
      
    }
  };

  const onDropRes = (acceptedFiles) => {
    setResUploading(true);
  
    const oversizedFiles = [];
    const validExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];
    const newFilesWithPreviews = acceptedFiles
      .filter((file) => {
        const size = file.size; // Ukuran file dalam byte
        const ext = file.name.split('.').pop().toLowerCase(); // Ekstensi file dalam huruf kecil
  
        if (size > 4 * 1024 * 1024) {
          oversizedFiles.push(file);
          return false;
        }
  
        if (!validExtensions.includes(`.${ext}`)) {
          Swal.fire({
            icon: 'error',
            title: 'Ekstensi file tidak valid',
            text: `File dengan ekstensi ${ext} tidak diperbolehkan.`,
          });
          return false;
        }
  
        return true;
      })
      .map((file) => {
        const objectURL = URL.createObjectURL(file);
        const type = (file.type);
        const ext = type.split('/');
        return { file, src: objectURL, ext: '.'+ext[1] };
      });
  
    if (oversizedFiles.length > 0) {
      const oversizedFileNames = oversizedFiles.map((file) => file.name).join(', ');
  
      Swal.fire({
        icon: 'error',
        title: 'Ukuran file terlalu besar',
        html: `File-file berikut melebihi 4 MB: <br>${oversizedFileNames}`,
      });
    }
  
    new Promise((resolve) => {
      setTimeout(resolve, 1500);
    }).then(() => {
      const updatedFiles = [...resselectedFiles, ...newFilesWithPreviews];
      setResSelectedFiles(updatedFiles);
      setResUploading(false);
    });

  };


  const removeResFile = (index) => {
    const resfileToRemove = resmergedFiles[index];

    if (resfileToRemove.file.type === 'server') {
      const updatedResFilesDb = resselectedFilesdb.filter(
        (file) => file.file.name !== resfileToRemove.file.name
      );
      setResSelectedFilesDb(updatedResFilesDb);
      
      setResSelectedFilesDbDel(prevDeletedResFiles => [...prevDeletedResFiles, resfileToRemove]);
    } else {
      const updatedResFiles = [...resselectedFiles];
      updatedResFiles.splice(index - resselectedFilesdb.length, 1);
      setResSelectedFiles(updatedResFiles);
      
    }
  };


  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({ onDrop: onDropReq });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({ onDrop: onDropRes });


  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    const newMergedFiles = [...selectedFilesdb, ...selectedFiles];
    setMergedFiles(newMergedFiles);


    const resnewMergedFiles = [...resselectedFilesdb, ...resselectedFiles];
    setResMergedFiles(resnewMergedFiles);

  }, [selectedFilesdb, selectedFiles, resselectedFilesdb, resselectedFiles]);



  const handleOpenModalTnt = (key, islock) => {
   
    getKodeBank();
    getAba(user && user.kode_kantor);
    getUserExecutor('executor');
    setKodeKantor(user && user.kode_kantor);
    setKodeKantorKas(user && user.kode_kantor_kas);
    setUserIbs(user && user.kode_user_cbs);
    setIsTnt(user && user.is_tnt);
    setRole(user && user.role);
    setAction(key);
    if(key == 'add'){
      setDisabledJenisTnt(false)
      setIsModalOpenTnt(true)
    }else if(key == 'edit'){
      if(islock == 'lock'){
        Swal.fire(  
          'Gagal!',
          'Data sudah di lock!',
          'error'
      )
      setIsModalOpenTnt(false)
      }else{
        setDisabledJenisTnt(true)
        setIsModalOpenTnt(true)
      }
    }else if(key == 'respon'){
      
      if(islock == 'unlock'){
        Swal.fire(  
          'Gagal!',
          'Data belum di lock!',
          'error'
      )
        setIsModalOpenTnt(false)
      }else{
        setIsModalOpenTnt(true)
        setDisabledJenisTnt(true)
      }
      
    }else if(key == 'view'){
      setIsModalOpenTnt(true)
      setDisabledJenisTnt(true)      
    }
    
  };

  const handleCloseModalTnt = () => {
    setIsModalOpenTnt(false);
    resetForm();
  };

  
  const handleOpenModalImageRequest = (tntid, index) => {
    getTntById(tntid);
    setIsModalImageOpenRequest(true);
    setSelectedImageIndexRequest(index);
  };

  const handleCloseModalImageRequest = () => {
    setIsModalImageOpenRequest(false);
    setSelectedImageIndexRequest(null);
  };


  const handleOpenModalImageRespon = (tntid, index) => {
    setIsModalImageOpenRespon(true);
    getTntById(tntid)
    setSelectedImageIndexRespon(index);
  };

  const handleCloseModalImageRespon = () => {
    setIsModalImageOpenRespon(false);
    setSelectedImageIndexRespon(null);
  };


  const handleJenisTnt = (e) => {
    const selectedValue = e.target.value;
    setJenisTnt(selectedValue);

    if (selectedValue === '1') {
      setShowFormTransfer(true);
      setShowFormPajak(false);
      setShowFormBpjs(false);
      resetFormPajak();
      resetFormBpjs();
    } else if (selectedValue === '2') {
      setShowFormTransfer(false);
      setShowFormPajak(true);
      setShowFormBpjs(false);
      setKodeBank('');
      resetFormTransfer();
      resetFormBpjs();
    } else if (selectedValue === '3') {
      setShowFormTransfer(false);
      setShowFormPajak(false);
      setShowFormBpjs(true);
      setKodeBank('');
      resetFormTransfer();
      resetFormPajak();
    } else {
      setShowFormTransfer(false);
      setShowFormPajak(false);
      setShowFormBpjs(false);
      setKodeBank('');
      resetFormTransfer();
      resetFormPajak();
      resetFormBpjs();
    }
  };

  const resetForm = () => {
    setIdEdit('');
    setJenisTnt('');
    setUserExecutor('');
    setNorek('');
    setNamaNasabah('');
    setAlamatNasabah('');
    resetFormTransfer();
    resetFormPajak();
    resetFormBpjs();
    setShowFormTransfer(false);
    setShowFormPajak(false);
    setShowFormBpjs(false);
    setSelectedFiles([]);
    setSelectedFilesDb([]);
    setSelectedFilesDbDel([]);
    setResBankSumberAll([]);
    setResBankSumber('');
    setResNamaBankSumber('');
    setResNorek('');
    setResNoAlt('');
    setActiveTab(0);
    setResSelectedFiles([]);
    setResSelectedFilesDb([]);
    setResSelectedFilesDbDel([]);
    setResMergedFiles([]);
    setNominal('');
    setUraian('');
  }

  const resetFormTransfer = () => {
    setKodeBank('');
    setRekeningPenerima('');
    setNamaPenerima('');
    setJumlahSetoranTransfer('');
    setPerihalTransfer('');
    setCatatanTransfer('');
  }

  const resetFormPajak = () => {
    setIdBilling('');
    setJenisPajak('');
    setMasaPajak('');
    setJumlahSetoranPajak('');
    setCatatanPajak('');
  }

  const resetFormBpjs = () => {
    setVirtualAccount('');
    setJumlahSetoranBpjs('');
    setCatatanBpjs('');
  }


  const settanggal = (e) => {
    var date = new Date(e); 
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)).toLocaleString('en-ID', { hour12:false } );
  }

  const getUserExecutor = async (id) => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/userexe/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setExecutor(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const getKodeBank = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/kodebank`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKodeBankAll(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  const getAba = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/aba`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      })

      setResBankSumberAll(response.data);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  const getTntById = async (tntid) => {

    dispatch(getMe());
    try {
      const response = await axiosJWT.get(`${env.API_URL}/tnt/${tntid}`,{
       responseType: 'json',
        headers: {
          Authorization: `Bearer ${token}`
      }
      });

      let filefromserver = '';
      if(response.data.formattedFilesReq != ''){
        filefromserver = response.data.formattedFilesReq;
      }else if(response.data.formattedFilesReqDesa != ''){
        filefromserver = response.data.formattedFilesReqDesa;
      }
      
      const filesFromServerReq = filefromserver.map((file) => ({
        file:{name: file.name,
              type: file.type},
        ext: file.ext,
        src: env.API_URL+file.src 
      }));


      const filesFromServerRes = response.data.formattedFilesRes.map((file) => ({
        file:{name: file.name,
              type: file.type},
        ext: file.ext,
        src: env.API_URL+file.src 
      }));

  
      setNominal(new Intl.NumberFormat("id-ID").format(response.data.response.jumlah_disetor));
      setUraian("Penarikan Tabungan OB dari Rekening "+response.data.response.norek_nasabah+" ke Rekening ABA "+response.data.response.norek_aba);

      
      setSelectedFilesDb(filesFromServerReq);
      setResSelectedFilesDb(filesFromServerRes);
      setIdEdit(response.data.response.tnt_id);
      setKodeKantorKas(response.data.response.sandi_kantor);
      setKodeKantor(response.data.response.sandi_cabang);
      setJenisTnt(response.data.response.jenis_tnt);
      setNamaNasabah(response.data.response.nama_nasabah);
      setNorek(response.data.response.norek_nasabah);
      setUserExecutor(response.data.response.user_processed);
      setAlamatNasabah(response.data.response.alamat_nasabah);
      setUserCreated(response.data.response.user_created);
      setUserProcessed(response.data.response.user_processed);
      setLock(response.data.response.is_lock);
      setResBankSumber(response.data.response.norek_aba)
      setResNamaBankSumber(response.data.response.nama_aba);
      setResNorek(response.data.response.norek_aba);
      setResNoAlt(response.data.response.no_alternatif_aba);
      setResJenisAba(response.data.response.jenis_aba);    

      if(response.data.response.jenis_tnt == '1'){
        setKodeBank(response.data.response.bank_tujuan);
        setRekeningPenerima(response.data.response.norek_tujuan);
        setNamaPenerima(response.data.response.nama_pemilik_rekening_tujuan);
        setJumlahSetoranTransfer(new Intl.NumberFormat("id-ID").format(response.data.response.jumlah_disetor));
        setPerihalTransfer(response.data.response.perihal);
        setCatatanTransfer(response.data.response.catatan);
        setShowFormTransfer(true);
        setShowFormPajak(false);
        setShowFormBpjs(false);
      }else if(response.data.response.jenis_tnt == '2'){
        setIdBilling(response.data.response.id_biling);
        setJenisPajak(response.data.response.jenis_pajak);
        setMasaPajak(response.data.response.masa_pajak);
        setJumlahSetoranPajak(new Intl.NumberFormat("id-ID").format(response.data.response.jumlah_disetor));
        setCatatanPajak(response.data.response.catatan);
        setShowFormTransfer(false);
        setShowFormPajak(true);
        setShowFormBpjs(false);
      }else if(response.data.response.jenis_tnt == '3'){
        setVirtualAccount(response.data.response.nomor_virtual_account);
        setJumlahSetoranBpjs(new Intl.NumberFormat("id-ID").format(response.data.response.jumlah_disetor));
        setCatatanBpjs(response.data.response.catatan);
        setShowFormTransfer(false);
        setShowFormPajak(false);
        setShowFormBpjs(true);
      }


 

    
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const getKantorKas = async () => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/kantorkas`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setKodeKaKasAll(response.data);
      // console.log(response.data)
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  

  
  const handleBlurNorekAba = async () => {
    try {
        const response = await axiosJWT.get(`${env.API_URL}/getaba/${resbanksumber}`,{
          headers: {
            Authorization: `Bearer ${token}`
        }
        });
        
          setResNamaBankSumber(response.data.nama_aba);
          setResNorek(response.data.norek_aba);
          setResNoAlt(response.data.no_alternatif);    
          setResJenisAba(response.data.jenis_aba);          
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
        setResNamaBankSumber('');
        setResNorek('');
        setResNoAlt('');
        setResJenisAba('');
      }
  
  };
  


      const fetchDataTgl = async () => {
        const tanggal = await formatTanggal();
        setTanggalFormatIndo(tanggal.tanggalformatindo);
        setTglSekarang(tanggal.tgl_trans);   
        setTglMulai(tanggal.tgl_trans); 
        setTglSelesai(tanggal.tgl_trans);    
        setCreatedAt(tanggal.tgl_trans+' '+tanggal.jam_trans);     
        setJam(tanggal.jam_trans); 
  
        flatpickr(datePeriodeMulai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d',
          defaultDate: tanggal.tgl_trans,
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglMulai(dateStr);
          },
        });
    
        flatpickr(datePeriodeSelesai.current, {
          altInput: true,
          altFormat: dateFormat,
          dateFormat: 'Y-m-d',
          defaultDate: tanggal.tgl_trans,
          disableMobile: "true",
          onChange: function(selectedDates, dateStr) {
            setTglSelesai(dateStr);
          },
        });
  
        };
  
  
  const KonfirmTrans = async () => {  
        Swal.fire({
          title: 'Konfirmasi!',
          text: " Anda yakin akan melakukan konfirmasi transaksi?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Konfirmasi!'
      }).then(async (result) => {
        if (result.isConfirmed) {
      
          await axiosJWT.patch(`${env.API_URL}/konfirmtranstnt/${idedit}`,  {
            statustrans:'1',
            userfinished : useribs,
            finishedat : createdat,
            tgltrans : tglsekarang,
            jamtrans : jam
          },{
            headers: {
              Authorization: `Bearer ${token}`
          }
          })

          handleCloseModalTransTnt();
          getTnt(user && user.kode_user_cbs, tglsekarang, tglsekarang);
      
          }
          
        })
  
  };



useEffect(() => {
    const result = tnt.filter((tnt) => {
        return tnt.tnt_id.toLowerCase().match(search.toLowerCase())
    })
    setFilteredTnt(result)
 }, [search])

axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Tanggal',
        selector: row => row.tgl_permohonan,
        sortable: true,
        width: "100px"
    },
    {
        name: 'Kantor',
        selector: row => row.nama_kantor,
        sortable: true,
        width: "150px",
        cell: row => (
          <div title={row.nama_kantor}>
            {row.nama_kantor}
          </div>
        )
    },
    {
        name: 'Jenis Transaksi',
        selector: row => row.jenis_tnt,
        sortable: true,
        cell: row => {
          let jenistnttext = '';
          if (row.jenis_tnt === '1') {
            jenistnttext = 'Transfer';
          } else if (row.jenis_tnt === '2') {
            jenistnttext = 'Pajak';
          } else if (row.jenis_tnt === '3') {
            jenistnttext = 'BPJS';
          }
          return <span>{jenistnttext}</span>;
        },
        width: "100px"
    },
    {
        name: 'Nama Nasabah',
        selector: row => row.nama_nasabah,
        sortable: true,
        cell: (row) => <div title={`ID ${row.tnt_id},\nNomor Rekening ${row.norek_nasabah},\nBank Tujuan ${row.nama_bank},\nNomor Rekening Bank Tujuan ${row.norek_tujuan}.`}>{row.nama_nasabah}</div>,
        width: "200px",
    },
    {
        name: 'Requester',
        selector: row => row.pemohon,
        sortable: true,
        width: "100px"
    },
    {
        name: 'Executor',
        selector: row => row.pemproses,
        sortable: true,
        width: "110px"
    },
    {
        name: 'Jumlah Disetor',
        selector: row => new Intl.NumberFormat("id-ID").format(row.jumlah_disetor),
        sortable: true,
        width: "130px"
    },
    {
        name: 'Status',
        selector: row => {
          if (row.status == 'Pending') {
            return (
              <div>
                <span           
                  onClick={() => {
                    Swal.fire(
                      'Warning!',
                      'Data belum di respon oleh Admin TNT!',
                      'warning'
                    );
                  }}               
                className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Belum di proses"><b>Pending</b></span>
              </div>
            );
          }else if(row.status == 'Proses'){
            return (
              <div>
                <span
                onClick={() => {handleOpenModalTransTnt(row.tnt_id, row.norek_nasabah)}}          
                className="is-small mr-1" style={{ color: '#bf9000', cursor: 'pointer'}} title="Dalam proses"><b>Proses</b></span>

              </div>
            );
          }else if(row.status == 'Finish'){
            return (
              <div>
                <span
                  onClick={() => {
                    Swal.fire(
                      'Berhasil!',
                      'Data sudah ditransaksikan',
                      'success'
                    );
                  }}                       
                className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Selesai"><b>Finish</b></span>
              </div>
            );
          }
 
        },
        sortable: true,
        width: "90px"
    },
    {
        name: 'File Request',
        selector: row => {
          if (row.filerequest || row.filerequestdesa) {
            let fileNames = '';
            if(row.filerequest){
              fileNames = row.filerequest.split('|');
            }else{
              fileNames = row.filerequestdesa.split('|');
            }
            
            return (
                <ul>
                    {fileNames.map((fileName, index) => (
                       <li key={index}>

                      <a onClick={() => {handleOpenModalImageRequest(row.tnt_id, index)}}  >
                           <IoDocumentAttach /> &nbsp;{fileName}
                      </a>
                   </li>
                    ))}
                </ul>
            );
          }
          return 'Tidak Ada File';
        },
        sortable: true,
        width: "210px"

    },
    {
        name: 'File Respon',
        selector: row => {
          if (row.filerespon) {
            const fileNames = row.filerespon.split('|'); // Memisahkan data menggunakan '|'
            return (
                <ul>
                    {fileNames.map((fileName, index) => (
                       <li key={index}>

                       <a onClick={() => {handleOpenModalImageRespon(row.tnt_id, index)}}  >
                           <IoDocumentAttach /> &nbsp;{fileName}
                       </a>

                   </li>
                    ))}
                </ul>
            );
          }
          return 'Tidak Ada File';
        },
        sortable: true,
        width: "210px"
    },
    {
        name: 'Is Lock',  
        sortable: false,
        width: "70px",
        selector: row => row.is_lock,
        cell: (row) => {
          if (row.is_lock === 'unlock') {
            return (
              <div>
                 <span
                  onClick={() => {islock(row.tnt_id, 'lock', row.user_processed, row.status)}}            
                  className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Data belum di lock"><IoLockOpen size={20} /></span>
              </div>
            );
          } else if (row.is_lock === 'lock') {
            return (
              <div>
                <span           
                onClick={() => {islock(row.tnt_id, 'unlock', row.user_processed, row.status)}}
                className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Data sudah di lock"><IoLockClosed size={20} /></span> 
              </div>
            );
          } else {
            return null; 
          }
        }
    },

    {
        name: 'Action',
        sortable: false,
        selector: row => row.tnt_id,

        cell: (row) => {
          if (istnt === 'executor' && role != 'admin') {
            return (
            <div>
                <span
                onClick={() => {setIdEdit(row.tnt_id);getTntById(row.tnt_id);handleOpenModalTnt('respon', row.is_lock);resetForm();}}       
                className="is-small mr-1" style={{ color: '#bf9000', cursor: 'pointer'}} title="Respon"><IoArrowUndo size={20} /></span>
                
            </div>
            );

        } else if (istnt === 'requester' && role != 'admin') {
          if(row.is_lock != 'lock'){
            return (
            <div>
               <span
                onClick={() => {setIdEdit(row.tnt_id);getTntById(row.tnt_id);handleOpenModalTnt('edit', row.is_lock);resetForm();}}           
                className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Edit data"><IoCreate size={20} /></span>
                <span           
                onClick={() => {deleteTnt(row.tnt_id);}}
                className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Hapus data"><IoTrash size={20} /></span>
          </div>
            );
          }else{
            return (
              <div>
                 <span
                  onClick={() => {setIdEdit(row.tnt_id);getTntById(row.tnt_id);handleOpenModalTnt('edit', row.is_lock);resetForm();}}           
                  className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Edit data"><IoCreate size={20} /></span>
                  <span           
                  onClick={() => { 
                    Swal.fire(  
                      'Gagal!',
                      'Data sudah di lock, tidak bisa dihapus!',
                      'error'
                    );}}              
                  className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Hapus data"><IoTrash size={20} /></span>
            </div>
              );
          }
          } else if (istnt === 'controller' && role != 'admin') {
            return (
              <div>
              <span
              onClick={() => {setIdEdit(row.tnt_id);getTntById(row.tnt_id);handleOpenModalTnt('view');resetForm();}}      
              className="is-small mr-1" style={{ color: '#00d1b2', cursor: 'pointer'}} title="Lihat data"><IoEye size={20} /></span>
              </div>
            );
          } else if (role === 'admin') {
            return (
            <div>

            <span
            onClick={() => {setIdEdit(row.tnt_id);getTntById(row.tnt_id);handleOpenModalTnt('respon');resetForm();}}        
            className="is-small mr-1" style={{ color: '#bf9000', cursor: 'pointer'}} title="Respon"><IoArrowUndo size={20} /></span>
            <span
            onClick={() => {setIdEdit(row.tnt_id);getTntById(row.tnt_id);handleOpenModalTnt('edit');resetForm();}}            
            className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}} title="Edit data"><IoCreate size={20} /></span>
            <span           
            onClick={() => {deleteTnt(row.tnt_id);}}
            className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}} title="Hapus data"><IoTrash size={20} /></span>
          </div>
            );
          }  else {
            return null; 
          }
        },
        width: "180px"
    }
  ];

  useEffect(() => {
  
    fetchDataTgl(); 
    setUserIbs(user && user.kode_user_cbs);
    getTnt(user && user.kode_user_cbs, tglsekarang, tglsekarang);
    setKodeKantor(user && user.kode_kantor);
    setKodeKantorKas(user && user.kode_kantor_kas);
    setIsTnt(user && user.is_tnt);
    setRole(user && user.role);
    getKantorKas();

  }, [user && user.kode_user_cbs, tglsekarang, user && user.kode_kantor, user && user.kode_kantor_kas, user && user.is_tnt, user && user.role]);


  const getTnt = async (id_user, tgl1, tgl2) => {

    const response = await axiosJWT.get(`${env.API_URL}/tnt/${id_user}/${tgl1}/${tgl2}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setTnt(response.data)
    setFilteredTnt(response.data)
  }


const islock = async (tntid, lock, userprocess, status) => {

if((useribs == userprocess && status != 'Finish')  || role == 'admin' ){
  Swal.fire({
    title: 'Konfirmasi!',
    text: "Anda akan melakukan "+lock+" data ini?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, '+lock+'!'
}).then(async (result) => {
  if (result.isConfirmed) {

    await axiosJWT.patch(`${env.API_URL}/locktnt/${tntid}`,{
      lock: lock
    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    getTnt(user && user.kode_user_cbs, tglsekarang, tglsekarang);
    }
    
  })
}else if(useribs == userprocess && role != 'admin' && status == 'Finish'){
  Swal.fire(
    'Error!',
    'Status trans sudah selesai, anda tidak bisa melakukan unlock data!',
    'error'
  );
}else{ 
// if(useribs != userprocess || role != 'admin'){
    Swal.fire(
      'Error!',
      'Anda tidak berhak melakukan '+lock+' data ini!',
      'error'
    );
  }

  getTnt(user && user.kode_user_cbs, tglsekarang, tglsekarang);
   
  }


const getTntFilter = async (e) => {
    e.preventDefault();
        var id_user = user && user.kode_user_cbs;
        var tglmulaif = settanggal(tglmulai);
        var tglselesaif = settanggal(tglselesai);
    const response = await axiosJWT.get(`${env.API_URL}/tnt/${id_user}/${tglmulaif}/${tglselesaif}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setTnt(response.data)
    setFilteredTnt(response.data)
}


const saveTnt = async (e) => {
  dispatch(getMe());
  e.preventDefault();

var setorantransfer = jumlahsetorantransfer.replace(/([.])/g, '').replace(',', '.');
var setoranpajak    = jumlahsetoranpajak.replace(/([.])/g, '').replace(',', '.');
var setoranbpjs     = jumlahsetoranbpjs.replace(/[.]/g,"").replace(',', '.');

if(kodekantor == ''){
  Swal.fire(
    'Gagal!',
    'Kode kantor wajib di isi!',
    'error'
)
}else if (tanggalformatindo == ''){
  Swal.fire(
    'Gagal!',
    'Tanggal permohonan wajib di isi!',
    'error'
)
}else if (jenistnt == ''){
  Swal.fire(
    'Gagal!',
    'Jenis transaksi wajib di isi!',
    'error'
)
}else if (userexecutor == ''){
  Swal.fire(
    'Gagal!',
    'Admin TNT belum di pilih!',
    'error'
)
}else if (norek == ''){
  Swal.fire(
    'Gagal!',
    'Nomor rekening wajib di isi!',
    'error'
)
}else if (namanasabah == ''){
  Swal.fire(
    'Gagal!',
    'Nama nasabah wajib di isi!',
    'error'
)
}
else if (action == 'add' && selectedFiles == ''){
  Swal.fire(
    'Gagal!',
    'Belum melampirkan file request!',
    'error'
)
}
else if(jenistnt == '1' && kodebank == ''){
  Swal.fire(
    'Gagal!',
    'Kode bank wajib di isi!',
    'error'
)
}
else if(jenistnt == '1' && rekeningpenerima == ''){
  Swal.fire(
    'Gagal!',
    'Nomor rekening penerima wajib di isi!',
    'error'
)
}
else if(jenistnt == '1' && namapenerima == ''){
  Swal.fire(
    'Gagal!',
    'Nama penerima wajib di isi!',
    'error'
)
}
else if(jenistnt == '1' && jumlahsetorantransfer == ''){
  Swal.fire(
    'Gagal!',
    'Nominal transfer wajib di isi!',
    'error'
)
}
else if(jenistnt == '2' && idbilling == ''){
  Swal.fire(
    'Gagal!',
    'Id billing wajib di isi!',
    'error'
)
}
else if(jenistnt == '2' && jenispajak == ''){
  Swal.fire(
    'Gagal!',
    'Jenis pajak wajib di isi!',
    'error'
)
}
else if(jenistnt == '2' && masapajak == ''){
  Swal.fire(
    'Gagal!',
    'Masa pajak wajib di isi!',
    'error'
)
}
else if(jenistnt == '2' && jumlahsetoranpajak == ''){
  Swal.fire(
    'Gagal!',
    'Jumlah nominal pajak wajib di isi!',
    'error'
)
}
else if(jenistnt == '3' && virtualaccount == ''){
  Swal.fire(
    'Gagal!',
    'Nomor virtual account wajib di isi!',
    'error'
)
}
else if(jenistnt == '3' && jumlahsetoranbpjs == ''){
  Swal.fire(
    'Gagal!',
    'Jumlah nominal BPJS wajib di isi!',
    'error'
)
}
else if((action == 'respon') && (resbanksumber == '' || resbanksumber == null)){
  Swal.fire(
    'Gagal!',
    'Rekening ABA belum di pilih!',
    'error'
)
}
else if((action == 'respon') && (resnamabanksumber == '' || resnamabanksumber == null)){
  Swal.fire(
    'Gagal!',
    'Nama rekening ABA masih kosong!',
    'error'
)
}
else if(action == 'respon' && resselectedFiles == '' && resselectedFilesdb == ''){
  Swal.fire(
    'Gagal!',
    'Belum melampirkan file respon!',
    'error'
)
}else {

 
  if(action == 'add'){
    setLoading(true);

    const formData = new FormData();
    formData.append('kodekantorkas', kodekantorkas);
    formData.append('kodekantor', kodekantor);
    formData.append('jenistnt', jenistnt);
    formData.append('namanasabah', namanasabah);
    formData.append('norek', norek);
    formData.append('alamatnasabah', alamatnasabah);
    formData.append('kodebank', kodebank);
    formData.append('rekeningpenerima', rekeningpenerima);
    formData.append('namapenerima', namapenerima);
    formData.append('jumlahsetorantransfer', setorantransfer);
    formData.append('perihaltransfer', perihaltransfer);
    formData.append('catatantransfer', catatantransfer);
    formData.append('userexecutor', userexecutor);
    formData.append('useribs', useribs);
    formData.append('createdat', createdat);
    formData.append('lock', 'unlock');
    formData.append('tglsekarang', tglsekarang);
    formData.append('idbilling', idbilling);
    formData.append('jumlahsetoranpajak', setoranpajak);
    formData.append('jenispajak', jenispajak);
    formData.append('masapajak', masapajak);
    formData.append('catatanpajak', catatanpajak);
    formData.append('virtualaccount', virtualaccount);
    formData.append('jumlahsetoranbpjs', setoranbpjs);
    formData.append('catatanbpjs', catatanbpjs);

    selectedFiles.forEach((file) => {
      formData.append('files', file.file);
    });


    try {
      const response = await axiosJWT.post(`${env.API_URL}/tnt`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
      );
      setLoading(false);
      handleCloseModalTnt();
    } catch (error) {
      console.error('Error uploading files: ', error);
      Swal.fire(
        'Error!',
        'Terjadi kesalahan saat mengunggah data',
        'error'
      );
    }


}else if(action == 'edit'){
  setLoading(true);
      if(useribs == usercreated || role == 'admin'){
        const formData = new FormData();
        formData.append('jenistnt', jenistnt);
        formData.append('namanasabah', namanasabah);
        formData.append('norek', norek);
        formData.append('alamatnasabah', alamatnasabah);
        formData.append('kodebank', kodebank);
        formData.append('rekeningpenerima', rekeningpenerima);
        formData.append('namapenerima', namapenerima);
        formData.append('jumlahsetorantransfer', setorantransfer);
        formData.append('perihaltransfer', perihaltransfer);
        formData.append('catatantransfer', catatantransfer);
        formData.append('userexecutor', userexecutor);
        formData.append('useribs', useribs);
        formData.append('modifiedat', createdat);
        formData.append('idbilling', idbilling);
        formData.append('jumlahsetoranpajak', setoranpajak);
        formData.append('jenispajak', jenispajak);
        formData.append('masapajak', masapajak);;
        formData.append('catatanpajak', catatanpajak);
        formData.append('virtualaccount', virtualaccount);
        formData.append('jumlahsetoranbpjs', setoranbpjs);
        formData.append('catatanbpjs', catatanbpjs);
      
      
        selectedFiles.forEach((file) => { 
          formData.append('files', file.file);
        });
      
        selectedFilesdbdel.forEach((file) => {
          formData.append('fileexistdel', file.file.name); 
        });
      
      
        try {
          const response = await axiosJWT.patch(`${env.API_URL}/tnt/${idedit}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });
          Swal.fire(
            'Berhasil!',
            response.data.message,
            'success'
          );
          setLoading(false);
          handleCloseModalTnt();
        } catch (error) {
          Swal.fire(
            'Error!',
            error.response.data.msg,
            'error'
          );
        }

      }else{
        Swal.fire(
          'Error!',
          'Anda tidak berhak mengedit data ini!',
          'error'
        );
      }
  
 }else if(action == 'respon'){

  if(useribs == userprocessed || role == 'admin'){
    setLoading(true);

    const formData = new FormData();
    formData.append('norekaba', resnorek);
    formData.append('noalternatif', resnoalt);
    formData.append('namaaba', resnamabanksumber);
    formData.append('jenisaba', resjenisaba);
    formData.append('processedat', createdat);
  
    resselectedFiles.forEach((file) => { 
      formData.append('files', file.file);
    });
  
    resselectedFilesdbdel.forEach((file) => {
      formData.append('fileresexistdel', file.file.name); 
    });
  
  
    try {
      const response = await axiosJWT.patch(`${env.API_URL}/respontnt/${idedit}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
      );
      setLoading(false);
      handleCloseModalTnt();
    } catch (error) {
      console.error('Error uploading files: ', error);
    }
  }else{
    Swal.fire(
      'Error!',
      'Anda tidak berhak mengedit data!',
      'error'
    );
  }
 }

}

getTnt(user && user.kode_user_cbs, tglsekarang, tglsekarang);
};


const deleteTnt = async (idedit) => {
  dispatch(getMe());
  Swal.fire({
      title: 'Konfirmasi!',
      text: "Anda akan menghapus data ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus!'
  }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosJWT.delete(`${env.API_URL}/tnt/${idedit}/${user && user.kode_user_cbs}`,{
            headers: {
              Authorization: `Bearer ${token}`
          }
          }).then((response) => {
              Swal.fire(
                  'Berhasil dihapus!',
                  response.data.message,
                  'success'
              )
          })
        } catch (error) {
          Swal.fire(
            'Error!',
            error.response.data.msg,
            'error'
          );
        }

      }
      getTnt(user && user.kode_user_cbs, tglsekarang, tglsekarang);
  })
};



return (
    
<div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>


      <h2 className="subtitle"><b>List Transaksi Non Tunai</b></h2>
      {istnt == "requester" || role == 'admin'  ? (
      <div className="box">
          <button onClick={() => {handleOpenModalTnt('add');resetForm()}} className="button is-small is-info" style={{marginRight:'5px'}}>
          Tambah Data
        </button>
        <button onClick={() => {handleOpenModalPrintLaporan();}} className="button is-small is-warning">
          Print Laporan
        </button>

       </div>
      ): null}
       


      <div className="box">
     

<div className="field is-horizontal mb-0">

    <label className="label is-small column is-1 pt-1">Periode</label>
    <input className="input is-small column is-2 mr-2" 
      ref={datePeriodeMulai}
      type="date" 
      value={tglmulai} 
    />
    <input className="input is-small column is-2 mr-2"  
     ref={datePeriodeSelesai}
     type="date" 
     value={tglselesai} 
    />
      <button className="button is-small is-primary" onClick={(e) => getTntFilter(e)} title="Search Data"><IoSearch size="20"/></button>
   
</div>
     
            <DataTable
                        columns={columns}
                        data={filteredtnt}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                 
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />


<div className={`modal modal-fx-3dRotateFromBottom ${ismodalopentnt ? 'is-active' : ''}`}>
        <div className="modal-background "></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form Transaksi Non Tunai</b></p>
              <button
                onClick={handleCloseModalTnt}
                className="delete"
                aria-label="close"
              />
            </header>


   
        <section className="modal-card-body" style={{padding:'5px'}} >

        <div className="tabs">
            <ul>
              <li
                className={activeTab === 0 ? 'is-active' : ''}
                onClick={() => handleTabClick(0)}>
                <div>Request</div>
              </li>
              {istnt != 'requester' &&  (
               <li
                className={activeTab === 1 ? 'is-active' : ''}
                onClick={() => handleTabClick(1)}>
               <div>Respon</div>
              </li>
 
              )}
              
            </ul>
          </div>



          {activeTab === 0 && (
          <div>

            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                    <div className="columns mb-0">
                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Kode Kantor User</label> */}
                                    <input
                                        title="Kode Kantor User"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={kodekantor}
                                        onChange={(e) => setKodeKantor(e.target.value)}
                                        placeholder="Kode Kantor"
                                        readOnly
                                        />
                                  </div>
                                
                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Tanggal Request</label> */}
                                    <input
                                        title="Tanggal Permohonan"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={tanggalformatindo}
                                        placeholder="Tanggal Permohonan"
                                        readOnly
                                        />
                                  </div>  
                    </div>  

                    <div className="columns">
                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Jenis Transaksi</label> */}
                                    <div className="select is-fullwidth is-small mb-0">
                                    <select
                                      title="Jenis Transaksi"
                                      value={jenistnt}
                                      onChange={(e) => handleJenisTnt(e)}
                                      disabled={disabledjenistnt}
                                    >
                                      <option value="">-- Pilih Jenis Transaksi --</option>
                                      <option value="1">Transfer</option>
                                      <option value="2">Pajak</option>
                                      <option value="3">BPJS</option>
                                    </select>
                                    </div>
                                </div>


                                <div className="column is-half">
                                  {/* <label className="label is-small mb-0">User Pemroses</label> */}
              
                                    <p clclassNameass="control">
                                    <div className="select is-fullwidth is-small mb-0">
                                    <select
                                      title="User Admin TNT"
                                      value={userexecutor}
                                      onChange={(e) => setUserExecutor(e.target.value)}
                                      >
                                      <option value="">-- Pilih Admin TNT --</option>
                                            {executor.map(exe => (
                                              <option key={exe.kode_user_cbs} value={exe.kode_user_cbs}>
                                                {exe.name}
                                              </option>
                                            ))}
                                    </select>
                                    </div>
                                    </p>
                                  </div>

                        </div>

                      </div>

                      <div>
                </div>

                      <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                        <div className="columns mb-0">
                                  <div className="column is-half">
                                    {/* <label className="label is-small ">Rekening</label> */}
                                    <input
                                        title="Nomor Rekening Sumber"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={norek}
                                        onChange={(e) => setNorek(e.target.value)}
                                        // onBlur={handleBlurNorek}
                                        placeholder="Nomor Rekening Sumber"
                                        />
                                  </div>

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Nama</label> */}
                                  <input
                                      title="Nama Nasabah"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={namanasabah}
                                      onChange={(e) => setNamaNasabah(e.target.value)}
                                      placeholder="Nama Nasabah"
                                      
                                    />
                                  </div>
                        </div>

                  

                          <div className="columns">
                                <div className="column is-full">
                                  {/* <label className="label is-small mb-0">Alamat</label> */}
                                  <input
                                      title="Alamat Nasabah"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={alamatnasabah}
                                      onChange={(e) => setAlamatNasabah(e.target.value)}
                                      placeholder="Alamat Nasabah"
                                      
                                    />
                                  </div>
                                </div>
            
                          </div>


                                {showformtransfer && (
                                  <div  className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>

                                  <div className="columns mb-0">

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Bank Tujuan</label> */}
                                    <p clclassNameass="control">
                                    <div className="select is-fullwidth is-small mb-0">
                                    <select
                                      title="Bank Tujuan"
                                      value={kodebank}
                                      onChange={(e) => setKodeBank(e.target.value)}
                                      >
                                      <option value="">-- Pilih Bank Tujuan --</option>
                                            {kodebankall.map(bank => (
                                              <option key={bank.kode_bank} value={bank.kode_bank}>
                                                {bank.nama_bank}
                                              </option>
                                            ))}
                                    </select>
                                    </div>
                                    </p>
                                  </div>                  

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Nomor Rekening Penerima</label> */}
                                  <input
                                      title="Nomor Rekening Tujuan"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={rekeningpenerima}
                                      onChange={(e) => setRekeningPenerima(e.target.value)}
                                      placeholder="Nomor Rekening Tujuan"
                                    />
                                  </div>
                                  </div>


                                  <div className="columns mb-0">
                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Nama Penerima</label> */}
                                    <input
                                        title="Nama Penerima"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={namapenerima}
                                        onChange={(e) => setNamaPenerima(e.target.value)}
                                        placeholder="Nama Penerima"
                                        />
                                  </div>

                                  

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Jumlah Setoran</label> */}

                                  <NumericFormat 
                                    title="Jumlah Setoran"
                                    thousandSeparator="."
                                    decimalSeparator="," 
                                    value={jumlahsetorantransfer}                  
                                    onChange={(e) => setJumlahSetoranTransfer(e.target.value)}
                                    className="input is-small mb-0"
                                    placeholder={'Jumlah Setoran'}
                                    decimalScale={2}
                                  />

                                  </div>
                                  </div>              

                                  <div className="columns mb-0">
                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Perihal</label> */}
                                  <input
                                      title="Perihal Transaksi"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={perihaltransfer}
                                      onChange={(e) => setPerihalTransfer(e.target.value)}
                                      placeholder="Perihal Transaksi"
                                    />
                                  </div>
                                  </div>

                                  <div className="columns">
                                  <div className="column is-full">
                                    {/* <label className="label is-small mb-0">Catatan</label> */}
                                    <input
                                        title="Catatan"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={catatantransfer}
                                        onChange={(e) => setCatatanTransfer(e.target.value)}
                                        placeholder="Catatan"
                                        />
                                  </div>
                                  </div>

                                </div>
                                  )}


                                  {showformpajak && (
                                  <div  className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>

                                  <div className="columns mb-0">

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Id Billing</label> */}
                                  <input
                                      title="ID Billing"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={idbilling}
                                      onChange={(e) => setIdBilling(e.target.value)}
                                      placeholder="ID Billing"
                                    />
                                  </div>

                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Jenis Pajak</label> */}
                                    <input
                                        title="Jenis Pajak"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={jenispajak}
                                        onChange={(e) => setJenisPajak(e.target.value)}
                                        placeholder="Jenis Pajak"
                                        />
                                  </div>

                                  </div>

                                  
                                  <div className="columns mb-0">
                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Masa Pajak</label> */}
                                    <input
                                        title="Masa Pajak"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={masapajak}
                                        onChange={(e) => setMasaPajak(e.target.value)}
                                        placeholder="Masa Pajak"
                                        />
                                  </div>

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Jumlah Setoran</label> */}


                                  <NumericFormat 
                                    title="Jumlah Setoran"
                                    thousandSeparator="."
                                    decimalSeparator="," 
                                    value={jumlahsetoranpajak}                  
                                    onChange={(e) => setJumlahSetoranPajak(e.target.value)}
                                    className="input is-small mb-0"
                                    placeholder={'Jumlah Setoran'}
                                    decimalScale={2}
                                  />


                                  </div>
                                  </div>       

                                  <div className="columns">
                                  <div className="column is-full">
                                    {/* <label className="label is-small mb-0">Catatan</label> */}
                                    <input
                                        title="Catatan"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={catatanpajak}
                                        onChange={(e) => setCatatanPajak(e.target.value)}
                                        placeholder="Catatan"
                                        />
                                  </div>
                                  </div>       

                                </div>
                                  )}


                            {showformbpjs && (
                                  <div  className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>

                                  <div className="columns mb-0">
                                  <div className="column is-half">
                                    {/* <label className="label is-small mb-0">Virtual Account</label> */}
                                    <input
                                        title="Nomor Virtual Account"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={virtualaccount}
                                        onChange={(e) => setVirtualAccount(e.target.value)}
                                        placeholder="Nomor Virtual Account"
                                        />
                                  </div>                     

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Jumlah Setoran</label> */}

                                  <NumericFormat 
                                    title="Jumlah Setoran"
                                    thousandSeparator="."
                                    decimalSeparator="," 
                                    value={jumlahsetoranbpjs}                  
                                    onChange={(e) => setJumlahSetoranBpjs(e.target.value)}
                                    className="input is-small mb-0"
                                    placeholder={'Jumlah Setoran'}
                                    decimalScale={2}
                                  />
                                  
                                
                                  </div>
                                  </div>


                                  <div className="columns">
                                  <div className="column is-full">
                                    {/* <label className="label is-small mb-0">Catatan</label> */}
                                    <input
                                        title="Catatan"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={catatanbpjs}
                                        onChange={(e) => setCatatanBpjs(e.target.value)}
                                        placeholder="Catatan"
                                        />
                                  </div>
                                  </div>       

                                </div>
                                  )}

                                  
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
              <div {...getRootProps1()} className="dropzone">
                <input {...getInputProps1()} />
                <p style={{fontSize:'14px'}}>Drag & drop some files here, or click to select files</p>
              </div>
           
              <div className="uploaded-files">
              <h3 style={{marginBottom:'5px', fontSize: '14px'}}>Uploaded Files:</h3>
                <ul>
                  {mergedFiles.map((file, index) => (
                    <li key={index} className="uploaded-file">
                      <span className="uploaded-file-icon" style={{fontSize: '20px'}}><IoDocumentAttachOutline /></span>
                      <span className="uploaded-file-name" onClick={() => handleOpenModalFile(file.src, file.file.name, file.ext)} style={{cursor:'pointer'}}>
                      {file.file.name}
                      </span>

                      <button className="button is-danger is-small" onClick={() => removeFile(index)}>
                        <IoTrash />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {uploading && <LoadingFile />}
            </div>


          </div>
          )}
          {activeTab === 1 && (
          <div>

                  <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                                  <div className="columns mb-0">
                                            <div className="column is-half">
                                              {/* <label className="label is-small ">Rekening</label> */}
                                            
                                              <p clclassNameass="control">
                                              <div className="select is-fullwidth is-small mb-0">
                                              <select
                                                title="Bank Sumber"
                                                value={resbanksumber}
                                                onChange={(e) => setResBankSumber(e.target.value)}
                                                onBlur={handleBlurNorekAba}
                                                >
                                                <option value="">-- Pilih Bank Sumber --</option>
                                                      {resbanksumberall.map(aba => (
                                                        <option key={aba.norek_aba} value={aba.norek_aba}>
                                                          {aba.nama_aba} ({aba.no_alternatif}) 
                                                        </option>
                                                      ))}
                                              </select>
                                              </div>
                                              </p>

                                            </div>

                                            <div className="column is-half">
                                            {/* <label className="label is-small mb-0">Nama</label> */}
                                            <input
                                                title="Nama Bank Sumber"
                                                type="text"
                                                className="input is-small mb-0"
                                                value={resnamabanksumber}
                                                onChange={(e) => setResNamaBankSumber(e.target.value)}
                                                placeholder="Nama Bank Tujuan"
                                                readOnly
                                              />
                                            </div>
                                  </div>

                                  <div className="columns mb-0">

                                            <div className="column is-half">
                                            {/* <label className="label is-small mb-0">No Rekening</label> */}

                                            <input
                                                title="Nomor Rekening Bank Tujuan"
                                                type="text"
                                                className="input is-small mb-0"
                                                value={resnorek}
                                                onChange={(e) => setResNorek(e.target.value)}
                                                placeholder="Nomor Rekening Bank Tujuan"
                                                readOnly
                                              />
                                          
                                            </div>

                                            <div className="column is-half">
                                            {/* <label className="label is-small mb-0">Kode Kantor Tab</label> */}
                                            <input
                                                title="Nomor Alternatif ABA"
                                                type="text"
                                                className="input is-small mb-0"
                                                value={resnoalt}
                                                onChange={(e) => setResNoAlt(e.target.value)}
                                                placeholder="Nomor Alternatif ABA"
                                                readOnly
                                              />
                                            </div>
                                    </div>

                                  
                      
                    </div>

                        
          <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
            <div {...getRootProps2()} className="dropzone">
                <input {...getInputProps2()} />
                <p style={{fontSize:'14px'}}>Drag & drop some files here, or click to select files</p>
              </div>

              <div className="uploaded-files">
              <h3 style={{marginBottom:'5px', fontSize: '14px'}}>Uploaded Files:</h3>
                <ul>
                  {resmergedFiles.map((file, index) => (
                    <li key={index} className="uploaded-file">
                      <span className="uploaded-file-icon" style={{fontSize: '20px'}}><IoDocumentAttachOutline /></span>
                      <span className="uploaded-file-name" onClick={() => handleOpenModalFile(file.src, file.file.name, file.ext)} style={{cursor:'pointer'}}>
                      {file.file.name}
                      </span>
                      <button className="button is-danger is-small" onClick={() => removeResFile(index)}>
                        <IoTrash />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {resuploading && <LoadingFile />}
            </div>



          </div>
          )}

      
   
              
        </section>
            <footer className="modal-card-foot">
              {istnt == 'requester' || istnt == 'executor' || role == 'admin' ? (
                  <button className="button is-success is-small" onClick={(e) => {saveTnt(e);}} >Save</button>
              ): null}
              <button onClick={handleCloseModalTnt} className="button is-small is-danger">
                Close
              </button>
            </footer>
        </div>
      </div>
      
      </div>







<div className={`modal modal-fx-newsPaper ${isModalOpentranstnt ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Transaksi</b></p>
              <button
                onClick={handleCloseModalTransTnt}
                className="delete"
                aria-label="close"
              />
            </header>
            <section className="modal-card-body">
            {/* <form> */}
            <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                    <div className="columns">
                      <div className="column is-half">
                        {/* <label className="label is-small mb-0">Tgl Trans</label> */}
                        <input
                           title="Tanggal Transaksi"
                            type="text"
                            className="input is-small"
                            value={tanggalformatindo}
                            placeholder="Tanggal Transaksi"
                            readOnly
                            />
                      </div>

                      <div className="column is-half">
                      {/* <label className="label is-small mb-0">Kode Kantor</label> */}
                      <input
                         title="Kode Kantor User"
                          type="text"
                          className="input is-small"
                          value={kodekantor}
                          placeholder="Kode Kantor User"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-half">
                        {/* <label className="label is-small mb-0">Tgl Trans</label> */}
                        <input
                            title="Nominal Transaksi"
                            type="text"
                            className="input is-small"
                            value={nominal}
                            placeholder="Nominal"
                            readOnly
                            />

                      </div>

                      <div className="column is-half">
                      {/* <label className="label is-small mb-0">Kode Kantor</label> */}
                      <input
                         title="Uraian"
                          type="text"
                          className="input is-small"
                          value={uraian}
                          placeholder="Uraian"
                          readOnly
                        />
                      </div>
                    </div>

                  </div>


                    <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                        <div className="columns mb-0">
                                  <div className="column is-half">
                                    {/* <label className="label is-small ">Rekening</label> */}
                                    <input
                                        title="Nomor Rekening Sumber"
                                        type="text"
                                        className="input is-small mb-0"
                                        value={norek}
                                        onChange={(e) => setNorek(e.target.value)}
                                        // onBlur={handleBlurNorek}
                                        placeholder="Nomor Rekening Sumber"
                                        readOnly
                                        />
                                  </div>

                                  <div className="column is-half">
                                  {/* <label className="label is-small mb-0">Nama</label> */}
                                  <input
                                      title="Nama Nasabah"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={namanasabah}
                                      onChange={(e) => setNamaNasabah(e.target.value)}
                                      placeholder="Nama Nasabah"
                                      readOnly
                                    />
                                  </div>
                        </div>

                  

                          <div className="columns">
                                <div className="column is-full">
                                  {/* <label className="label is-small mb-0">Alamat</label> */}
                                  <input
                                      title="Alamat Nasabah"
                                      type="text"
                                      className="input is-small mb-0"
                                      value={alamatnasabah}
                                      onChange={(e) => setAlamatNasabah(e.target.value)}
                                      placeholder="Alamat Nasabah"
                                      readOnly
                                    />
                                  </div>
                                </div>
            
                    </div>


                    <div className="box" style={{border:'1px #999999 solid', marginBottom:'5px'}}>
                                  <div className="columns mb-0">
                                            <div className="column is-half">
                                              {/* <label className="label is-small ">Rekening</label> */}
                                              <input
                                                title="Nama Bank Tujuan"
                                                type="text"
                                                className="input is-small mb-0"
                                                value={resnamabanksumber}
                                                placeholder="Nama Bank Tujuan"
                                                readOnly
                                              />
                                              
                                            </div>

                                            <div className="column is-half">
                                            {/* <label className="label is-small mb-0">Nama</label> */}
                                            <input
                                                title="No. Rekening Bank Tujuan"
                                                type="text"
                                                className="input is-small mb-0"
                                                value={resnorek}
                                                placeholder="Nomor Rekening Bank Tujuan"
                                                readOnly
                                              />
                                            </div>
                                  </div>
                                    {uploading && <Loading />}
                                  </div>


       
            {/* </form> */}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" 
              onClick={(e) => {KonfirmTrans(e);}}
              >Konfirmasi Transaksi</button>
              <button onClick={handleCloseModalTransTnt} className="button is-small">
                Close
              </button>
            </footer>
        </div>
      </div>

     



    

      <div className={`modal modal-fx-3dSlit ${isModalImageOpenRequest ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalImageRequest()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Slider ref={sliderRefRequest} {...sliderSettingsRequest} >
           
              {selectedFilesdb.map((item, index) => (
                <div key={index}                          
                className={`Image ${index === selectedImageIndexRequest ? 'active' : ''}`}
                >

                  {item.ext === '.jpg' ? (
                    <img src={item.src} alt={`Image ${index + 1}`} width="100%" height="400px" />
                  ) : (
                    <embed src={item.src} ext="application/pdf" width="100%" height="400px" />
                  )}

                </div>                              
              ))}
            </Slider>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalImageRequest()}></button>
        </div>
      </div>



      


      <div className={`modal modal-fx-3dSlit ${isModalImageOpenRespon ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalImageRespon()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Slider ref={sliderRefRespon} {...sliderSettingsRespon} >
              {resselectedFilesdb.map((item, index) => (
                <div key={index}                          
                className={`Image ${index === selectedImageIndexRespon ? 'active' : ''}`}
                >
                  {item.ext === '.jpg' ? (
                    <img src={item.src} alt={`Image ${index + 1}`} width="100%" height="400px" />
                  ) : (
                    <embed src={item.src} ext="application/pdf" width="100%" height="400px"/>
                  )}

                </div>                              
              ))}
            </Slider>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalImageRespon()}></button>
        </div>
      </div>




      <div className={`modal modal-fx-3dSlit ${isModalOpenFile ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalFile()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <b>File : {nameFile}</b><br /><br />
            {extFile == '.pdf' ? (
            <Iframe src={urlFile}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
            ): extFile == '.jpg' || extFile == '.jpeg' || extFile == '.gif' || extFile == '.png' ? (
              <img src={urlFile} alt={nameFile} />
            ): null}
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalFile()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenPrintLaporan ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalPrintLaporan()}></div>
        <div className="modal-content" style={{borderRadius: '5px'}}>
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Rekap Laporan</b></p>
              <button
                onClick={handleCloseModalPrintLaporan}
                className="delete"
                aria-label="close"
              />
            </header>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
        

                                <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Mulai</label>
                                      <input                                
                                        ref={dateInputRefLapMulai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Mulai (dd/MM/yyyy)"   
                                      />

                                   

                                  </div>  
                             </div> 

                             <div className="columns mb-0"> 
                                  <div className="column is-full">
                                    <label className="label is-small mb-0">Tgl Selesai</label>
                                      <input
                                        ref={dateInputRefLapSelesai}
                                        type="text"
                                        className="input is-small mb-0"
                                        placeholder="Tanggal Selesai (dd/MM/yyyy)"                              
                                      />                              
                                  </div>  
                             </div> 


                      

                             <div className="columns mb-0"> 
                             <div className="column is-full">
                              <label className="label is-small mb-0">Kantor Kas</label>
                              <p className="control">
                                <div className="select is-fullwidth is-small">                           
                                  <select
                                  value={kodekakas}
                                  onChange={(e) => setKodeKaKas(e.target.value)}
                                  >
                                  <option value="">-- Select --</option>
                                        {kodekakasall.map(ktr => (
                                          <option key={ktr.kode_kantor_kas} value={ktr.kode_kantor_kas}>
                                            {ktr.nama_kantor}
                                          </option>
                                        ))}
                                </select>
                                </div>
                                </p>
                     
                              </div>
                            </div>
                         




          </div>
         
          </section>
          <footer className="modal-card-foot">

        <button className="button is-success is-small" onClick={handleOpenModalCetakLaporan}>OK</button>
        <button className="button is-danger is-small" onClick={handleCloseModalPrintLaporan}>Close</button>
        </footer>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalPrintLaporan()}></button>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenCetakLaporan ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalCetakLaporan()}></div>
        <div className="modal-content is-huge" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
            <Iframe src={urlbyid}
                  width="100%"
                  height="600px"
                  id=""
                  className=""
                  display="block"
                  position="relative"/>
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalCetakLaporan()}></button>
        </div>
      </div>


      
      </div>
  );
};



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}


export default Tntlist;
