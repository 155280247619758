import axios from "axios";
import env from "react-dotenv";


  export const getLocalIP = async () => {
    try {
      const response = await axios.get(`${env.API_URL}/local-ip`);
      return response.data.ip;
    } catch (error) {
      console.error('Terjadi kesalahan saat mengambil IP lokal:', error);
      return null;
    }
  };


  export const formatTanggal = async () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    var jam = today.getHours();
    var menit = today.getMinutes();
    var detik = today.getSeconds();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    if (jam < 10) jam = '0' + jam;
    if (menit < 10) menit = '0' + menit;
    if (detik < 10) detik = '0' + detik;

    const tanggalformatindo = dd + '/' + mm + '/' + yyyy;
    const tgl_trans = yyyy +'-'+ mm +'-'+ dd; 
    const jam_trans = jam + ":" + menit + ":" + detik;

    return {tanggalformatindo, tgl_trans, jam_trans};
  };

  
  export const insertLogUser = async (userid, action, table) => {
    const response = await axios.get(`${env.API_URL}/local-ip`);

    try {
      await axios.post(`${env.API_URL}/logusers`, {
        user_ibs:userid,
        ip_address: response.data.ip,
        action: action,
        table_name : table
      }
      )
    } catch (error) {
      if (error.response) {
      }
    }
  };


