import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoCreate } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";
import { getMe } from "../features/authSlice";

const ConfigList = () => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState([]);
  const [filteredconfig, setFilteredConfig] = useState([])
  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()

  const [search, setSearch] = useState('')
  const [msg, setMsg] = useState("");
  const [idedit, setIdEdit] = useState("");
  const [keyname, setKeyName] = useState("");
  const [keyvalue, setKeyValue] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disablededit, setDisabledEdit] = useState(false);
  const [key, setKey] = useState(false);

  const handleOpenModal = (key) => {
    setIsModalOpen(true);
    setKey(key)
    if(key == 'add'){
      setDisabledEdit(false)
    }else if(key == 'edit'){
      setDisabledEdit(true)
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const resetForm = () => {
    setIdEdit("")
    setKeyName("")
    setKeyValue("")
    setDeskripsi("")
  }

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    const result = config.filter((tb_config) => {
        return config.keyname.toLowerCase().match(search.toLowerCase())
    })
    setFilteredConfig(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Key Name',
        selector: row => row.keyname,
        sortable: true
    },
    {
        name: 'Key Value',
        selector: row => row.keyvalue,
        sortable: true
    },
    {
        name: 'Deskripsi',
        selector: row => row.deskripsi,
        sortable: true
    },
    {
        name: 'Action',
        sortable: false,
        selector: row => row.id,
        cell: (row) => [
          <div>
          <span
         onClick={() => {setIdEdit(row.id);getConfigById(row.id);handleOpenModal('edit');}}            
          className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
          <span           
         onClick={() => {deleteConfig(row.id)}}
          className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
        </div>
          ]
    }
  ];


const getConfig = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/config`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setConfig(response.data)
    setFilteredConfig(response.data)
}

const getConfigById = async (id) => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/config/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setKeyName(response.data.keyname);
    setKeyValue(response.data.keyvalue);
    setDeskripsi(response.data.deskripsi);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};


const saveConfig = async (e) => {
  dispatch(getMe());
  e.preventDefault();

if(key == 'add'){
if(keyname == ''){
  Swal.fire(
    'Gagal!',
    'Key Name wajib di isi!',
    'error'
)
}else if (keyvalue == ''){
  Swal.fire(
    'Gagal!',
    'Key Value wajib di isi!',
    'error'
)
}else{
    await axiosJWT.post(`${env.API_URL}/config`, {
      keyname: keyname,
      keyvalue: keyvalue,
      deskripsi: deskripsi,
    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })
}
}else if(key == 'edit'){
  if(keyname == ''){
    Swal.fire(
      'Gagal!',
      'Key Name wajib di isi!',
      'error'
  )
  }else if (keyvalue == ''){
    Swal.fire(
      'Gagal!',
      'Key Value wajib di isi!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/config/${idedit}`, {
      keyname: keyname,
      keyvalue: keyvalue,
      deskripsi: deskripsi,
  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
}
getConfig()
};

const deleteConfig = async (idedit) => {
    dispatch(getMe());
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axios.delete(`${env.API_URL}/config/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getConfig();
    })
}

return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>List Config</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal('add'); resetForm()}} className="button is-small is-info">
      Tambah Data
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredconfig}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                 
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />

<div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form Config App</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
        
                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Key Name</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={keyname}
                          onChange={(e) => setKeyName(e.target.value)}
                          placeholder="Key Name"
                          disabled={disablededit}
                        />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Key Value</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={keyvalue}
                          onChange={(e) => setKeyValue(e.target.value)}
                          placeholder="Key Value"
                        />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Deskripsi</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={deskripsi}
                          onChange={(e) => setDeskripsi(e.target.value)}
                          placeholder="Deskripsi Config"
                        />
                        </p>
                      </div>
                      </div>
                  </div>

              

            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveConfig(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default ConfigList;
