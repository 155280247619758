import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoDocumentAttach, IoCreate } from "react-icons/io5";
import axios from "axios";
import "bulma/css/bulma.css";
import 'bulma/css/bulma.min.css';
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import env from "react-dotenv";

const InstansiList = () => {
  const [products, setProducts] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([])
  const navigate = useNavigate(); 

  const [name, setName] = useState('')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()

  const [idedit, setIdEdit] = useState("");
  const [nameedit, setNameEdit] = useState("");
  const [price, setPrice] = useState("");

 


  const [msg, setMsg] = useState("");
  const [search, setSearch] = useState('')

  const [instansi, setInstansi] = useState([]);
  const [filteredinstansi, setFilteredInstansi] = useState([])
  const [namainstansi, setNamaInstansi] = useState("");
  const [alamatinstansi, setAlamatInstansi] = useState("");
  const [telpinstansi, setTelpInstansi] = useState("");
  const [warnaslip, setWarnaSlip] = useState("");
  const [file, setFile] = useState("");
  const [hapusfile, setHapusFile] = useState("");
  const [preview, setPreview] = useState("");
  const [urlfile, setUrlFile] = useState("");
  const [showCheckbox, setShowCheckbox] = useState(true);
  const [isModalOpenFile, setIsModalOpenFile] = useState(false);


  const ref = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleOpenModal = () => {
    setIsModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModalFile = (url) => {
    setIsModalOpenFile(true);
    setUrlFile(url);
  };

const handleCloseModalFile = () => {
    setIsModalOpenFile(false);
    setUrlFile("");
  };


  
  const resetForm = () => {
    setIdEdit("")
    setNamaInstansi("")
    setAlamatInstansi("")
    setTelpInstansi("")
    setWarnaSlip("")
    setPreview("")
    setFile("")
    setHapusFile(false);
  }

  const resetFormFile = () => {
    ref.current.value = ""
    setHapusFile(false);
    setPreview("")
    setFile("")
  }



  useEffect(() => {
    getInstansi();
  }, []);


  useEffect(() => {
    const result = instansi.filter((instansi) => {
        return instansi.instansi_nama.toLowerCase().match(search.toLowerCase())
    })
    setFilteredInstansi(result)
}, [search])


axiosJWT.interceptors.request.use(async (config) => {
  const currentDate = new Date()
  if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${env.API_URL}/token`)
      config.headers.Authorization = `Bearer ${response.data.accessToken}`
      setToken(response.data.accessToken)
      const decoded = jwt_decode(response.data.accessToken)
      setName(decoded.name)
      setExpire(decoded.exp)
  }
  return config
}, (error) => Promise.reject(error))



const columns = [
  {
      name: 'Nama Instansi',
      selector: row => row.instansi_nama,
      sortable: true,
      width: "300px"
  },
  {
      name: 'Alamat Instansi',
      selector: row => row.instansi_alamat,
      sortable: true,
      width: "450px"
  },
  {
      name: 'Telp Instansi',
      selector: row => row.instansi_telp,
      sortable: true,
      width: "400px"
  },
  {
    name: 'Warna Slip',  
    sortable: false,
    width: "100px",
    selector: row => row.warna_slip,
    cell: (row) => {
      if (row.warna_slip === '#0070C0') {
        return <span>Biru</span>;
      } else if (row.warna_slip === '#990000') {
        return <span>Merah</span>;
      } else if (row.warna_slip === '#748729') {
        return <span>Hijau</span>;
      } else if (row.warna_slip === '#bf9000') {
        return <span>Kuning</span>;
      } else if (row.warna_slip === '#5b5b5b') {
        return <span>Abu-abu</span>;
      } else if (row.warna_slip === '#000000') {
        return <span>Hitam</span>;
      } else {
        return null; 
      }
    }
  },
  {
    name: 'Logo Instansi',
    width: "150px",
    selector: row => {
      if (row.instansi_logo) {
        return (
            <ul>
              <li>
                  <a 
                    onClick={() => {handleOpenModalFile(`${env.API_URL}/images/${row.instansi_logo}`)}} 
                   >
                       <IoDocumentAttach /> &nbsp;{row.instansi_logo}
                  </a>
               </li>
            </ul>
        );
      }
      return 'Tidak Ada File';
    },
    sortable: true
  },
  {
      name: 'Action',
      sortable: false,
      selector: row => row.id,
      cell: (row) => [
        <div>
        <span
        onClick={() => {setIdEdit(row.id);getInstansiById(row.id);handleOpenModal();resetFormFile()}}             
        className="is-small mr-1" style={{ color: '#3e8ed0', cursor: 'pointer'}}><IoCreate size={20} /></span>
        <span           
        onClick={() => {deleteInstansi(row.id)}}
        className="is-small mr-1" style={{ color: '#f03a5f', cursor: 'pointer'}}><IoTrash size={20} /></span>
      </div>  
        ]
  }
];

  const getInstansi = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/instansi`,{
      headers: {
          Authorization: `Bearer ${token}`
      }
    });
    setInstansi(response.data);
    setFilteredInstansi(response.data);
  };

  const getInstansiById = async (id) => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/instansi/${id}`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
      });
      setNamaInstansi(response.data.instansi_nama);
      setAlamatInstansi(response.data.instansi_alamat);
      setTelpInstansi(response.data.instansi_telp);
      setWarnaSlip(response.data.warna_slip)
      setFile(response.data.instansi_logo);
      if (response.data.instansi_logo){
        setPreview(`${env.API_URL}/images/${response.data.instansi_logo}`);
      }
      
      if(response.data.instansi_logo){
        setShowCheckbox(true);
      }else{
        setShowCheckbox(false);
        setHapusFile(false);
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const saveInstansi = async (e) => {
    e.preventDefault();

  if(idedit == ''){
    if(instansi != ''){
      Swal.fire(
        'Gagal!',
        'Data instansi sudah ada!',
        'error'
    )
    }else if (namainstansi == ''){
      Swal.fire(
        'Gagal!',
        'Alamat instansi wajib di isi!',
        'error'
    )
    }else if (alamatinstansi == ''){
      Swal.fire(
        'Gagal!',
        'Alamat instansi wajib di isi!',
        'error'
    )
    }else if (telpinstansi == ''){
      Swal.fire(
        'Gagal!',
        'Telphone instansi wajib di isi!',
        'error'
    )
    }else if (warnaslip == ''){
      Swal.fire(
        'Gagal!',
        'Warna slip belum di pilih!',
        'error'
    )
    }else if (file == ''){
      Swal.fire(
        'Gagal!',
        'Logo wajib di isi!',
        'error'
    )
    }else{
      await axiosJWT.post(`${env.API_URL}/instansi`, {
        namainstansi: namainstansi,
        alamatinstansi: alamatinstansi,
        telpinstansi: telpinstansi,
        warnaslip: warnaslip,
        file: file
      },{
        headers:{
            Authorization:`Bearer ${token}`,
            "Content-type": "multipart/form-data"
        }
      }).then((response) => {
          Swal.fire(
              'Berhasil!',
              response.data.message,
              'success'
          )
          handleCloseModal();
      })
    }
  }else{
    if(namainstansi == ''){
      Swal.fire(
        'Gagal!',
        'Nama instansi wajib di isi!',
        'error'
    )
    }else if (alamatinstansi == ''){
      Swal.fire(
        'Gagal!',
        'Alamat instansi wajib di isi!',
        'error'
    )
    }else if (telpinstansi == ''){
      Swal.fire(
        'Gagal!',
        'Telphone instansi wajib di isi!',
        'error'
    )
    }else if (warnaslip == ''){
      Swal.fire(
        'Gagal!',
        'Warna slip belum di pilih!',
        'error'
    )
    }else if (file == ''){
      Swal.fire(
        'Gagal!',
        'Logo wajib di isi!',
        'error'
    )
    }else{
    await axiosJWT.patch(`${env.API_URL}/instansi/${idedit}`, {
      namainstansi: namainstansi,
      alamatinstansi: alamatinstansi,
      telpinstansi: telpinstansi,
      warnaslip: warnaslip,
      file: file,
      hapusfile : hapusfile
    },{
      headers:{
          Authorization:`Bearer ${token}`,
          "Content-type": "multipart/form-data"
      }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })

    }

  }

    getInstansi()
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setFile(image);
    setPreview(URL.createObjectURL(image));
    if(image){
      setShowCheckbox(false);
      setHapusFile(false);
    }else{
      setShowCheckbox(true);
    }
  };


  const deleteInstansi = async (idedit) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axiosJWT.delete(`${env.API_URL}/instansi/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getInstansi();
    })


}

  return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>Instansi</b></h2>
      <div className="box">
  
      <button  onClick={() => {handleOpenModal(); resetForm(); resetFormFile()}} className="button is-small is-info">
          Tambah Data
        </button>
            <DataTable
                        columns={columns}
                        data={filteredinstansi}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='600px'
                        highlightOnHover
                 
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />

  


      <div className={`modal modal-fx-3dRotateFromBottom ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form Instansi</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
      

                  <div className="columns">
                      <div className="column is-full">
                        <label className="label is-small mb-0">Nama Instansi</label>
                        <input
                            type="text"
                            className="input is-small"
                            value={namainstansi}
                            onChange={(e) => setNamaInstansi(e.target.value)}
                            placeholder="Nama Instansi"
                            />
                      </div>

                     
                    </div>


                    <div className="columns">
                      <div className="column is-full">
                        <label className="label is-small mb-0">Alamat Instansi</label>
                           <input
                            type="text"
                            className="input is-small"
                            value={alamatinstansi}
                            onChange={(e) => setAlamatInstansi(e.target.value)}
                            placeholder="Alamat Instansi"
                            />
                      </div>

                    </div>

                 

                    <div className="columns">
                      <div className="column is-full">
                      <label className="label is-small mb-0">Telp Instansi</label>
                      <input
                          type="text"
                          className="input is-small"
                          value={telpinstansi}
                          onChange={(e) => setTelpInstansi(e.target.value)}
                          placeholder="Telphone Instansi"
                        />
                      </div>
                    </div>


                    <div className="columns">
                      <div className="column is-full">
                      <label className="label is-small mb-0">Warna Slip</label>
                      <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={warnaslip}
                          onChange={(e) => setWarnaSlip(e.target.value)}
                        >
                          <option value="">-Pilih-</option>
                          <option value="#0070C0">Biru</option>
                          <option value="#990000">Merah</option>
                          <option value="#748729">Hijau</option>
                          <option value="#bf9000">Kuning</option>
                          <option value="#5b5b5b">Abu-abu</option>
                          <option value="#000000">Hitam</option>
                        </select>
                        </div>
                        </p>
                      </div>
                    </div>


                    <div className="columns">
                      <div className="column is-full">
                      <label className="label is-small mb-0">Logo</label>
                      <div className="control">
                      <input
                        type="file"
                        className="input is-small"
                        ref={ref}
                        onChange={loadImage}
                        placeholder="Choose a file..."
                      />
                      </div>
                      </div>
                    </div>

                    {preview ? (
                    <figure className="image is-128x128">
                        <a  onClick={() => {handleOpenModalFile(preview)}} >
                          <img src={preview} alt="Preview Image" />
                          </a>
                          
                        {showCheckbox && (
                        <div>
                        <input
                            type="checkbox"
                            checked={hapusfile}
                            onChange={(e) => setHapusFile(e.target.checked)}
                          /> Hapus File
                          </div>
                          )}
                      </figure>
                      
                    ) : (
                      ""
                    )}


            </section>
            <footer className="modal-card-foot">
              
              <button className="button is-success is-small" onClick={(e) => {saveInstansi(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>


      <div className={`modal modal-fx-3dSlit ${isModalOpenFile ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => handleCloseModalFile()}></div>
        <div className="modal-content is-image" style={{borderRadius: '5px'}}>
          <section className="modal-card-body" style={{padding: '40px'}}>          
            <div>
              <img src={urlfile} />
          </div>
         
          </section>
          <button class="modal-close is-large" aria-label="close" onClick={() => handleCloseModalFile()}></button>
        </div>
      </div>

      </div>
    </div>
  );
};

export default InstansiList;
